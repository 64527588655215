import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import { ScrollView, View, Image, Linking, TouchableOpacity, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router";
import { getDeviceType } from "../tools/Interface";
import { SlBulb, SlPeople, SlRocket, SlStar } from "react-icons/sl";
import colors from "../assets/colors/colors";
import { BsArrowRightShort } from "react-icons/bs";
import { LatoBold, LatoRegular } from "../components/Text";
import ExplainationSell from "../components/SellWithUs/ExplainationSell";
import ToasterContext from "../contexts/ToastContext";
import { api } from "../utils/api";
import { Link } from "react-router-dom";

function CareersScreen() {
  const scrollRef = useRef();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - 2 * gap;

  const { showToast } = useContext(ToasterContext);
  const [jobOpeningList, setJobOpeningList] = useState([]);
  const getJobOpeningList = async () => {
    try {
      const res = await api.get("/api/job_openings");

      setJobOpeningList(res.data.data || []);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  useEffect(() => {
    getJobOpeningList();
  }, []);

  const valueList = [
    {
      title: "Creativity and Problem Solving",
      body: "At Belle&Kate, we embrace innovative thinking and original ideas. We believe that creativity is the driving force behind our brand, empowering us to deliver unique solutions that truly resonate with our audience.",
      icon: <SlBulb size={20} color={colors.whiteFF} />,
    },
    {
      title: "Collaboration",
      body: "We thrive on teamwork and open communication. By creating a collaborative environment, we build strong relationships that enhance creativity and drive results, allowing us to achieve our goals together. ",
      icon: <SlPeople size={20} color={colors.whiteFF} />,
    },
    {
      title: "Growth Mindset",
      body: "We are dedicated to continuous learning and personal development. Our top talent views challenges as opportunities for growth and inspires others to adopt the same mindset.",
      icon: <SlRocket size={20} color={colors.whiteFF} />,
    },
    {
      title: "Integrity",
      body: "Honesty and transparency are at the heart of our culture. We expect our team members to act with integrity in all their interactions, upholding ethical standards and accountability ensures that we maintain trust with our clients and colleagues.",
      icon: <SlStar size={20} color={colors.whiteFF} />,
    },
  ]

  return (
    <View>
      <ScrollView
        ref={scrollRef}
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: isPhone ? 16 : 40,
            marginHorizontal: isPhone ? 16 : 100,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 24 : isTablet ? 48 : 60,
              lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
              color: colors.black00,
              marginBottom: 16,
            }}
          >
            CAREERS
          </LatoRegular>
          <View style={{
            flex: 1,
            width: containerWidth,
            padding: 3,
            gap: 24,
          }}>
            {jobOpeningList.map(o => {
              return (
                <Link key={o.id} to={`/careers/${o.id}`}>
                  <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid black',
                  }}>
                    <LatoRegular
                      style={{
                        fontSize: isPhone ? 20 : isTablet ? 24 : 28,
                        lineHeight: isPhone ? 22 : isTablet ? 24 : 36,
                        color: colors.black00,
                        marginBottom: 25,
                        textAlign: "left",
                        display: "inline",
                      }}
                    >
                      {o.title}
                    </LatoRegular>
                    <LatoRegular
                      style={{
                        fontSize: isPhone ? 20 : isTablet ? 24 : 28,
                        lineHeight: isPhone ? 22 : isTablet ? 24 : 36,
                        color: colors.black00,
                        marginBottom: 25,
                        textAlign: "right",
                        display: "inline",
                      }}
                    >
                      {o.location || "-"}
                    </LatoRegular>
                  </View>
                </Link>
              )
            })}
          </View>
          <LatoRegular
            style={{
              fontSize: 16,
              lineHeight: isPhone ? 16 : isTablet ? 24 : 32,
              textAlign: "center",
              color: colors.grey6C,
              marginVertical: 20,
            }}
          >
            Apply by sending email to <a href="mailto:recruitment@belleandkate.com">recruitment@belleandkate.com</a> with the opening's position in title.
          </LatoRegular>
          <View style={{
            backgroundColor: colors.green5B,
            color: colors.whiteFF,
          }}>
            <LatoBold
              style={{
                width: '100vw',
                fontSize: isPhone ? 20 : 32,
                lineHeight: isPhone ? 28 : 40,
                marginVertical: isPhone ? 20 : 32,
                textAlign: "center",
                color: colors.whiteFF,
              }}
            >
              OUR VALUES
            </LatoBold>
            <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pb-6 px-3 justify-evenly">
              {valueList.map((o, index) => {
                return (
                  <div key={o.title} className={`my-3 px-3 ${(index > 0 || isTablet || isPhone) && "border-l-[1px]"}`}>
                    <div className="flex">
                      <div className="mr-4 shrink-0">
                        {o.icon}
                      </div>
                      <div>
                        <h4 className="text-sm font-bold">{o.title}</h4>
                        <p className="text-sm mt-1">{o.body}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default CareersScreen;

import React from "react";
import {
  TouchableOpacity,
  View,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import { Link, useNavigate } from "react-router-dom";
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";

export default function FooterMenu({ title, data, noBorder = false }) {
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();
  const { isPhone } = getDeviceType({ width, height });

  return (
    <View
      style={{
        ...style.middleSingleLeftItem,
        marginBottom: isPhone ? 24 : 0,
        borderBottomColor: colors.whiteFF,
        borderBottomWidth: isPhone && !noBorder ? 1 : 0,
        paddingHorizontal: isPhone ? 16 : 0,
      }}
    >
      <LatoBold style={style.middleItemText}>{title}</LatoBold>
      {data.map(({ title, route, state }, index) => {
        return (
          state
          ?
          <TouchableOpacity
            onPress={() => navigate(route, { state })}
            style={style.middleItemTextContainer}
            key={index}
          >
            <LatoRegular style={style.upperItemButtonText}>{title}</LatoRegular>
          </TouchableOpacity>
          :
          <Link
            to={route}
            key={index}
          >
            <View style={style.middleItemTextContainer}>
              <LatoRegular style={style.upperItemButtonText}>{title}</LatoRegular>
            </View>
          </Link>
        );
      })}
    </View>
  );
}

const style = StyleSheet.create({
  middleSingleLeftItem: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  middleItemTextContainer: { marginBottom: 16 },
  middleItemText: {
    color: colors.whiteFF,
    marginBottom: 32,
    fontSize: 14,
    lineHeight: 20,
  },
  upperItemButtonText: {
    color: colors.whiteFF,
    fontSize: 16,
    lineHeight: 24,
    textDecorationLine: "none",
  },
});

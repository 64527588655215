import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  Linking,
} from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import ImageWithLoader from "../ImageWithLoader";
import colors from "../../assets/colors/colors";
import { LinksFromCDN } from "../../constants/cdnLinks";

export default function BannerCarousel({ loading = false }) {
  const [count, setCount] = useState(0);

  const autoScrollRef = useRef();
  const navigate = useNavigate();

  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });

  const images = [
    {
      "id": 1,
      "name": "What's new",
      "url": "https://belleandkate.com/products",
      "desktop_image": LinksFromCDN.banner1,
      "mobile_image": LinksFromCDN.bannerPhone1,
    },
    {
      "id": 2,
      "name": "Sell With Us",
      "url": "https://belleandkate.com/sell-with-us/consignment",
      "desktop_image": LinksFromCDN.banner2,
      "mobile_image": LinksFromCDN.bannerPhone2,
    },
    {
      "id": 3,
      "name": "Release.Revolve.Relove",
      "url": "https://belleandkate.com/about",
      "desktop_image": LinksFromCDN.banner3,
      "mobile_image": LinksFromCDN.bannerPhone3,
    }
  ]

  const autoSlideCarousel = useCallback(() => {
    setTimeout(() => {
      const multiplier = count + 1;
      const slideX = width;
      const slide = multiplier * slideX;

      autoScrollRef?.current?.scrollTo({
        x: slide,
        y: 0,
        animated: true,
      });
      setCount(count === images.length - 2 ? -1 : count + 1);
    }, 5000);
  }, [count, images, width]);

  useEffect(() => {
    autoSlideCarousel();
  }, [count, autoSlideCarousel]);

  return (
    <View>
      <ScrollView
        ref={autoScrollRef}
        horizontal
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={autoSlideCarousel}
      >
        {loading ? (
          <View
            style={{
              height: isPhone ? (3 / 2) * width : (9 / 16) * width - 120,
              width,
              backgroundColor: colors.superTransparentBlack00,
            }}
          />
        ) : (
          images.map((itm, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  if (itm.url.includes("belleandkate.com")) {
                    const direction = itm.url.split("belleandkate.com")[1];

                    navigate(direction ? direction : "/");
                  } else {
                    Linking.openURL(
                      itm.url.startsWith("https") || itm.url.startsWith("http")
                        ? itm.url
                        : `https://${itm.url}`
                    );
                  }
                }}
                activeOpacity={1}
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                  width,
                }}
                key={index}
              >
                <ImageWithLoader
                  source={isPhone ? itm.mobile_image : itm.desktop_image}
                  alt={`Product ${index}`}
                  style={{
                    height: isPhone ? (3 / 2) * width : (9 / 16) * width - 120,
                    width,
                  }}
                  defaultStyle={{
                    width: 120,
                    height: isPhone ? (3 / 2) * width : (9 / 16) * width - 120,
                  }}
                  resizeMode={"cover"}
                />
              </TouchableOpacity>
            );
          })
        )}
      </ScrollView>
    </View>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import colors from "../../assets/colors/colors";
import { SlDiamond } from "react-icons/sl";
import { LatoRegular, PTSerifBold } from "../Text";
import LinearGradient from "react-native-web-linear-gradient";
import Shimmer from "../Shimmer";
import { FaCircle } from "react-icons/fa";
import { api } from "../../utils/api";
import { formatCurrency } from "../../tools/formatter";
import ToasterContext from "../../contexts/ToastContext";
import { sentenceCase } from "change-case";

function RewardTracker({ position = "regular", current = 0 }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const { showToast } = useContext(ToasterContext);

  const [spendLimit, setSpendLimit] = useState([
    {
      name: "Togo",
      val: 1000000000,
    },
    {
      name: "Ostrich",
      val: 2000000000,
    },
    {
      name: "Croco",
      val: 4000000000,
    },
  ]);

  const getTiers = async () => {
    try {
      const res = await api.get("/api/tiers");

      setSpendLimit(
        res.data.data.map(({ name, minimum_spend }) => {
          return { name: sentenceCase(name), val: minimum_spend };
        }) || []
      );
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  useEffect(() => {
    getTiers();
  }, []);

  return (
    <View
      style={{
        flexDirection: "row",
        marginBottom: 32,
        marginLeft: 9,
      }}
    >
      <View
        style={{
          alignItems: "flex-end",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: 36,
          }}
        >
          {position === "regular" ? (
            <View
              style={{ flexDirection: "row", alignItems: "center", height: 36 }}
            >
              <LinearGradient
                colors={[colors.greyE6, colors.grey90]}
                style={{
                  width: (current / 1000000000) * (isPhone ? 85 : 120),
                  height: 9,
                }}
                start={{ x: -1, y: 0.5 }}
                end={{ x: 2, y: 0.5 }}
              />
              <Shimmer
                autoRun={true}
                visible={false}
                width={(1 - current / 1000000000) * (isPhone ? 85 : 120)}
                height={9}
                colorShimmer={[colors.greyE6, colors.silverEF]}
                style={{ borderRadius: 0 }}
              />
              <View
                style={{
                  position: "absolute",
                  left: (current / 1000000000) * (isPhone ? 85 : 120),
                  marginLeft: -9,
                  padding: 6,
                  borderWidth: 2,
                  borderRadius: "100%",
                  borderColor: colors.grey33,
                  backgroundColor: colors.whiteFF,
                  zIndex: 10,
                }}
              >
                <SlDiamond size={20} color={colors.grey33} />
              </View>
            </View>
          ) : (
            <LinearGradient
              colors={
                position === "regular"
                  ? [colors.greyE6, colors.grey90]
                  : [colors.greyE6, colors.grey90, colors.greyE6]
              }
              style={{ width: isPhone ? 85 : 120, height: 9 }}
              start={{ x: -1, y: 0.5 }}
              end={{ x: 2, y: 0.5 }}
            />
          )}
        </View>
        <View
          style={{
            borderRightWidth: 1,
            borderRightColor: colors.grey33,
            paddingTop: 10,
            paddingRight: 10,
            marginRight: -9,
            alignItems: "flex-end",
            marginTop: -10,
            zIndex: 5,
          }}
        >
          <PTSerifBold
            style={{ fontSize: 12, lineHeight: 16, color: colors.grey5F }}
          >
            {spendLimit[0]?.name || ""}
          </PTSerifBold>
          <LatoRegular
            style={{ fontSize: 12, lineHeight: 16, color: colors.grey5F }}
          >
            {`> IDR ${formatCurrency(spendLimit[0]?.val, true, true)}`}
          </LatoRegular>
        </View>
      </View>
      <View style={{ alignItems: "flex-end" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: 36,
          }}
        >
          <FaCircle size={16} color={colors.grey90} />
          {position === "togo" ? (
            <View
              style={{ flexDirection: "row", alignItems: "center", height: 36 }}
            >
              <LinearGradient
                colors={[colors.greyE6, colors.grey6C, colors.green8D]}
                style={{
                  width:
                    ((current - 1000000000) / 1000000000) *
                    (isPhone && width < 520 ? 85 : isTablet ? 170 : 240),
                  height: 9,
                }}
                start={{ x: -1, y: 0.5 }}
                end={{ x: 2, y: 0.5 }}
              />
              <Shimmer
                autoRun={true}
                visible={false}
                width={
                  (1 - (current - 1000000000) / 1000000000) *
                  (isPhone && width < 520 ? 85 : isTablet ? 170 : 240)
                }
                height={9}
                colorShimmer={[colors.greyE6, colors.silverEF]}
                style={{ borderRadius: 0 }}
              />
              <View
                style={{
                  position: "absolute",
                  left:
                    ((current - 1000000000) / 1000000000) *
                    (isPhone && width < 520 ? 85 : isTablet ? 170 : 240),
                  marginLeft: -9,
                  padding: 6,
                  borderWidth: 2,
                  borderRadius: "100%",
                  borderColor: colors.grey33,
                  backgroundColor: colors.whiteFF,
                  zIndex: 10,
                }}
              >
                <SlDiamond size={20} color={colors.grey33} />
              </View>
            </View>
          ) : (
            <LinearGradient
              colors={
                position === "regular" || position === "togo"
                  ? [colors.greyE6, colors.greyE6]
                  : [colors.greyE6, colors.green8D]
              }
              style={{
                width: isPhone && width < 520 ? 85 : isTablet ? 170 : 240,
                height: 9,
              }}
              start={{ x: -1, y: 0.5 }}
              end={{ x: 2, y: 0.5 }}
            />
          )}
        </View>
        <View
          style={{
            borderRightWidth: 1,
            borderRightColor: colors.grey33,
            paddingTop: 10,
            paddingRight: 10,
            marginRight: -9,
            alignItems: "flex-end",
            marginTop: -10,
            zIndex: 5,
          }}
        >
          <PTSerifBold
            style={{ fontSize: 12, lineHeight: 16, color: colors.grey5F }}
          >
            {spendLimit[1]?.name || ""}
          </PTSerifBold>
          <LatoRegular
            style={{ fontSize: 12, lineHeight: 16, color: colors.grey5F }}
          >
            {`> IDR ${formatCurrency(spendLimit[1]?.val, true, true)}`}
          </LatoRegular>
        </View>
      </View>
      <View style={{ alignItems: "flex-end" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: 36,
          }}
        >
          <FaCircle size={16} color={colors.green5B} />
          {position === "ostrich" ? (
            <View
              style={{ flexDirection: "row", alignItems: "center", height: 36 }}
            >
              <LinearGradient
                colors={[colors.green8D, colors.yellowCC]}
                style={{
                  width:
                    ((current - 2000000000) / 2000000000) *
                    (isPhone && width < 520 ? 85 : isTablet ? 170 : 240),
                  height: 9,
                }}
                start={{ x: -1, y: 0.5 }}
                end={{ x: 2, y: 0.5 }}
              />
              <Shimmer
                autoRun={true}
                visible={false}
                width={
                  (1 - (current - 2000000000) / 2000000000) *
                  (isPhone && width < 520 ? 85 : isTablet ? 170 : 240)
                }
                height={9}
                colorShimmer={[colors.greyE6, colors.silverEF]}
                style={{ borderRadius: 0 }}
              />
              <View
                style={{
                  position: "absolute",
                  left:
                    ((current - 2000000000) / 2000000000) *
                    (isPhone && width < 520 ? 85 : isTablet ? 170 : 240),
                  marginLeft: -9,
                  padding: 6,
                  borderWidth: 2,
                  borderRadius: "100%",
                  borderColor: colors.grey33,
                  backgroundColor: colors.whiteFF,
                  zIndex: 10,
                }}
              >
                <SlDiamond size={20} color={colors.grey33} />
              </View>
            </View>
          ) : (
            <LinearGradient
              colors={
                position === "croco"
                  ? [colors.green8D, colors.yellowCC]
                  : [colors.greyE6, colors.greyE6]
              }
              style={{
                width: isPhone && width < 520 ? 85 : isTablet ? 170 : 240,
                height: 9,
              }}
              start={{ x: -1, y: 0.5 }}
              end={{ x: 2, y: 0.5 }}
            />
          )}
        </View>
        <View
          style={{
            borderRightWidth: 1,
            borderRightColor: colors.grey33,
            paddingTop: 10,
            paddingRight: 10,
            marginRight: -9,
            alignItems: "flex-end",
            marginTop: -10,
            zIndex: 5,
          }}
        >
          <PTSerifBold
            style={{ fontSize: 12, lineHeight: 16, color: colors.grey5F }}
          >
            {spendLimit[2]?.name || ""}
          </PTSerifBold>
          <LatoRegular
            style={{ fontSize: 12, lineHeight: 16, color: colors.grey5F }}
          >
            {`> IDR ${formatCurrency(spendLimit[2]?.val, true, true)}`}
          </LatoRegular>
        </View>
      </View>
      <View style={{ alignItems: "flex-end" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: 36,
          }}
        >
          {position === "croco" ? (
            <View
              style={{
                marginLeft: -9,
                padding: 6,
                borderWidth: 2,
                borderRadius: "100%",
                borderColor: colors.grey33,
                backgroundColor: colors.whiteFF,
                zIndex: 10,
              }}
            >
              <SlDiamond size={20} color={colors.grey33} />
            </View>
          ) : (
            <FaCircle size={16} color={colors.yellowCC} />
          )}
        </View>
      </View>
    </View>
  );
}

export default RewardTracker;

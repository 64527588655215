import React from "react";
import { View, Image, ScrollView, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import AboutUs from "../assets/images/about-us-1.png";
import AboutUsPhone from "../assets/images/about-us-1.png";
import About1 from "../assets/images/about-line-1.png";
import About2 from "../assets/images/about-line-2.png";
import About2New from "../assets/images/about-line-2-new.jpg";
import About3 from "../assets/images/about-line-3.png";
import colors from "../assets/colors/colors";
import { LatoRegular } from "../components/Text";
import { getDeviceType } from "../tools/Interface";
import Footer from "../components/Footer";
import Commitment from "../components/Home/Commitment";
import VisitUs from "../components/Home/VisitUs";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";
import { ImageBackground } from "react-native-web";

export default function AboutUsScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const navigate = useNavigate();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;
  const bannerHeight = isPhone ? 360 : (width * 600) / 1920

  return (
    <View>
      <ScrollView
        style={{ height: "100vh" }}
        showsVerticalScrollIndicator={false}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <ImageBackground
          source={isPhone ? AboutUsPhone : AboutUs}
          alt={"About Us"}
          style={{
            alignSelf: "center",
            width,
            height: bannerHeight,
          }}
          defaultSource={require("../assets/images/spinning-loading.gif")}
          resizeMode={isPhone ? "cover" : "contain"}
        >
          <View style={{
            width,
            height: bannerHeight,
            justifyContent: 'center',
          }}>
            <LatoRegular
              style={{
                fontSize: isPhone ? 24 : isTablet ? 48 : 60,
                lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
                textAlign: "center",
                color: colors.whiteFF,
              }}
            >
              RELEASE. RELOVE. REVOLVE.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : 24,
                lineHeight: isPhone ? 16 : 32,
                textAlign: "center",
                color: colors.whiteFF,
              }}
            >
              GIVE YOUR LUXURY GOODS A SECOND LIFE.
            </LatoRegular>
          </View>
        </ImageBackground>
        <View
          style={{
            flexDirection: isPhone ? "column" : "row",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "center" : "space-between",
            marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 48 : 100,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <Image
            source={About1}
            alt={"A Trusted Marketplace"}
            style={{
              alignSelf: "center",
              width: isPhone ? containerWidth : (containerWidth - 40) / 2,
              height: isPhone
                ? (containerWidth * 281) / 358
                : (containerWidth - 40) / 2,
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: isPhone ? 0 : isTablet ? 24 : 60,
              marginLeft: isPhone ? 0 : 24,
              gap: 32,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              A TRUSTED MARKETPLACE
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              Our ROOM is a marketplace where members of our community can buy, consign, trade-in and sell luxury goods. We believe in giving high-end items a second life, offering people the chance to own their dream pieces while helping original owners get the most value from their investments.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              We are a resource for tens of thousands of members in our community to trade, sell, consign, and shop luxury goods. We believe in extending the life of luxury items to transform the way we consume them. Our vision is to inspire our community to reduce waste in the fashion industry that promotes sustainability and contributes to the circular economy.
            </LatoRegular>
            <Buttons
              label="SHOP COLLECTIONS"
              onPress={() => navigate("/products")}
              noBorder
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: isPhone ? "column" : "row-reverse",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "center" : "space-between",
            marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 24 : 100,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <Image
            source={About2New}
            alt={"A Trusted Marketplace"}
            style={{
              alignSelf: "center",
              width: isPhone ? containerWidth : (containerWidth - 40) / 2,
              height: isPhone
                ? (containerWidth * 281) / 358
                : (containerWidth - 40) / 2,
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: isPhone ? 0 : isTablet ? 24 : 60,
              marginRight: isPhone ? 0 : 24,
              gap: 32,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              HOW WE STARTED
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              In 2016, Ardine Nakety recognized a gap in how luxury items were managed—many struggled to part with pieces they no longer used while seeking new additions to their wardrobes. This insight led her to create Belle&Kate ROOM, a secure marketplace where luxury goods seamlessly #RELEASE, #RELOVE, and #REVOLVE.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              Today, Belle&Kate is a leading name in Indonesia’s pre-owned luxury market, championing sustainable fashion by extending the life cycle of high-end items. We connect buyers with their dream designer pieces while enabling sellers to unlock the value of their investments—driving a circular, conscious approach to luxury.
            </LatoRegular>
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 24 : 100,
          }}
        >
          <Image
            source={isPhone ? AboutUsPhone : About3}
            alt={"A Trusted Marketplace"}
            style={{
              alignSelf: "center",
              width: containerWidth,
              height:
                (containerWidth * (isPhone ? 281 : 500)) /
                (isPhone ? 358 : 1460),
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: containerWidth,
              justifyContent: "center",
              alignItems: "center",
              gap: 32
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 32,
              }}
            >
              HOW WE HAVE EVOLVED
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              Over the years, Belle&Kate has become the go-to trusted marketplace to buy, sell and trade amongst our community. In 2019, we opened our first showroom for our customers’ private viewing in PIK, North Jakarta and in 2021, relocated to the prestigious Fairgrounds at SCBD, South Jakarta.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              Belle&Kate connects fashion lovers who value luxury and sustainability. We cater to style-savvy millennials, Gen Z shoppers, professionals, and collectors looking for premium designer pieces without the full price tag. Whether they’re buying or selling, our customers appreciate mindful consumption and see the value in giving luxury items a second life.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              We strive to continue growing our community through intimate in store events and aim to give the best service from our dedicated client advisors towards our customers. Our personalised services, such as Home Shopping for VIP customers provides tailored advice on the best options for consignment, direct sale, or trade-in.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
              }}
            >
              As the years go by, we will keep expanding our stores to continuously reach new markets. Now, our newly expanded Jakarta store has been relaunched with more curated selections of pre-owned luxury goods, such as watches and vintage luxury goods. We expanded our presence to Singapore in August 2023, opening a new location in the prominent Orchard area at 313@somerset, Level B1.
            </LatoRegular>
          </View>
        </View>
        <Commitment />
        <VisitUs />
        <Footer />
      </ScrollView>
    </View>
  );
}

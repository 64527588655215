import React from "react";
import { View, useWindowDimensions } from "react-native";
import { PiStarFour, PiLeaf, PiCallBell } from "react-icons/pi";
import { MdOutlineThumbUp } from "react-icons/md";
import { getDeviceType } from "../tools/Interface";
import colors from "../assets/colors/colors";
import { LatoBold } from "./Text";

function ProductTag({
  containerStyle = {},
  influencer = false,
  isDetails = false,
  editor = false,
  vintage = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isTablet } = getDeviceType({ width, height });

  const iconProps = {
    size: isTablet && !isDetails ? 12 : 20,
  };

  let IconComponent;
  let text;
  let color;
  let backgroundColor;

  if (vintage) {
    IconComponent = PiCallBell; // Replace with the appropriate icon for vintage
    text = "Vintage";
    color = colors.brown4A; // Assuming you have a brown color defined
    backgroundColor = colors.brownE0
  } else if (influencer) {
    IconComponent = PiStarFour;
    text = "Influencer Pre-loved";
    color = colors.pinkCB;
    backgroundColor = colors.lightPinkCB
  } else {
    IconComponent = MdOutlineThumbUp;
    text = "Editor's Pick";
    color = colors.green5B;
    backgroundColor = colors.lightGreen5B
  }

  return Boolean(influencer || editor || vintage) ? (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: backgroundColor,
        padding: 8,
        borderRadius: 3.4,
        ...containerStyle,
      }}
    >
      <IconComponent {...iconProps} color={color} />
      <LatoBold
        style={{
          fontSize: isTablet && !isDetails ? 8 : 14,
          lineHeight: isTablet && !isDetails ? 12 : 20,
          color: color,
          marginLeft: 8,
        }}
      >
        {text}
      </LatoBold>
    </View>
  ) : (
    <View />
  );
}

export default ProductTag;

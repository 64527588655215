import React, { useEffect, useRef, useState } from "react";
import { ScrollView, View, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Tradein from "../assets/images/tradein.png";
import TopComponent from "../components/SellWithUs/TopComponent";
import SellCover from "../components/SellWithUs/SellCover";
import AboutSell from "../components/SellWithUs/AboutSell";
import ProductCover from "../components/Home/ProductCover";
import { useParams } from "react-router";
import HowToSell from "../components/SellWithUs/HowToSell";
import VisitUs from "../components/Home/VisitUs";
import Modal from "../components/Modal";
import RateModal from "../components/SellWithUs/RateModal";
import VideoModal from "../components/VideoModal";
import { getDeviceType } from "../tools/Interface";
import { LinksFromCDN } from "../constants/cdnLinks";

function SellWithUsScreen() {
  const { height, width } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { category: cat } = useParams();

  const scrollRef = useRef();

  const [openRate, setOpenRate] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const [yPosition, setYPosition] = useState(0);
  const [aboutPosition, setAboutPosition] = useState(0);

  const menuArr = [
    {
      title: "DIRECT SELL",
      category: "direct-sell",
      cover: LinksFromCDN.diorCoverImage,
      about:
        "We offer direct selling (buy-out) option, a simple and easy way to sell your bag, where you can immediately earn top dollar for it. Plus, we'll handle all of the shipping and payments, so you don't have to worry about a thing.\n\nThe selling price will be lowest compared to consignment and trade-in because Belle&Kate has taken the risk to stock your bag(s).",
    },
    {
      title: "TRADE IN",
      category: "trade-in",
      cover: Tradein,
      about:
        "Receive hard cash right away from Belle&Kate! However, you have to trade-in by buying our available stocks in store. The selling price for trade-in will be lower than consignment, but higher than direct selling.",
    },
    {
      title: "CONSIGNMENT",
      category: "consignment",
      cover: LinksFromCDN.louisVuittonCoverImage,
      about:
        "Our consignment option will get you the highest price in comparison to our other selling options.",
    },
  ];

  useEffect(() => {
    if (yPosition) {
      scrollRef?.current?.scrollTo({
        y: yPosition - 250,
        animated: true,
      });
      setYPosition(0);
    }
  }, [yPosition]);

  useEffect(() => {
    scrollRef?.current?.scrollTo({
      y: 0,
      animated: true,
    });
    setYPosition(0);
  }, [cat]);

  return (
    <View>
      <ScrollView
        ref={scrollRef}
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <TopComponent menuArr={menuArr} cat={cat} />
        <SellCover
          menuArr={menuArr}
          cat={cat}
          setYPosition={setYPosition}
          aboutPosition={aboutPosition}
        />
        <AboutSell
          menuArr={menuArr}
          cat={cat}
          setAboutPosition={setAboutPosition}
          isVideo
          setOpenVideo={setOpenVideo}
        />
        <ProductCover cat={cat} />
        <HowToSell cat={cat} setOpenRate={setOpenRate} />
        <VisitUs justText />
        <Footer />
      </ScrollView>
      {Boolean(openVideo) && (
        <Modal
          noBackground
          width={isPhone ? width - 40 : (isTablet ? 0.75 : 0.5) * width}
        >
          <VideoModal
            setOpenVideo={setOpenVideo}
            videoAttr={{ src: "https://www.youtube.com/watch?v=yqQfrJw8s0g" }}
          />
        </Modal>
      )}
      {Boolean(openRate) && (
        <Modal>
          <RateModal setOpenRate={setOpenRate} />
        </Modal>
      )}
    </View>
  );
}

export default SellWithUsScreen;

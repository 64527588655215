import { sentenceCase } from "change-case";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { GrLocation } from "react-icons/gr";
import { View, TouchableOpacity, useWindowDimensions } from "react-native";
import { useLocation, useNavigate, useParams } from "react-router";
import colors from "../../assets/colors/colors";
import { useAuth } from "../../contexts/AuthContext";
import { addressChecker } from "../../tools/checker";
import { getDeviceType } from "../../tools/Interface";
import { regexChecker } from "../../tools/regexChecker";
import Buttons from "../Buttons";
import { MyInput, SelectionDropDown } from "../Inputs";
import MapView from "react-native-web-maps";
import Marker from "react-native-web-maps/dist/Marker";

import { LatoRegular, PlayfairDisplayRegular } from "../Text";
import {
  geocodeByAddress,
  geocodeByLatLng,
  getLatLng,
} from "react-google-places-autocomplete";
import ToasterContext from "../../contexts/ToastContext";

export default function AddressForm({
  user,
  isCheckout = false,
  fromPayment = false,
  fromShipment = false,
  courier = {},
  setOpenModal = () => {},
  addressObj,
}) {
  const { loading, updateUser, loadingUpdateUser } = useAuth();
  const { email, address = {}, detail = {} } = user;
  const navigate = useNavigate();
  const { segment } = useParams();
  const { state } = useLocation();
  const { showToast } = useContext(ToasterContext);
  const {
    recipient_name,
    complete_address,
    recipient_number,
    province_id,
    city_id,
    suburb_id,
    area_id,
    instruction,
    latitude,
    longitude,
  } = address;

  const {
    complete_address: consignor_address,
    city_name,
    name,
    phone,
    instruction: detailInstruction,
  } = detail;

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [desiredLocation, setDesiredLocation] = useState({
    latitude:
      addressObj && addressObj?.geometry
        ? Number(addressObj?.geometry?.location?.lat())
        : Number(latitude),
    longitude:
      addressObj && addressObj?.geometry
        ? Number(addressObj?.geometry?.location?.lng())
        : Number(longitude),
    latitudeDelta: 0.01,
    longitudeDelta: 0.05,
  });

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const usedName = state?.consignment_data ? name : recipient_name;

  const initialValues = {
    firstname: usedName
      ?.split(" ")
      .splice(0, usedName.split(" ")?.length - 1)
      .join(" "),
    lastname: usedName
      ?.split(" ")
      .splice(usedName.split(" ")?.length - 1, 1)
      .join(" "),
    // province: { id: state?.consignment_data ? null : province_id },
    city: state?.consignment_data ? city_name : { id: city_id },
    // suburb: { id: state?.consignment_data ? null : suburb_id },
    // area: { id: state?.consignment_data ? null : area_id },
    instruction: state?.consignment_data
      ? detailInstruction || ""
      : instruction || "",
    address: state?.consignment_data ? consignor_address : complete_address,
    phone: state?.consignment_data ? phone : recipient_number,
    // latitude: Number(latitude),
    // longitude: Number(longitude),
    latitude: null,
    longitude: null,
  };

  const nameFormArr = [
    {
      name: "firstname",
      placeholder: "First Name",
      label: "First Name",
      required: true,
      initialValue: initialValues?.firstname,
    },
    {
      name: "lastname",
      placeholder: "Last Name",
      label: "Last Name",
      required: true,
      initialValue: initialValues?.lastname,
    },
  ];

  const locationArr = state?.consignment_data
    ? [
        {
          name: "city",
          label: "Town / City",
          required: true,
          initialValue: initialValues?.city,
          datas: [
            { name: "Jakarta Utara", id: "Jakarta Utara" },
            { name: "Jakarta Timur", id: "Jakarta Timur" },
            { name: "Jakarta Selatan", id: "Jakarta Selatan" },
            { name: "Jakarta Barat", id: "Jakarta Barat" },
            { name: "Jakarta Pusat", id: "Jakarta Pusat" },
            { name: "Tangerang Selatan", id: "Tangerang Selatan" },
            { name: "Tangerang, Kota", id: "Tangerang, Kota" },
          ],
        },
      ]
    : [
        // {
        //   name: "country",
        //   label: "Country",
        //   required: true,
        //   initialValue: "Indonesia",
        // },
        // {
        //   name: "province",
        //   label: "Province",
        //   required: true,
        //   initialID: initialValues?.province?.id,
        // },
        // {
        //   name: "city",
        //   label: "Town / City",
        //   required: true,
        //   initialID: initialValues?.city?.id,
        // },
        // {
        //   name: "suburb",
        //   label: "District",
        //   required: true,
        //   initialID: initialValues?.suburb?.id,
        // },
        // {
        //   name: "area",
        //   label: "Sub-District",
        //   required: true,
        //   initialID: initialValues?.area?.id,
        // },
      ];

  const detailDataArr = [
    {
      name: "address",
      placeholder: "Street address",
      label: "Street address",
      required: true,
      initialValue: initialValues?.address,
      helper:
        "Kindly input your complete address: street, city, state, and ZIP code.",
    },
    {
      name: "instruction",
      label: "Instruction ( optional )",
      placeholder: "Instruction ( optional )",
      initialValue: initialValues?.instruction,
      length: 100,
      type: "long-text",
      inline: true,
    },
    // {
    //   name: "postcode",
    //   placeholder: "Postcode / ZIP ( optional )",
    //   initialValue: complete_address,
    // },
    {
      name: "phone",
      placeholder: "Phone",
      label: "Phone",
      required: true,
      initialValue: initialValues?.phone,
      prefix: "+62",
    },
  ];

  const handleChanges = async (value) => {
    const {
      firstname,
      lastname,
      address,
      phone,
      province,
      city,
      suburb,
      area,
      instruction,
    } = value;
    try {
      const needToUpdate = addressChecker(
        {
          ...value,
          latitude: desiredLocation.latitude,
          longitude: desiredLocation.longitude,
        },
        initialValues
      );
      const reg = /^[a-zA-Z0-9\s#&'.=\+\,\(\)\-]+$/;
      const engineeredAddress = address
        .split("")
        .map((itm) => {
          if (reg.test(itm)) return itm;
          return " ";
        })
        .join("");
      const engineeredInstruction = instruction
        .split("")
        .map((itm) => {
          if (reg.test(itm)) return itm;
          return " ";
        })
        .join("");

      if (needToUpdate) {
        const sendToBE = {
          user: state?.consignment_data
            ? {
                detail_attributes: {
                  complete_address: engineeredAddress,
                  city_name: city?.name,
                  instruction: engineeredInstruction,
                  phone,
                },
              }
            : {
                address_attributes: {
                  complete_address: engineeredAddress,
                  recipient_name: `${firstname} ${lastname}`,
                  recipient_number: phone,
                  instruction: engineeredInstruction,
                  latitude: desiredLocation?.latitude,
                  longitude: desiredLocation?.longitude,
                },
              },
        };

        await updateUser(sendToBE);
      }

      isCheckout
        ? navigate(fromPayment ? "/checkout/payment" : "/checkout/shipment", {
            state: { courier },
          })
        : state?.consignment_data
        ? navigate("/account/consignment/assisted-listing", {
            state: {
              consignment_data: state?.consignment_data,
            },
          })
        : navigate(`/account/${segment}`, {
            state: { idx: state?.idx, index: state?.index },
          });
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  useEffect(() => {
    if (addressObj) {
      addressObj?.geometry
        ? setDesiredLocation({
            ...desiredLocation,
            latitude: Number(addressObj?.geometry?.location?.lat()),
            longitude: Number(addressObj?.geometry?.location?.lng()),
          })
        : geocodeByAddress(addressObj?.label)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
              setDesiredLocation({
                ...desiredLocation,
                latitude: Number(lat),
                longitude: Number(lng),
              });
            });
    } else {
      setDesiredLocation({
        ...desiredLocation,
        latitude: Number(latitude),
        longitude: Number(longitude),
      });
    }
  }, [addressObj, address]);

  return (
    <View style={{ width: isPhone && !isCheckout ? width - 32 : "100%" }}>
      <LatoRegular style={{ fontSize: 20, lineHeight: 28, marginBottom: 24 }}>
        {state?.consignment_data ? "Pickup Address" : "Shipping Address"}
      </LatoRegular>
      {loading ? null : (
        <Formik
          onSubmit={handleChanges}
          validateOnChange={hasSubmitted}
          validateOnBlur={hasSubmitted}
          initialValues={{
            // email: email,
            firstname: initialValues?.firstname,
            lastname: initialValues?.lastname,
            phone: initialValues?.phone,
            address: initialValues?.address,
            instruction: initialValues?.instruction,
            latitude: initialValues?.latitude,
            longitude: initialValues?.longitude
          }}
          validate={(values) => {
            !hasSubmitted && setHasSubmitted(true);
            const errors = {};

            const checkArr = [...nameFormArr, ...detailDataArr];

            for (const a of checkArr) {
              const { name, required, placeholder, length, match, disabled } =
                a;

              if (disabled) {
                errors[name] = "";
              } else if (required && !disabled && !values[name]) {
                errors[name] = `${sentenceCase(placeholder)} can't be blank`;
              } else if (!regexChecker(name, values[name])) {
                errors[name] =
                  name === "password"
                    ? "Password must includes 1 uppercase, 1 lowercase, and 1 number"
                    : `Invalid ${placeholder.toLowerCase()}`;
              } else if (length && values[name]?.length > length) {
                errors[name] = `${sentenceCase(
                  name.split("_").join(" ")
                )} limited to ${length} characters`;
              } else if (match && values[name] !== values[match]) {
                errors[name] = `${sentenceCase(
                  match.split("_").join(" ")
                )} doesn't match`;
              } else if (name === "phone" && !values[name].startsWith("8")) {
                errors[name] =
                  "Please enter a valid Indonesian mobile phone number starting with 8xx";
              }
            }
            if (!desiredLocation.latitude || !desiredLocation.longitude) {
              errors.location = "Location must be marked on the map";
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {nameFormArr.map(
                  (
                    {
                      name,
                      placeholder,
                      required,
                      hide,
                      initialValue,
                      disabled,
                      length,
                      label,
                    },
                    index
                  ) => {
                    return (
                      <View key={index}>
                        <MyInput
                          error={errors[name]}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          name={name}
                          label={values?.name || initialValue ? label : ""}
                          placeholder={placeholder}
                          required={required}
                          hide={hide}
                          initialValue={initialValue}
                          customWidth={
                            isCheckout
                              ? (isCheckout - 32) / 2
                              : isPhone
                              ? "40vw"
                              : "30vw"
                          }
                          disabled={disabled}
                          length={length}
                        />
                      </View>
                    );
                  }
                )}
              </View>
              {Boolean(!state?.consignment_data) && (
                <View
                  style={{
                    padding: 16,
                    borderWidth: 1,
                    borderColor: colors.greyE6,
                    borderRadius: 4,
                    marginVertical: 32,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 18,
                    }}
                  >
                    <GrLocation size={20} color={colors.grey33} />
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginLeft: 4,
                      }}
                    >
                      Mark your delivery location
                    </LatoRegular>
                    <LatoRegular style={{ color: colors.redB6 }}>*</LatoRegular>
                    {errors.location && (
                      <LatoRegular style={{ color: colors.redB6, marginLeft: 8 }}>
                        Delivery location must be marked
                      </LatoRegular>
                    )}
                  </View>
                  {Boolean(
                    (latitude && longitude) || (addressObj && addressObj?.label)
                  ) && (
                    <View
                      style={{ height: 300, width: "100%", marginBottom: 24 }}
                    >
                      <MapView
                        region={desiredLocation}
                        options={{
                          disableDefaultUI: true,
                        }}
                      >
                        <Marker coordinate={desiredLocation} />
                      </MapView>
                    </View>
                  )}
                  <Buttons
                    onPress={() => {
                      setOpenModal(true);
                    }}
                    label={
                      Boolean(
                        (latitude && longitude) ||
                          (addressObj && addressObj?.label)
                      )
                        ? "Change location"
                        : "Mark Location"
                    }
                    isBlack
                    noBorder
                    width={latitude && longitude ? 140 : 120}
                    height={24}
                  />
                </View>
              )}
              {locationArr.map(
                (
                  { name, label, required, initialID, initialValue, datas },
                  index
                ) => {
                  return (
                    <View key={index}>
                      <SelectionDropDown
                        label={label}
                        required={required}
                        values={values}
                        name={name}
                        setValue={setFieldValue}
                        initialID={initialID}
                        initialValue={initialValue}
                        datas={datas}
                      />
                    </View>
                  );
                }
              )}
              {detailDataArr.map(
                (
                  {
                    name,
                    placeholder,
                    required,
                    hide,
                    initialValue,
                    disabled,
                    prefix,
                    label,
                    type,
                    length,
                    inline,
                    helper,
                  },
                  index
                ) => {
                  return (
                    <View key={index}>
                      <MyInput
                        prefix={prefix}
                        error={errors[name]}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        required={required}
                        hide={hide}
                        initialValue={initialValue}
                        customWidth={
                          isCheckout
                            ? isTablet
                              ? isCheckout
                              : isCheckout + 100
                            : isPhone
                            ? "95vw"
                            : "72.5vw"
                        }
                        disabled={disabled}
                        multiline={type === "long-text"}
                        bordered={type === "long-text"}
                        length={length}
                        color={
                          Boolean(values[name] && type === "long-text")
                            ? colors.grey33
                            : undefined
                        }
                        value={values[name]}
                        inline={inline}
                        helper={helper}
                      />
                    </View>
                  );
                }
              )}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: isCheckout ? "space-between" : "flex-start",
                  marginTop: 32,
                }}
              >
                {Boolean(isCheckout) && (
                  <Buttons
                    label={
                      fromPayment
                        ? "Return to Payment"
                        : fromShipment
                        ? "Return to Shipment"
                        : "Return to Cart"
                    }
                    width={fromPayment || fromShipment ? 120 : 100}
                    noBorder
                    onPress={() => navigate(-1)}
                  />
                )}
                <Buttons
                  label={
                    isCheckout && !fromPayment && !fromShipment
                      ? "Continue to Shipping"
                      : "Save Changes"
                  }
                  width={isPhone ? 150 : 250}
                  isBlack
                  onPress={handleSubmit}
                  disabled={
                    loading ||
                    loadingUpdateUser
                  }
                />
                {Boolean(!isCheckout) && (
                  <Buttons
                    label={"Cancel"}
                    width={42}
                    noBorder
                    onPress={() => {
                      if (state?.consignment_data)
                        return navigate(
                          "/account/consignment/assisted-listing",
                          {
                            state: {
                              consignment_data: state?.consignment_data,
                            },
                          }
                        );

                      navigate(`/account/${segment}`, {
                        state: { idx: state?.idx, index: state?.index },
                      });
                    }}
                    secondButton
                  />
                )}
              </View>
            </View>
          )}
        </Formik>
      )}
    </View>
  );
}

import React, { useEffect, useState } from "react";
import {
  ScrollView,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getDeviceType } from "../tools/Interface";
import { LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import Editorial from "../components/Home/Editorial";
import Classroom from "../components/Home/Classroom";
import { api } from "../utils/api";

function EditorialScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const [selected, setSelected] = useState("all");
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([
    {
      title: "SHOW ALL",
      value: "all",
      array: [],
    },
    {
      title: "Press Coverage",
      value: "press_coverage",
      array: [],
    },
    {
      title: "Belle&kate Class",
      value: "belle&kate_class",
      array: [],
    },
    {
      title: "Fresh from Her Room",
      value: "fresh_from_her_room",
      array: [],
    },
    {
      title: "Belle&kate Adores",
      value: "belle&kate_adores",
      array: [],
    },
    {
      title: "Campaign",
      value: "campaign",
      array: [],
    },
  ]);

  const getArticles = async () => {
    try {
      setLoading(true);

      const res = await api.get("/api/editorials/articles");
      const res2 = await api.get("/api/editorials/crushes");

      menu[0].array =
        [
          ...res?.data?.data.filter(
            ({ category }) => category !== "fresh_from_her_room"
          ),
          ...res2?.data?.data,
        ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) || [];
      menu[1].array =
        res?.data?.data?.filter(
          ({ category }) => category === "press_coverage"
        ) || [];
      menu[2].array =
        res?.data?.data?.filter(
          ({ category }) => category === "belle&kate_class"
        ) || [];
      menu[3].array =
        res?.data?.data?.filter(
          ({ category }) => category === "fresh_from_her_room"
        ) || [];
      // menu[4].array = res2?.data?.data || [];
      menu[4].array =
        res?.data?.data?.filter(
          ({ category }) => category === "belle&kate_adores"
        ) || [];
      menu[5].array =
        res?.data?.data?.filter(({ category }) => category === "campaign") ||
        [];
      // menu[6].array =
      // res?.data?.data?.filter(
      //   ({ category }) => category === "belle&kate_adores"
      // ) || [];

      setMenu(menu);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: isPhone ? 16 : 100,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
            marginBottom: isPhone ? 48 : 100,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 24 : isTablet ? 48 : 60,
              lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
              color: colors.black00,
              marginBottom: 16,
            }}
          >
            EDITORIAL
          </LatoRegular>
          <LatoRegular
            style={{
              fontSize: isPhone ? 12 : isTablet ? 16 : 24,
              lineHeight: isPhone ? 16 : isTablet ? 24 : 32,
              textAlign: "center",
              color: colors.grey6C,
              width: isPhone ? width - 32 : width - 300,
            }}
          >
            Belle&Kate doesn't just dictate your looks, we curate and style it
            to make you look your best. In our Editorial section discover the
            trends of the season, Insta-worthy pieces, how designers think, how
            influencers behave, and what makes you tick.
          </LatoRegular>
        </View>
        <Classroom loading={loading} woHeader data={menu[0].array} />
        <Editorial loading={loading} isEditorial data={menu[3].array} />
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: isPhone ? 48 : 100,
            paddingTop: isPhone ? 0 : 24,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          {menu
            .filter(({ value }) => value !== "fresh_from_her_room")
            .map(({ value, title }, i) => (
              <TouchableOpacity
                key={i}
                style={{
                  marginRight: menu.length - 1 === i ? 0 : isPhone ? 16 : 40,
                  paddingBottom: 1,
                  borderBottomColor: colors.black00,
                  borderBottomWidth: selected === value ? 1 : 0,
                  marginBottom: isPhone ? 16 : 24,
                }}
                onPress={() => setSelected(value)}
              >
                <LatoRegular
                  style={{
                    fontSize: isPhone ? 12 : 14,
                    lineHeight: isPhone ? 16 : 20,
                    color: colors.black00,
                    textTransform: "uppercase",
                  }}
                >
                  {title}
                </LatoRegular>
              </TouchableOpacity>
            ))}
        </View>
        <View style={{ marginBottom: isTablet ? 48 : 100 }}>
          <Classroom
            woHeader
            isEditorial
            data={menu.filter(({ value }) => value === selected)[0].array}
            loading={loading}
          />
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default EditorialScreen;

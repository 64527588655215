import React, { useContext, useState } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import Navbar from "../components/Navbar";
import { LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { api } from "../utils/api";
import { getDeviceType } from "../tools/Interface";
import { MyInput } from "../components/Inputs";
import { useAuth } from "../contexts/AuthContext";
import { sentenceCase } from "change-case";
import { regexChecker } from "../tools/regexChecker";
import Buttons from "../components/Buttons";
import { ToasterContext } from "../contexts/ToastContext";
import Snackbars from "../components/Snackbars";
import Footer from "../components/Footer";
import { LinksFromCDN } from "../constants/cdnLinks";

export default function LoginScreen() {
  const [loading, setLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { authorize, user, refetch } = useAuth();
  const navigate = useNavigate();
  const { showToast } = useContext(ToasterContext);

  const formFieldArr = [
    {
      name: "email",
      placeholder: "Email Address",
      required: true,
    },
    {
      name: "password",
      placeholder: "Password",
      required: true,
      hide: true,
      length: 8,
    },
  ];

  const handleLogin = async (value) => {
    try {
      setLoading(true);
      await api.post("/api/auth/sign_in", value);
      await authorize();
      await refetch();
    } catch (err) {
      console.log("err:", err);
      showToast(err, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        {Boolean(user?.id) && (
          <Navigate to={"/"} replace={true} relative={"path"} />
        )}
        <Navbar />
        <View style={{ flexDirection: isPhone ? "column" : "row" }}>
          <Image
            source={LinksFromCDN.login}
            style={{
              height: isPhone ? "40vh" : "100vh",
              width: isPhone ? "100vw" : "50vw",
              marginTop: -88,
            }}
          />
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: isPhone ? "100vw" : "50vw",
              paddingHorizontal: isTablet ? 40 : 190,
              marginTop: isPhone ? 36 : 0,
            }}
          >
            <LatoRegular
              style={{ fontSize: 24, lineHeight: 32, marginBottom: 24 }}
            >
              Login
            </LatoRegular>
            <Formik
              onSubmit={handleLogin}
              initialValues={{ email: "", password: "" }}
              validateOnChange={hasSubmitted}
              validateOnBlur={hasSubmitted}
              validate={(values) => {
                !hasSubmitted && setHasSubmitted(true);
                const errors = {};

                for (const a of formFieldArr) {
                  const { name, required, placeholder, length, match } = a;

                  if (required && !values[name]) {
                    errors[name] = `${sentenceCase(
                      placeholder
                    )} can't be blank`;
                  } else if (
                    name !== "password" &&
                    !regexChecker(name, values[name])
                  ) {
                    errors[name] =
                      name === "password"
                        ? "Password must includes 1 uppercase, 1 lowercase, and 1 number"
                        : `Invalid ${placeholder.toLowerCase()}`;
                  } else if (length && values[name].length < length) {
                    errors[name] = `${sentenceCase(
                      name.split("_").join(" ")
                    )} must have at least ${length} characters`;
                  } else if (match && values[name] !== values[match]) {
                    errors[name] = `${sentenceCase(
                      match.split("_").join(" ")
                    )} doesn't match`;
                  }
                }
                return errors;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <View>
                  {formFieldArr.map(
                    ({ name, placeholder, required, hide }, index) => {
                      return (
                        <View key={index}>
                          <MyInput
                            error={errors[name]}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            name={name}
                            placeholder={placeholder}
                            required={required}
                            hide={hide}
                            label={values[name] ? placeholder : ""}
                          />
                        </View>
                      );
                    }
                  )}
                  <TouchableOpacity
                    onPress={() => navigate("/forgot-password")}
                    style={{ alignSelf: "flex-end" }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 16,
                        lineHeight: 24,
                        color: colors.grey6C,
                        textDecorationLine: "underline",
                      }}
                    >
                      forgot your password?
                    </LatoRegular>
                  </TouchableOpacity>
                  <Buttons
                    label="Login"
                    width={"100%"}
                    isBlack
                    onPress={handleSubmit}
                    disabled={errors.email || errors.password}
                    loading={loading}
                    borderRadius={6}
                    noBorder
                    containerStyle={{ marginVertical: 32 }}
                  />
                </View>
              )}
            </Formik>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: isPhone ? "80vw" : isTablet ? "40vw" : "32.5vw",
              }}
            >
              <LatoRegular
                style={{
                  fontSize: 16,
                  lineHeight: 24,
                  color: colors.grey6C,
                }}
              >
                {"New client? "}
              </LatoRegular>
              <TouchableOpacity
                onPress={() => navigate("/register")}
                style={{ alignSelf: "flex-end" }}
              >
                <LatoRegular
                  style={{
                    fontSize: 16,
                    lineHeight: 24,
                    color: colors.grey33,
                    fontFamily: "Lato-Regular",
                  }}
                >
                  create an account
                </LatoRegular>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

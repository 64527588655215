import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import { ScrollView, View, Image, Linking, TouchableOpacity, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import { useLocation, useParams } from "react-router";
import { getDeviceType } from "../tools/Interface";
import { SlBulb, SlPeople, SlRocket, SlStar } from "react-icons/sl";
import colors from "../assets/colors/colors";
import { BsArrowRightShort } from "react-icons/bs";
import { LatoBold, LatoRegular } from "../components/Text";
import ExplainationSell from "../components/SellWithUs/ExplainationSell";
import ToasterContext from "../contexts/ToastContext";
import { api } from "../utils/api";
import Markdown from 'react-markdown'

function CareersDetailScreen() {
  const scrollRef = useRef();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - 2 * gap;

  const { showToast } = useContext(ToasterContext);
  const { id } = useParams();
  const [jobOpeningDetail, setJobOpeningDetail] = useState(undefined);
  const getJobOpeningDetail = async () => {
    try {
      const res = await api.get(`/api/job_openings/${id}`);

      setJobOpeningDetail(res.data.data || null);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  useEffect(() => {
    getJobOpeningDetail();
  }, []);

  const valueList = [
    {
      title: "Creativity and Problem Solving",
      body: "At Belle&Kate, we embrace innovative thinking and original ideas. We believe that creativity is the driving force behind our brand, empowering us to deliver unique solutions that truly resonate with our audience.",
      icon: <SlBulb size={20} color={colors.grey33} />,
    },
    {
      title: "Collaboration",
      body: "We thrive on teamwork and open communication. By creating a collaborative environment, we build strong relationships that enhance creativity and drive results, allowing us to achieve our goals together. ",
      icon: <SlPeople size={20} color={colors.grey33} />,
    },
    {
      title: "Growth Mindset",
      body: "We are dedicated to continuous learning and personal development. Our top talent views challenges as opportunities for growth and inspires others to adopt the same mindset.",
      icon: <SlRocket size={20} color={colors.grey33} />,
    },
    {
      title: "Integrity",
      body: "Honesty and transparency are at the heart of our culture. We expect our team members to act with integrity in all their interactions, upholding ethical standards and accountability ensures that we maintain trust with our clients and colleagues.",
      icon: <SlStar size={20} color={colors.grey33} />,
    },
  ]

  return (
    <View>
      <ScrollView
        ref={scrollRef}
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: isPhone ? 16 : 100,
            marginHorizontal: isPhone ? 16 : 100,
          }}
        >
          {
            jobOpeningDetail === undefined
            &&
            <LatoRegular
              style={{
                fontSize: isPhone ? 24 : isTablet ? 48 : 60,
                lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
                color: colors.black00,
                marginBottom: 16,
              }}
            >
              Loading
            </LatoRegular>
          }
          {
            jobOpeningDetail === null
            &&
            <LatoRegular
              style={{
                fontSize: isPhone ? 24 : isTablet ? 48 : 60,
                lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
                color: colors.black00,
                marginBottom: 16,
              }}
            >
              Job not found
            </LatoRegular>
          }
          {
            !!jobOpeningDetail
            &&
            <>
              <LatoRegular
                style={{
                  fontSize: isPhone ? 24 : isTablet ? 48 : 60,
                  lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
                  color: colors.black00,
                  marginBottom: 16,
                }}
              >
                {jobOpeningDetail.title}
              </LatoRegular>
              <View style={{
                textAlign: "left",
                minWidth: containerWidth,
                paddingHorizontal: isPhone ? 0 : isTablet ? 50 : 200,
              }}>
                <Markdown className="markdown_text_custom_style">
                  {jobOpeningDetail.description}
                </Markdown>
              </View>
            </>
          }
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default CareersDetailScreen;

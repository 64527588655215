import React from "react";
import { View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import { useNavigate } from "react-router";
import { times, upperCase } from "lodash";
import ImageWithLoader from "../ImageWithLoader";

function Classroom({
  woHeader = false,
  isEditorial = false,
  data = [],
  loading = false,
}) {
  const navigate = useNavigate();

  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - 2 * gap;

  return (
    <View>
      {Boolean(!woHeader) && (
        <View
          style={{
            paddingTop: isEditorial ? 0 : 100,
            paddingBottom: 50,
            justifyContent: "center",
            alignItems: "center",
            marginHorizontal: isEditorial ? -gap : 0,
            paddingHorizontal: gap,
          }}
        >
          {Boolean(!isEditorial) && (
            <LatoRegular
              style={{
                fontSize: isPhone ? 16 : 24,
                lineHeight: isPhone ? 24 : 32,
                color: colors.grey6C,
                marginBottom: 12,
                backgroundColor: colors.whiteFF,
                paddingHorizontal: isPhone ? 8 : 56,
              }}
            >
              BELLE&KATE CLASS ROOM
            </LatoRegular>
          )}
          <View
            style={{
              position: "absolute",
              zIndex: -1,
              top: isEditorial ? 0 : isPhone ? 112 : 116,
              width: isEditorial ? width : containerWidth,
              height: 1,
              backgroundColor: colors.greyE6,
              left: gap,
            }}
          />
        </View>
      )}
      <View
        style={{
          flexDirection: isPhone && !isEditorial ? "column" : "row",
          paddingHorizontal: gap,
          width: isEditorial ? width : "auto",
          flexWrap: isEditorial ? "wrap" : "nowrap",
        }}
      >
        {loading
          ? times(isEditorial ? 3 : 2, (i) => (
              <View
                key={i}
                style={{
                  width: isPhone
                    ? isEditorial
                      ? (containerWidth - 32) / 2
                      : containerWidth
                    : (containerWidth - (isEditorial ? 100 : 50)) /
                      (isEditorial ? 3 : 2),
                  height:
                    ((isPhone
                      ? isEditorial
                        ? (((containerWidth - 32) / 2) * 200) / 171
                        : (containerWidth * 281) / 358
                      : (containerWidth - (isEditorial ? 100 : 50)) /
                        (isEditorial ? 3 : 2)) *
                      (isEditorial ? 540 : 450)) /
                    (isEditorial ? 454 : 646),
                  backgroundColor: colors.superTransparentBlack00,
                  marginRight:
                    ((i + 1) % (isEditorial ? 3 : 2) === 0 && !isPhone) ||
                    isPhone
                      ? 0
                      : 50,
                }}
              />
            ))
          : data.map((item, idx) => {
              const { main_image: cover, category, header: title, id } = item;
              return (idx < 2 && !isEditorial) || isEditorial ? (
                <View
                  key={idx}
                  style={{
                    alignItems: "center",
                    marginBottom:
                      (isPhone && idx === 0) || isEditorial ? 40 : 0,
                    paddingBottom: isPhone && idx === 0 ? 48 : 0,
                    borderBottomColor: colors.greyE6,
                    borderBottomWidth: isPhone && idx === 0 ? 1 : 0,
                    marginRight:
                      ((idx + 1) % (isEditorial ? 3 : 2) === 0 && !isPhone) ||
                      isPhone
                        ? 0
                        : 50,
                  }}
                >
                  <ImageWithLoader
                    source={
                      cover.startsWith("http")
                        ? { uri: cover }
                        : require(`../../assets/images/${cover}`)
                    }
                    containerStyle={{
                      width: isPhone
                        ? isEditorial
                          ? (containerWidth - 32) / 2
                          : containerWidth
                        : (containerWidth - (isEditorial ? 100 : 50)) /
                          (isEditorial ? 3 : 2),
                      height: isPhone
                        ? isEditorial
                          ? (containerWidth - 32) / 2
                          : containerWidth
                        : (containerWidth - (isEditorial ? 100 : 50)) /
                          (isEditorial ? 3 : 2),
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    defaultStyle={{
                      width: 48,
                      height: isPhone
                        ? isEditorial
                          ? (containerWidth - 32) / 2
                          : containerWidth
                        : (containerWidth - (isEditorial ? 100 : 50)) /
                          (isEditorial ? 3 : 2),
                    }}
                    resizeMode={"cover"}
                    woMask
                  />
                  <View
                    style={{
                      width: isPhone
                        ? isEditorial
                          ? (containerWidth - 16) / 2
                          : containerWidth
                        : (containerWidth - (isEditorial ? 100 : 50)) /
                          (isEditorial ? 3 : 2),
                      alignItems: "center",
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: isPhone ? 12 : 16,
                        lineHeight: isPhone ? 16 : 24,
                        color: colors.grey33,
                        marginTop: isPhone ? 16 : 24,
                        marginBottom: 8,
                      }}
                    >
                      {/* {category && category !== "belle&kate_class"
                        ? upperCase(category)
                        : category !== "belle&kate_class"
                        ? "BELLE&KATE ADORES"
                        : "BELLE&KATE CLASS"} */}
                        { category === "belle&kate_class" ? "BELLE&KATE CLASS" :
                        category === "belle&kate_adores" ? "BELLE&KATE ADORES" :
                        category ? category.toUpperCase().replace("_", " ") : "BELLE&KATE ARTICLES" }
                        
                    </LatoRegular>
                    <LatoRegular
                      style={{
                        fontSize: woHeader && isPhone ? 14 : 20,
                        lineHeight: woHeader && isPhone ? 20 : 28,
                        color: colors.black22,
                        marginBottom: isPhone && !woHeader ? 32 : 24,
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {title}
                    </LatoRegular>
                    <Buttons
                      onPress={() =>
                        navigate(
                          `/editorial/${
                            category
                              ? category === "belle&kate_class"
                                ? "classroom"
                                : category
                              : "adores"
                          }/${id}`,
                          {
                            state: {
                              item,
                            },
                          }
                        )
                      }
                      label={"READ ARTICLE"}
                      textStyle={{ fontFamily: "lato-regular" }}
                      color={colors.black00}
                      fontSize={isPhone ? 12 : 16}
                      lineHeight={isPhone ? 16 : 24}
                      noBorder
                    />
                  </View>
                </View>
              ) : null;
            })}
      </View>
    </View>
  );
}

export default Classroom;

import React from "react";
import { BsInstagram, BsTiktok } from "react-icons/bs";
import {
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";
import { LatoRegular } from "./Text";

export default function SocialMedia({
  color = colors.whiteFF,
  isRow = false,
  fontSize = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });

  const iconSize = fontSize ? fontSize + 4 : isPhone ? 18 : 20
  const socialMediaArr = [
    {
      icon: <BsInstagram color={color} size={iconSize} />,
      title: "belleandkate.room",
      link: "https://www.instagram.com/belleandkate.room",
    },
    {
      icon: <BsInstagram color={color} size={iconSize} />,
      title: "belleandkate.h",
      link: "https://www.instagram.com/belleandkate.h",
    },
    {
      icon: <BsInstagram color={color} size={iconSize} />,
      title: "belleandkate.watch",
      link: "https://www.instagram.com/belleandkate.watch",
    },
    {
      icon: <BsInstagram color={color} size={iconSize} />,
      title: "belleandkate.curated",
      link: "https://www.instagram.com/belleandkate.curated",
    },
    // {
    //   icon: <BsInstagram color={color} size={iconSize} />,
    //   title: "bibi.accessories.sale",
    //   link: "https://www.instagram.com/bibi.accessories.sale",
    // },
    // {
    //   icon: <BsInstagram color={color} size={iconSize} />,
    //   title: "bibi.multibrand",
    //   link: "https://www.instagram.com/bibi.multibrand",
    // },
    {
      icon: <BsInstagram color={color} size={iconSize} />,
      title: "belleandkate.sg",
      link: "https://www.instagram.com/belleandkate.sg",
    },
    {
      icon: <BsInstagram color={color} size={iconSize} />,
      title: "belleandkate.vintage",
      link: "https://www.instagram.com/belleandkate.vintage",
    },
    {
      icon: <BsTiktok color={color} size={iconSize} />,
      title: "belleandkate.room",
      link: "https://www.tiktok.com/@belleandkate.room",
    },
  ];

  return socialMediaArr.map(({ icon, title, link }, index) => {
    return (
      <TouchableOpacity
        style={{
          ...style.socialMediaButton,
          marginRight: isRow ? (isPhone ? 0 : 16) : 0,
        }}
        key={index}
      >
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecorationLine: "none",
          }}
        >
          {icon}
        </a>
        {Boolean(!isRow) && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecorationLine: "none",
              fontSize: fontSize || isPhone ? 14 : 16,
            }}
          >
            <LatoRegular
              style={{
                ...style.socialMediaText,
                color: color,
                fontSize: fontSize || isPhone ? 14 : 16,
              }}
            >
              {title}
            </LatoRegular>
          </a>
        )}
      </TouchableOpacity>
    );
  });
}

const style = StyleSheet.create({
  upperItemTitle: { color: colors.whiteFF, marginBottom: 40, fontSize: 22 },
  upperRightItem: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "20%",
    marginLeft: 48,
    paddingTop: 28,
    paddingBottom: 6,
  },
  socialMediaButton: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 16,
  },
  socialMediaText: {
    marginLeft: 6,
    fontSize: 14,
  },
});

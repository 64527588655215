import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const useGA4 = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA4 with your Measurement ID
    ReactGA.initialize('G-6431JJYFCP'); // Replace 'G-XXXXXXXXXX' with your actual Measurement ID

    // Track page view on route change
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
};

export default useGA4;
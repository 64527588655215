import React, { useEffect, useState } from "react";
import { useWindowDimensions, Animated, Easing } from "react-native";
import { getDeviceType } from "../tools/Interface";
import Cart from "./Cart";
import WishList from "./WishList";
import { useAuth } from "../contexts/AuthContext";
import { api } from "../utils/api";
import MainComponent from "./Navbar/MainComponent";
import Search from "./Navbar/Search";

export default function Navbar({
  customOpenCart = false,
  setCustomOpenCart = () => {},
  getData = () => {},
  isCheckout = false,
  showHelp = false,
  setShowHelp = () => {},
  promotion,
}) {
  const [openBurger, setOpenBurger] = useState(false);
  const [items, setItems] = useState([]);
  const [openSubBurger, setOpenSubBurger] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishList, setOpenWishList] = useState(false);
  const [opacity, setOpacity] = useState(new Animated.Value(0));
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [hasQuery, setHasQuery] = useState(false);
  const [totalResult, setTotalResult] = useState(0);

  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });
  const { user } = useAuth();

  const getItems = async () => {
    try {
      const res = await api.get("/api/carts");
      const data = res?.data?.data;

      setItems(data?.products);
    } catch (err) {
      console.log("err:", err);
    } finally {
    }
  };

  useEffect(() => {
    if (customOpenCart) {
      getItems();
    }

    if (customOpenCart || openSearch || openSubBurger || openBurger) {
      animate(Easing.ease);
    }
  }, [customOpenCart, openSubBurger, openSearch, openBurger]);

  const handleSearch = async (val) => {
    try {
      setLoading(true);
      if (val.length) {
        setHasQuery(val);
        const url = `/api/inventories/v2?q[product_product_styles_style_name_or_product_name_or_product_sku_or_product_internal_search_keyword_or_product_category_name_or_product_designer_name_or_product_product_badges_badge_name_cont]=${val}&page=1&per_page=5&q[product_sku_not_null]=1`;
        const res = await api.get(url);
        const data = res?.data?.data || [];

        setSearchResult(data);
        setTotalResult(res?.data?.total);
      } else {
        setHasQuery(false);
        setSearchResult([]);
      }
    } catch (err) {
      // showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const animate = (easing, close) => {
    opacity.setValue(close ? 1 : 0);
    Animated.timing(opacity, {
      toValue: close ? 0 : 1,
      duration: isPhone ? 200 : 500,
      easing,
      useNativeDriver: true,
    }).start();
  };

  const animatedWidth = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, isPhone ? width : 450],
  });

  return (
    <>
      <MainComponent
        animate={animate}
        openBurger={openBurger}
        setOpenBurger={setOpenBurger}
        customOpenCart={customOpenCart}
        openCart={openCart}
        openWishList={openWishList}
        setOpenCart={setOpenCart}
        setOpenWishList={setOpenWishList}
        user={user}
        openSubBurger={openSubBurger}
        setOpenSubBurger={setOpenSubBurger}
        items={items}
        isCheckout={isCheckout}
        setOpenSearch={setOpenSearch}
        opacity={opacity}
        openSearch={openSearch}
        searchResult={searchResult}
        setHasQuery={setHasQuery}
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        promotion={promotion}
      />
      {Boolean(openSearch) && (
        <Search
          handleSearch={handleSearch}
          hasQuery={hasQuery}
          loading={loading}
          opacity={opacity}
          openBurger={openBurger}
          searchResult={searchResult}
          setHasQuery={setHasQuery}
          setOpenSearch={setOpenSearch}
          setSearchResult={setSearchResult}
          totalResult={totalResult}
        />
      )}
      {Boolean(openCart || customOpenCart) && (
        <Cart
          setOpenCart={setOpenCart}
          setCustomOpenCart={setCustomOpenCart}
          getItem={getItems}
          getData={getData}
          animatedWidth={animatedWidth}
          opacity={opacity}
        />
      )}
      {Boolean(openWishList) && (
        <WishList
          setOpenCart={setOpenWishList}
          getData={getData}
          animatedWidth={animatedWidth}
          opacity={opacity}
        />
      )}
    </>
  );
}

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  View,
  useWindowDimensions,
} from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import colors from "../../assets/colors/colors";
import { api } from "../../utils/api";
import ToasterContext from "../../contexts/ToastContext";
import { LatoBold, LatoRegular } from "../Text";
import { removePrependedDesignerName } from "../../utils/productutil";
import CurrencyContext from "../../contexts/CurrenctContext";
import { formatCurrency } from "../../tools/formatter";
import { Image } from "react-native-web";
import { Link } from "react-router-dom";
import Buttons from "../Buttons";

//see keys of options for list of allowed selectedCategory
export default function CategorySection({selectedCategory}) {
  const navigate = useNavigate();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const [productArr, setProductArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useContext(ToasterContext);
  const { selected } = useContext(CurrencyContext);
  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 220;
  const containerWidth = width - gap * 2;

  const maxItems = 10
  const options = {
    newArrival: {
      title: "NEW ARRIVALS",
      description: "Discover our latest collection of luxury goods, expertly curated daily",
      api: `sorting=newest&q[active_eq]=true&page=1&per_page=${maxItems}`,
      viewAllAction: () => navigate("/products")
    },
    sale: {
      title: "SALE",
      description: "Browse our exclusive deals on your favourite styles",
      api: `sorting=newest&q[active_eq]=true&page=1&per_page=${maxItems}&q[product_discounted_eq]=1`,
      viewAllAction: () => navigate("/products", { state: { discounted: true } })
    },
    vintage: {
      title: "VINTAGE",
      description: "Explore our sought-after timeless collections from the past. ",
      api: `q[product_product_badges_badge_name_cont]=Vintage&sorting=newest&q[active_eq]=true&page=1&per_page=${maxItems}`,
      viewAllAction: () => navigate("/products/category/Vintage")
    },
  }
  const getData = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/api/inventories/v2?${options[selectedCategory].api}`);
      const data = res?.data?.data;

      setProductArr(data);
    } catch (err) {
      console.log("err:", err.response);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProductArr([]);
    getData();
  }, []);

  return (
    <>
      <View style={{
        width: containerWidth,
        paddingTop: isPhone ? 48 : 100,
        marginHorizontal: 'auto',
        textAlign: 'center',
      }}>
        <View
          style={{
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: gap,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              color: colors.grey6C,
              backgroundColor: colors.whiteFF,
              paddingHorizontal: isPhone ? 8 : 56,
            }}
          >
            {options[selectedCategory].title}
          </LatoRegular>
          <View style={{
            position: "absolute",
            zIndex: -1,
            width: containerWidth,
            height: 1,
            backgroundColor: colors.greyE6,
            // left: gap,
          }}/>
        </View>
        <LatoRegular
          style={{
            fontSize: 18,
            lineHeight: 16,
            color: colors.grey6C,
            marginVertical: 30,
          }}
          numberOfLines={2}
        >
          {options[selectedCategory].description}
        </LatoRegular>
      </View>
      <View style={{
        width: containerWidth,
        overflowX: "scroll",
        marginHorizontal: 'auto',
        marginBottom: 30,
      }}>
        <View style={{
          flex: 1,
          justifyContent: 'flex-start',
          flexDirection: isPhone ? 'column' : 'row',
        }}>
          <View style={{
            flex: 2,
            flexDirection: 'row',
            gap: 20,
          }}>
            {productArr.map((item, index) => {
              const defaultPrice = parseFloat(item.product.default_price).toFixed(0) ?? 0
              const sellingPrice = parseFloat(item.product.selling_price).toFixed(0) ?? 0
              return (
                <Link key={item.id} to={`/products/${item.id}`}>
                  <View
                    style={{
                      width: 200,
                      padding: 12,
                    }}
                  >
                    <View style={{
                      flex: 1,
                      flexDirection: 'column',
                    }}>
                      <View>
                        {Boolean(item.product.summary_index.direct_photos.filter((item) => item)[0]) && (
                          <Image
                            source={{
                              uri: `${item.product.summary_index.direct_photos.filter((item) => item)[0]}`,
                            }}
                            alt={`Product ${index}`}
                            style={{
                              height: 289,
                              width: 174,
                            }}
                            defaultSource={require("../../assets/images/spinning-loading.gif")}
                            resizeMode={"contain"}
                          />
                        )}
                        <View
                          style={{
                            position: "absolute",
                            backgroundColor: colors.superTransparentBlack00,
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}
                        />
                      </View>
                      <LatoRegular
                        style={{
                          fontSize: isPhone ? 10 : 14,
                          lineHeight: isPhone ? 12 : 20,
                          color: colors.grey72,
                          marginBottom: 4,
                        }}
                      >
                        {item.product.summary_index.condition}
                      </LatoRegular>
                      <LatoBold style={{marginBottom: 6}}>{item.product.summary_index.designer}</LatoBold>
                      <LatoRegular numberOfLines={2}>
                        {removePrependedDesignerName(item.product.name, item.product.summary_index.designer)}
                      </LatoRegular>
                      <LatoBold
                        style={{ color: colors.redAD, marginVertical: 6 }}
                      >
                        {
                          defaultPrice !== sellingPrice
                          ? `Save ${(
                              ((defaultPrice - sellingPrice) / defaultPrice) *
                              100
                            ).toFixed(0)}%`
                          : ""
                        }
                      </LatoBold>
                      <View
                        style={{
                          flexDirection: "column",
                          flexWrap: "wrap",
                          gap: 3,
                        }}
                      >
                        <LatoBold
                          style={{
                            color:
                              sellingPrice !== defaultPrice
                                ? colors.grey6C
                                : colors.grey33,
                            textDecorationLine:
                              sellingPrice !== defaultPrice
                                ? "line-through"
                                : "none",
                          }}
                        >
                          {`${selected} ${formatCurrency(defaultPrice)}`}
                        </LatoBold>
                        {Boolean(
                          sellingPrice !== defaultPrice
                        ) && (
                          <LatoBold>
                            {`${selected} ${formatCurrency(sellingPrice)}  `}
                          </LatoBold>
                        )}
                      </View>
                    </View>
                  </View>
                </Link>
              )
            })}
          </View>
        </View>
      </View>
      <Buttons
        onPress={options[selectedCategory].viewAllAction}
        label="View All"
        isBlack
        containerStyle={{
          borderRadius: 2,
          alignSelf: "center",
        }}
        height={48}
        width={isPhone ? width - 32 : 102}
      />
    </>
  );
}

import React from "react";
import {
  ScrollView,
  SectionList,
  View,
  useWindowDimensions,
} from "react-native";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import FAQ from "../assets/images/faq.png";
import FAQPhone from "../assets/images/faq-phone.png";
import { LatoBold, LatoLight } from "../components/Text";
import DropDownTab from "../components/DropDownTab";
import { getDeviceType } from "../tools/Interface";
import { Wrapper, WrapperInsider } from "../components/Wrapper";
import Footer from "../components/Footer";

export default function FAQScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const faqArr = [
    {
      key: "ORDER",
      data: [
        {
          title: "How long does delivery take, and how much will it cost?",
          datas: [
            "For information on delivery and shipping charges to your destination, view our Shipping options.",
          ],
          style: "paragraph",
        },
        {
          title: "Do I need an account to place an order?",
          datas: [
            "Yes. Having an account can save you time during checkout as it securely saves your address and payment details for future purchases.",
          ],
          style: "paragraph",
        },
        {
          title: "Can I cancel my orders?",
          datas: [
            "All orders are final, we do not accept returns or refunds except if we deliver you the wrong item. Kindly review your orders before check-out to prevent mistakes.",
          ],
          style: "paragraph",
        },
        {
          title: "How can I track my order?",
          datas: [
            "Once your order has been dispatched, you will receive an email containing your air waybill number to track your package. If you have registered, you can also follow the progress of your delivery by signing into your account and selecting My Account followed by Order Status.",
          ],
          style: "paragraph",
        },
      ],
    },
    {
      key: "ACCOUNT",
      data: [
        {
          title: "Is my personal information kept private?",
          datas: [
            "Please be assured that your personal information is kept private and confidential, and at no point will we share it with a third party.",
          ],
          style: "paragraph",
        },
        {
          title: "Can I be sure that my bag is authentic?",
          datas: [
            "100%. Our bags are expertly curated by our in house authenticator and we use third party authenticator to validate the authenticity.",
          ],
          style: "paragraph",
        },
        {
          title: "What forms of payment do you accept?",
          datas: ["We accept all payment method using Midtrans."],
          style: "paragraph",
        },
      ],
    },
    {
      key: "OTHERS",
      data: [
        {
          title: "Do you ship worldwide?",
          datas: [
            "Yes, we use FedEx International Priority as our worldwide shipping expedition.",
          ],
          style: "paragraph",
        },
        {
          title: "Do you accept consignments?",
          datas: [
            "Yes, we do! Drop your bag(s) to our office and we need around 2 weeks (Hermes) and 2 months (Chanel, Louis Vuitton & Dior) to keep in order to authenticate and process your bag. Simply contact our WhatsApp number for further assistant.",
          ],
          style: "paragraph",
        },
        {
          title:
            "You do not have the item I’m looking for, can you help me find it?",
          datas: [
            "Yes! We still have many items outside our website or instagram page that are on their way here! You can contact our WhatsApp number and we can inform you if we have them coming soon or you can put your name on our waiting list!",
          ],
          style: "paragraph",
        },
        {
          title: "Item conditions you need to know!",
          datas: [
            "BRAND NEW: Bought directly from the store, but never been used. This brand new item comes with all original accompaniments.",
            "ALMOST NEW: This item has only been worn a few times, usually less than 3 times. The condition is as good as the Brand new items and comes with all original accompaniments.",
            "EXCELLENT: Pre-loved item but is still in a good condition. There will be minor signs of wear to the leather and/or hardware but the wear does not distract from the overall look of the item.",
            "GOOD: This item has been used often and you can see signs of wear to the leather and/or hardware although still very much acceptable",
          ],
          style: "paragraph",
        },
      ],
    },
  ];

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        {/* <Wrapper> */}
        <Navbar backgroundColor={colors.whiteFF} />
        {Boolean(isPhone) && (
          <img src={FAQPhone} width={width} height={"auto"} alt={"FAQ"} />
        )}
        <View
          style={{
            width: isPhone ? width : isTablet ? width - 80 : width - 160,
            marginHorizontal: isPhone ? 0 : isTablet ? 40 : 80,
            paddingHorizontal: isPhone ? 16 : 0,
            marginTop: 40,
            paddingBottom: 24,
            marginBottom: 24,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <LatoLight style={{ fontSize: 32, lineHeight: 40 }}>FAQ</LatoLight>
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: 50,
          }}
        >
          {Boolean(!isPhone) && (
            <View style={{ width: "50vw", alignItems: "center" }}>
              <img
                src={FAQ}
                width={isTablet ? width / 2 - 80 : width / 2 - 160}
                height={"auto"}
                alt={"FAQ"}
              />
            </View>
          )}
          <View
            style={{
              width: isPhone ? "100vw" : "50vw",
              paddingRight: isPhone ? 0 : isTablet ? 40 : 80,
            }}
          >
            {/* <WrapperInsider> */}
            <SectionList
              sections={faqArr}
              keyExtractor={(_, index) => {
                return _ + index;
              }}
              renderItem={({ item }) => {
                return <DropDownTab item={item} noBorder />;
              }}
              renderSectionHeader={({ section }) => {
                return section ? (
                  <LatoBold
                    style={{
                      fontSize: isTablet ? 16 : 18,
                      lineHeight: isTablet ? 24 : 26,
                      paddingBottom: 12,
                      paddingHorizontal: isPhone ? 16 : 0,
                      borderBottomWidth: 1,
                      borderBottomColor: colors.greyE6,
                    }}
                  >
                    {section.key}
                  </LatoBold>
                ) : null;
              }}
              SectionSeparatorComponent={() => {
                return (
                  <View
                    style={{
                      marginTop: 24,
                    }}
                  />
                );
              }}
              ItemSeparatorComponent={() => (
                <View style={{ marginBottom: 12 }} />
              )}
            />
            {/* </WrapperInsider> */}
          </View>
        </View>
        <Footer />
        {/* </Wrapper> */}
      </ScrollView>
    </View>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Image } from "react-native-web";
import LiveChatIcon from "./assets/images/live-chat-icon.png";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <div id="live_chat_floating_action_button" className="hide_live_chat print:hidden">
      <Image source={LiveChatIcon} alt="Live Chat" style={{width: '100%', height: '100%'}}/>
    </div>
    <App />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

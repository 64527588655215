import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Animated,
  Easing,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { AiOutlineArrowDown } from "react-icons/ai";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import colors from "../assets/colors/colors";
import { splitIntoRows } from "../components/Home/ProductGrid";
import Navbar from "../components/Navbar";
import { LatoRegular } from "../components/Text";
import { getDeviceType } from "../tools/Interface";
import FilterProduct from "../components/Catalog/FilterProduct";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../utils/api";
import { filterQueriesDecider } from "../tools/decider";
import Footer from "../components/Footer";
import ToasterContext from "../contexts/ToastContext";
import CatalogHeader from "../components/Catalog/CatalogHeader";
import InfiniteScroll from 'react-infinite-scroll-component';
import GridWrapper from "../components/Catalog/GridWrapper";
import GridItem from "../components/Catalog/GridItem";

export default function ProductCatalogScreen() {
  const { pathname, state, search } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { showToast } = useContext(ToasterContext);
  const scrollRef = useRef();
  const { discounted = false } = state || {};
  const queryParams = new URLSearchParams(search);
  const query = function(){
    const q = queryParams.get('query')
    if (q === "null") { return "" }
    return q
  }();
  const pathArr = pathname.split("/")
  const sectionIsDesigner = pathArr[2]?.startsWith("designer") ?? false
  const sectionIsCategory = pathArr[2]?.startsWith("category") ?? false
  const section = pathArr[2]?.split("?")[0]
  const [hasMoreProduct, setHasMoreProduct] = useState(true);

  const style = StyleSheet.create({
    container: {},
    row: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    merk: {
      fontSize: isPhone ? 12 : 14,
      lineHeight: isPhone ? 16 : 20,
      marginBottom: 4,
      color: colors.black22,
    },
    title: {
      fontSize: isPhone ? 12 : 14,
      lineHeight: isPhone ? 16 : 20,
      marginBottom: 10,
      numberOfLines: 2,
      color: colors.black22,
      height: isPhone ? 32 : 40,
    },
    price: {
      fontSize: isPhone ? 12 : 14,
      lineHeight: isPhone ? 16 : 20,
      color: colors.black22,
      textDecorationLine: "none",
      height: isPhone ? 16 : 20,
    },
  });
  const divider = isPhone ? 2 : isTablet ? 3 : 4;

  const filter = state?.menu
    ? typeof state?.menu === "string"
      ? [state?.menu]
      : state?.menu
    : [];

  const [shownSpecialFilter, setShownSpecialFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState((!id && filter) || []);
  const [shownFilter, setShownFilter] = useState(false);
  const [specialFilter, setSpecialFilter] = useState({
    title: "Newest",
    value: "newest",
  });
  const [productArr, setProductArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opacity, setOpacity] = useState(new Animated.Value(0));
  const [showHelp, setShowHelp] = useState(false);

  const designerArr = ["Hermes", "Chanel", "Dior", "Louis Vuitton"];
  const categoryArr = ["Bags", "Shoes", "Accessories", "Watch"];

  function isNumeric(value) {
    return !isNaN(Number(value));
  }

  const handleGetNext = async () => {
    try {
      setLoading(true);

      const menuItems = sectionIsDesigner ? [
        ...(state?.menu || [
       {
        id: isNumeric(id) ? parseInt(id) : designerArr.indexOf(id) + 1,
        name: id,
        section: "Designer",
       },
      ]),
      ...selected,
     ] : (sectionIsCategory ? [
              ...(state?.menu || [
                {
                  id: categoryArr.includes(id) ? categoryArr.indexOf(id) + 1 : id,
                  name: id,
                  section: "Category",
                },
              ]),
              ...selected,
            ] : []);

      const additionalQueries = filterQueriesDecider(
        id ? [...menuItems, ...selected] : selected,
        specialFilter?.value
      );

      const perPage = 20
      const res = await api.get(
        additionalQueries
          ? `/api/inventories/v2?${additionalQueries}&page=${
              page
            }&per_page=${perPage}${
              query ? "" : "&q[active_eq]=true"
            }&q[product_product_styles_style_name_or_product_name_or_product_sku_or_product_internal_search_keyword_or_product_category_name_or_product_designer_name_or_product_product_badges_badge_name_cont]=${
              query || ""
            }${discounted ? "&q[product_discounted_eq]=1" : ""}`
          : `/api/inventories/v2?page=${page}&per_page=${perPage}${
              query ? "" : "&q[active_eq]=true"
            }&q[product_product_styles_style_name_or_product_name_or_product_sku_or_product_internal_search_keyword_or_product_category_name_or_product_designer_name_or_product_product_badges_badge_name_cont]=${
              query || ""
            }${discounted ? "&q[product_discounted_eq]=1" : ""}`
      );
      const data = res?.data?.data;

      setTotal(res?.data?.total);
      setHasMoreProduct(data.length >= perPage)
      if (page === 1) {
        setProductArr(data);
        setShowHelp(false);
      } else {
        setProductArr([...productArr, ...data]);
      }
      setPage(page + 1);
    } catch (err) {
      console.log("err:", err.response);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    setProductArr([]);
    scrollRef?.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [specialFilter, selected, id, state, query]);
  useEffect(() => {
    if(page === 1){
      handleGetNext()
    }
  }, [page]);

  const specialFilterArr = [
    {
      title: "Recommended",
      value: "recommended",
    },
    {
      title: "Price low to high",
      value: "price-low-high",
    },
    {
      title: "Price high to low",
      value: "price-high-low",
    },
    {
      title: "Newest",
      value: "newest",
    },
  ];

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const animate = (easing, close) => {
    opacity.setValue(close ? 1 : 0);
    Animated.timing(opacity, {
      toValue: close ? 0 : 1,
      duration: isPhone ? 200 : 500,
      easing,
      useNativeDriver: true,
    }).start();
  };

  const animatedWidth = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, isPhone ? width : 450],
  });

  return (
    <>
      <View>
        <ScrollView
          scrollEventThrottle={16}
          onScroll={(e) => {
            const layout = e.nativeEvent.contentSize.height;
            const yPosition = e.nativeEvent.contentOffset.y;

            if (
              total > productArr.length &&
              !loading &&
              yPosition > layout - 2000
            ) {
              setLoading(true);
              handleGetNext();
            }

            if (page % 3 === 0 && yPosition > layout - 2000) {
              setShowHelp(true);
            }
          }}
          ref={scrollRef}
          style={{ height: height }}
        >
          <Navbar
            backgroundColor={colors.whiteFF}
            showHelp={showHelp}
            setShowHelp={setShowHelp}
          />
          <CatalogHeader
            total={total}
            setShownFilter={setShownFilter}
            setShownSpecialFilter={setShownSpecialFilter}
            shownFilter={shownFilter}
            shownSpecialFilter={shownSpecialFilter}
            specialFilter={specialFilter}
            animate={animate}
            selected={selected}
          />
          <View style={{
            position: "relative",
          }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingVertical: 12,
                paddingHorizontal: gap,
                width,
              }}
            >
              {Boolean(!isPhone) && <LatoRegular>{`${total} PRODUCTS`}</LatoRegular>}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: isPhone ? "flex-start" : "center",
                  justifyContent: isPhone ? "space-between" : "flex-end",
                  width: isPhone ? containerWidth : "auto",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setShownFilter(!shownFilter);
                    animate(Easing.ease);
                  }}
                  style={{
                    paddingVertical: selected.length ? 5 : 8.5,
                    paddingHorizontal: 20,
                    borderWidth: 1,
                    borderColor: colors.grey90,
                    borderRadius: 4,
                    marginRight: 16,
                  }}
                >
                  <View
                    style={{
                      flexDirection:
                        isPhone || selected.length ? "row" : "row-reverse",
                      alignItems: "center",
                    }}
                  >
                    {Boolean(!selected.length) ? (
                      <HiOutlineAdjustmentsHorizontal size={16} />
                    ) : (
                      Boolean(isPhone && selected.length) && (
                        <View
                          style={{
                            borderRadius: 1000,
                            padding: 4,
                            width: 24,
                            height: 24,
                            backgroundColor: colors.grey33,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <LatoRegular
                            style={{
                              fontSize: 12,
                              lineHeight: 16,
                              color: colors.whiteFF,
                            }}
                          >
                            {selected.length}
                          </LatoRegular>
                        </View>
                      )
                    )}
                    <LatoRegular
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        marginLeft: !isPhone ? 0 : 8,
                        marginRight: !isPhone && !selected.length ? 8 : 0,
                      }}
                    >
                      {!isPhone && selected.length ? "FILTER APPLIED" : "FILTER"}
                    </LatoRegular>
                    {Boolean(!isPhone && selected.length) && (
                      <View
                        style={{
                          borderRadius: 1000,
                          padding: 4,
                          width: 24,
                          height: 24,
                          backgroundColor: colors.grey33,
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: 8,
                        }}
                      >
                        <LatoRegular
                          style={{
                            fontSize: 12,
                            lineHeight: 16,
                            color: colors.whiteFF,
                          }}
                        >
                          {selected.length}
                        </LatoRegular>
                      </View>
                    )}
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => setShownSpecialFilter(!shownSpecialFilter)}
                  style={{
                    paddingVertical: 8.5,
                    paddingHorizontal: 20,
                    borderWidth: 1,
                    borderColor: colors.grey90,
                    borderRadius: 4,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LatoRegular
                      style={{
                        marginRight: 6,
                        fontSize: 12,
                        lineHeight: 16,
                        textTransform: "uppercase",
                      }}
                    >
                      {specialFilter.title}
                    </LatoRegular>
                    <AiOutlineArrowDown width={14} height={14} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            {Boolean(shownSpecialFilter) && (
              <View
                style={{
                  position: "absolute",
                  right: gap,
                  top: 53,
                  // height: 100,
                  width: 200,
                  borderWidth: 1,
                  backgroundColor: colors.whiteFF,
                  zIndex: 5,
                  paddingHorizontal: 8,
                  paddingVertical: 6,
                  borderRadius: 8,
                  borderColor: colors.grey90,
                }}
              >
                <LatoRegular
                  style={{
                    paddingVertical: 12,
                    marginBottom: 6,
                    borderBottomWidth: 1,
                    borderBottomColor: colors.greyE6,
                  }}
                >
                  SORT BY
                </LatoRegular>
                {specialFilterArr?.map((item, idx) => {
                  return (
                    <TouchableOpacity
                      key={idx}
                      onPress={() => {
                        setSpecialFilter(item);
                        navigate(section ? `/products/${section}/${id}?query=${query}` : `/products?query=${query}`, {
                          state: {
                            menu: state?.menu,
                          },
                        });
                        setShownSpecialFilter(false);
                      }}
                      style={{
                        paddingVertical: 12,
                        backgroundColor:
                          specialFilter.value === item?.value
                            ? colors.transparentGrey6C
                            : colors.whiteFF,
                        marginHorizontal: -8,
                        paddingHorizontal: 8,
                      }}
                    >
                      <LatoRegular
                        style={{
                          fontSize: 12,
                          lineHeight: 16,
                          textTransform: "uppercase",
                        }}
                      >
                        {item?.title}
                      </LatoRegular>
                    </TouchableOpacity>
                  );
                })}
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <GridWrapper
                containerWidth={containerWidth} //change this based on ProductGrid (?)
                gap={gap}
                loading={loading}
                catalog={true}
                divider={divider}
                style={style}
              >
                <InfiniteScroll
                  dataLength={productArr.length}
                  next={handleGetNext}
                  hasMore={hasMoreProduct}
                  loader={<></>}
                  endMessage={<></>}
                >
                  {splitIntoRows({ data: productArr, divider }).map((row = [], i) => {
                    return (
                      <View style={style.row} key={i}>
                        {row.map((item, index) => {
                          return (
                            <GridItem
                              key={index}
                              index={index}
                              divider={divider}
                              item={item}
                              style={style}
                            />
                          );
                        })}
                      </View>
                    );
                  })}
                </InfiniteScroll>
              </GridWrapper>
            </View>
          </View>
          {
            (!loading && productArr.length === 0)
            &&
            <View
              style={{
                textAlign: "center",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                textDecoration: "underline",
              }}
            >
              <Link to={"/request-product"}>Didn't find what you're looking for? Click here to request the product, and we'll source it for you.</Link>
            </View>
          }
          <Footer />
        </ScrollView>
      </View>
      {Boolean(shownFilter) && (
        <FilterProduct
          selected={selected}
          setSelected={setSelected}
          shownFilter={shownFilter}
          setShownFilter={setShownFilter}
          isDesignerPage={id}
          animatedWidth={animatedWidth}
          opacity={opacity}
        />
      )}
    </>
  );
}

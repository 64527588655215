import React, { useContext, useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  useWindowDimensions,
  Image,
  ActivityIndicator,
} from "react-native";
import colors from "../../assets/colors/colors";
import { stateMaskingDecider } from "../../tools/decider";
import { customDateFormatter, formatCurrency } from "../../tools/formatter";
import { api } from "../../utils/api";
import { LatoBold, LatoRegular } from "../Text";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { upperCase } from "lodash";
import { useAuth } from "../../contexts/AuthContext";
import { getDeviceType } from "../../tools/Interface";
import Buttons from "../Buttons";
import { useDropzone } from "react-dropzone";
import { useFilePicker } from "use-file-picker";
import {
  asyncRemoveIsMultipart,
  asyncSetIsMultipart,
} from "../../tools/asyncStorages";
import DragDropFile from "../DragDropFile";
import { useParams } from "react-router";
import ToasterContext from "../../contexts/ToastContext";
import { Formik } from "formik";
import { sentenceCase } from "change-case";
import { regexChecker } from "../../tools/regexChecker";
import { MyInput } from "../Inputs";
import { RiCheckLine } from "react-icons/ri";

export default function OrderDetail({
  isCheckout = false,
  setOpenRefund = () => {},
  openRefund = false,
}) {
  const [detailData, setDetailData] = useState({});
  const [loading, setLoading] = useState(false);
  const [checker, setChecker] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [receipt, setReceipt] = useState({});
  const [imageURI, setImageURI] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selected, setSelected] = useState(false);

  const { user } = useAuth();
  const { showToast } = useContext(ToasterContext);
  const { additional, id } = useParams();
  const { email } = user || {};
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const detailID = additional || id;

  const getOrderDetails = async () => {
    try {
      setLoading(true);
      const res = await api.get(`api/deals/${detailID}`);
      const data = res?.data?.data;

      const timeDivider = 60 * 60 * 1000 * 24;

      setChecker(
        data?.state === "completed" &&
          Math.floor(
            Math.abs(
              new Date().getTime() -
                new Date(
                  data?.state_timestamp_logs?.find(
                    ({ state }) => state === "completed"
                  )?.timestamp
                ).getTime()
            ) / timeDivider
          ) < 1
      );

      setDetailData(data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrderDetails();
  }, [openRefund]);

  const {
    reference_number,
    created_at,
    state,
    state_timestamp_logs = [],
    cart = {},
    discounted_subtotal,
    discounted_delivery_fee,
    final_payment,
    payment = {},
    shipment = {},
    payment_fee,
    insurance_fee,
    rejection_note,
  } = detailData;
  const { products = [] } = cart;

  const { courier_detail = {}, address_detail = {} } = shipment;
  const { recipient_name, complete_address, recipient_number } = address_detail;
  const { logistic = {}, rate = {} } = courier_detail;

  const feeArr =
    payment?.method_type === "credit_card"
      ? [
          {
            left: "Subtotal",
            right: [{ price: discounted_subtotal }],
          },
          {
            left: `${rate?.name} - ${upperCase(logistic?.name)}`,
            right: [{ price: discounted_delivery_fee }],
          },
          {
            left: "Insurance Fee",
            right: [{ price: insurance_fee }],
          },
          {
            left: "Administration Fee",
            right: [{ price: payment_fee }],
          },
        ]
      : rate?.name
      ? [
          {
            left: "Subtotal",
            right: [{ price: discounted_subtotal }],
          },
          {
            left: `${rate?.name} - ${upperCase(logistic?.name)}`,
            right: [{ price: discounted_delivery_fee }],
          },
          {
            left: "Insurance Fee",
            right: [{ price: insurance_fee }],
          },
        ]
      : [
          {
            left: "Subtotal",
            right: [{ price: discounted_subtotal }],
          },
        ];

  const detailTableArr = [
    {
      left: "PRODUCT",
      right: "TOTAL",
    },
    {
      left: products?.map(({ summary_index, name, sku }, index) => {
        return {
          name,
          designer: summary_index?.designer,
          index,
          sku,
        };
      }),
      right: products?.map(({ selling_price }, index) => {
        return {
          price: selling_price,
          index,
        };
      }),
    },
    ...feeArr,
    {
      left: "Payment method",
      right:
        payment?.method_type === "credit_card"
          ? "Credit card"
          : payment?.method_type === "virtual_account"
          ? "Virtual Account"
          : rate?.name
          ? "Direct bank transfer"
          : "Sold in store",
      additionalRight:
        (Boolean(
          payment?.method_type === "credit_card" ||
            payment?.method_type === "virtual_account"
        ) &&
          payment?.redirect_url) ||
        "",
    },
    {
      left: "Total",
      right: [{ price: final_payment }],
      isBold: true,
    },
  ];

  const manualBankArr = [
    {
      title: "BCA",
      // name: "PT Berkah Nusantara Kayana",
      name: "PT BERKAH NUSANTARA KAYANA",
      // account: "5375227727",
      account: "4232387878",

    },
  ];

  const numberedCautionsArr = [
    "Please Include your order number on your transfer notes",
    "After transferring, confirm the payment within 1 hours, otherwise your order will be automatically cancelled.",
    "Order will be shipped 1-2 days after payment received",
  ];

  const initialValues = {
    bank: "",
    bank_account_name: "",
    bank_account_number: "",
    bank_branch: "",
  };

  const paymentArr = [
    {
      name: "bank",
      placeholder: "Input bank name",
      label: "Bank Name",
      required: true,
      initialValue: "",
    },
    {
      name: "bank_account_name",
      placeholder: "Input bank account owner name",
      label: "Account Name",
      required: true,
      initialValue: "",
    },
    {
      name: "bank_account_number",
      placeholder: "Input account number",
      label: "Account Number",
      required: true,
      initialValue: "",
    },
  ];

  const { getRootProps, open } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  const [
    openFileSelector,
    { filesContent, loading: pickerLoading, plainFiles },
  ] = useFilePicker({
    accept: [".png", ".jpg", ".jpeg"],
    readAs: "DataURL",
    multiple: false,
  });

  const handleSelectedImage = () => {
    plainFiles.map((file) => {
      setReceipt({ content: URL.createObjectURL(file), ...file });

      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        setImageURI(file);
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    handleSelectedImage();
  }, [filesContent]);

  const handleUploadReceipt = async (value) => {
    try {
      setLoadingUpload(true);
      await asyncSetIsMultipart(true);

      const formData = new FormData();
      formData.append("transfer_receipt", imageURI);

      await api.put(`/api/deals/${detailID}`, {
        transfer_receipt: imageURI,
        ...value,
      });
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      await asyncRemoveIsMultipart();
      await getOrderDetails();
      setLoadingUpload(false);
    }
  };

  const handleCancel = async () => {
    try {
      await api.put(`api/deals/${detailID}/cancel`);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      await getOrderDetails();
    }
  };

  const handleRefund = () => {
    setOpenRefund(true);
  };

  useEffect(() => {}, [detailData]);

  return loading ? (
    <View
      style={{
        height: height - 500,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        source={require("../../assets/images/spinning-loading.gif")}
        // alt={item?.product?.name}
        style={{
          alignSelf: "center",
          width: 200,
          height: 200,
        }}
        defaultSource={require("../../assets/images/spinning-loading.gif")}
        resizeMode={"contain"}
      />
    </View>
  ) : (
    <View style={{ width: isPhone ? width - 32 : "100%" }}>
      {Boolean(
        payment?.method_type === "manual_transfer" &&
          !payment?.paid_at &&
          state === "waiting_for_payment"
      ) && (
        <>
          <View style={{ marginBottom: 44 }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <LatoRegular
                style={{
                  fontSize: 24,
                  lineHeight: 32,
                  color: colors.grey33,
                  marginBottom: 8,
                }}
              >
                Bank Details
              </LatoRegular>
            </View>
            <LatoRegular
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey6C,
                marginBottom: 24,
              }}
            >
              Kindly settle the payment by transferring it to one of our bank
              accounts:
            </LatoRegular>
            {manualBankArr.map(({ title, name, account }, idx) => {
              return (
                <View
                  style={{
                    marginBottom: 16,
                    paddingBottom: 16,
                    borderBottomColor: colors.greyE6,
                    borderBottomWidth: 1,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    {`${title} / ${name} / ${account}`}
                  </LatoRegular>
                </View>
              );
            })}
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey6C,
                marginBottom: 16,
              }}
            >
              To avoid order delays please check the following
            </LatoBold>
            {numberedCautionsArr.map((item, idx) => {
              return (
                <View
                  style={{
                    marginBottom: 8,
                    flexDirection: "row",
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey6C,
                      marginRight: 8,
                    }}
                  >
                    {`${idx + 1}.`}
                  </LatoRegular>
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey6C,
                    }}
                  >
                    {item}
                  </LatoRegular>
                </View>
              );
            })}
          </View>
          <View style={{ marginBottom: 64 }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <LatoRegular
                style={{
                  fontSize: 24,
                  lineHeight: 32,
                  color: colors.grey33,
                  marginBottom: 8,
                }}
              >
                Confirm Payment
              </LatoRegular>
            </View>
            <LatoRegular
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey6C,
                marginBottom: 24,
              }}
            >
              Upload your direct bank transfer receipt, so we can verify your
              payment quickly.
            </LatoRegular>
            <View>
              {Boolean(!receipt.content) ? (
                <DragDropFile setImage={setReceipt} setImageURI={setImageURI} />
              ) : (
                <View>
                  <TouchableOpacity
                    onPress={(event) => {
                      openFileSelector();
                    }}
                  >
                    <Image
                      source={receipt.content}
                      style={{ width: "100%", height: 500, marginBottom: 32 }}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                  <Formik
                    onSubmit={handleUploadReceipt}
                    validateOnChange={hasSubmitted}
                    validateOnBlur={hasSubmitted}
                    initialValues={initialValues}
                    validate={(values) => {
                      !hasSubmitted && setHasSubmitted(true);
                      const errors = {};

                      for (const a of paymentArr) {
                        const { name, required, label } = a;

                        if (required && !values[name]) {
                          errors[name] = `${sentenceCase(
                            label
                          )} can't be blank`;
                        } else if (
                          name !== "return_tracking_number" &&
                          name.includes("number")
                        ) {
                          if (!regexChecker(name, values[name])) {
                            errors[name] = `${sentenceCase(
                              label
                            )} only accept number`;
                          }
                        }
                      }
                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      resetForm,
                    }) => (
                      <View>
                        {paymentArr.map(
                          (
                            {
                              name,
                              placeholder,
                              required,
                              hide,
                              initialValue,
                              disabled,
                              label,
                            },
                            index
                          ) => {
                            return (
                              <View key={index}>
                                <MyInput
                                  error={errors[name]}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  name={name}
                                  label={label}
                                  placeholder={placeholder}
                                  required={required}
                                  hide={hide}
                                  initialValue={initialValue}
                                  customWidth={"100%"}
                                  disabled={disabled}
                                />
                              </View>
                            );
                          }
                        )}
                        <View
                          style={{
                            flexDirection: "row",
                            // alignItems: "center",
                            marginBottom: 16,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => setSelected(!selected)}
                            style={{
                              backgroundColor: selected
                                ? colors.grey33
                                : colors.whiteFF,
                              borderRadius: 4,
                              borderWidth: 1,
                              borderColor: selected
                                ? colors.grey33
                                : colors.greyE6,
                              padding: 2,
                              justifyContent: "center",
                              alignItems: "center",
                              height: 16,
                              width: 16,
                              marginRight: 8,
                            }}
                          >
                            <RiCheckLine size={12} color={colors.whiteFF} />
                          </TouchableOpacity>
                          <LatoRegular
                            style={{
                              fontSize: 12,
                              lineHeight: 16,
                              color: colors.grey6C,
                            }}
                          >
                            I confirm and validate that the bank account data
                            provided is the authorized source of payment for the
                            transaction to Belle and Kate.
                          </LatoRegular>
                        </View>
                        <View
                          style={{
                            flexDirection: isPhone ? "column" : "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Buttons
                            isBlack
                            label="Upload Payment Proof"
                            loading={loadingUpload}
                            onPress={handleSubmit}
                            disabled={
                              errors.bank ||
                              errors.bank_account_name ||
                              errors.bank_account_number ||
                              errors.bank_branch ||
                              !selected
                            }
                          />
                        </View>
                      </View>
                    )}
                  </Formik>
                </View>
              )}
            </View>
          </View>
        </>
      )}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <LatoRegular
              style={{ fontSize: 20, lineHeight: 28, marginBottom: 8 }}
            >
              Order Details
            </LatoRegular>
            {Boolean(
              !isCheckout &&
                (state === "waiting_for_payment" ||
                  state === "failed" ||
                  checker)
            ) && (
              <Buttons
                onPress={
                  state === "waiting_for_payment" ? handleCancel : handleRefund
                }
                label={
                  state === "waiting_for_payment"
                    ? "Cancel Order"
                    : checker
                    ? "Request Return"
                    : "Request Refund"
                }
                width={150}
              />
            )}
          </View>
          {Boolean(!isCheckout && state !== "waiting_for_payment") && (
            <LatoRegular
              style={{
                fontSize: 16,
                lineHeight: 20,
                marginBottom: 32,
                width: isPhone ? width - 32 : width - 300,
              }}
            >
              {"Order "}
              <LatoBold>{reference_number}</LatoBold>
              {" was placed on "}
              <LatoBold>
                {created_at && customDateFormatter(created_at, true)}
              </LatoBold>
              {" and is currently being "}
              <LatoBold>{`${stateMaskingDecider(state)}.`}</LatoBold>
            </LatoRegular>
          )}
          {Boolean(rejection_note) && (
            <View
              style={{
                backgroundColor: colors.whiteF1,
                borderWidth: 1,
                borderColor: colors.greyBB,
                borderRadius: 4,
                flexDirection: "row",
                alignItems: "center",
                paddingVertical: 8,
                paddingHorizontal: 12,
                marginBottom: 32,
              }}
            >
              <LatoRegular
                style={{
                  fontSize: 16,
                  lineHeight: 20,
                  // width: width - 32,
                }}
              >
                {rejection_note}
              </LatoRegular>
            </View>
          )}
        </View>
      </View>
      <View style={{ marginBottom: 100 }}>
        {detailTableArr.map(({ left, right, additionalRight, isBold }, ind) => {
          return (
            <View
              key={ind}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingVertical: 24,
                borderBottomColor: colors.greyE6,
                borderBottomWidth: ind === detailTableArr.length - 1 ? 0 : 1,
              }}
            >
              <View
                style={{
                  width: isPhone ? "55%" : "37.5%",
                  alignItems: ind === 0 ? "center" : "flex-start",
                }}
              >
                {Boolean(typeof left === "object") ? (
                  left.map(({ designer, name, sku }, idx) => (
                    <View
                      key={idx}
                      style={{
                        marginBottom: idx === left.length - 1 ? 0 : 16,
                        height: 76,
                      }}
                    >
                      <LatoRegular
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          marginBottom: 8,
                          width: isPhone
                            ? (width - 32) / 2
                            : isTablet
                            ? width - 700
                            : width - 900,
                        }}
                        numberOfLines={2}
                      >{`${designer} - ${name}`}</LatoRegular>
                      <LatoRegular
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          marginBottom: 8,
                        }}
                      >{`SKU: ${sku}`}</LatoRegular>
                    </View>
                  ))
                ) : (
                  <LatoRegular
                    style={{
                      fontFamily: isBold ? "Lato-Bold" : "Lato-Regular",
                    }}
                  >
                    {left}
                  </LatoRegular>
                )}
              </View>
              <View
                style={{
                  width: "40%",
                  alignItems: ind === 0 ? "center" : "flex-start",
                }}
              >
                {Boolean(typeof right === "object") ? (
                  right.map(({ price }, idx) => (
                    <View
                      key={idx}
                      style={{
                        marginBottom: idx === right.length - 1 ? 0 : 16,
                        height: typeof left === "object" ? 76 : "auto",
                      }}
                    >
                      <LatoRegular
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          marginBottom: left[0]?.designer ? 36 : 0,
                          fontFamily: isBold ? "Lato-Bold" : "Lato-Regular",
                          // textAlign: "right",
                        }}
                      >{`IDR ${formatCurrency(price)}`}</LatoRegular>
                    </View>
                  ))
                ) : (
                  <View>
                    <LatoRegular
                      style={{
                        fontFamily: isBold ? "Lato-Bold" : "Lato-Regular",
                        // textAlign: "right",
                        marginBottom: 8,
                      }}
                    >
                      {right}
                    </LatoRegular>
                    {Boolean(
                      additionalRight && state === "waiting_for_payment"
                    ) && (
                      <TouchableOpacity
                        onPress={() => {
                          window.snap.pay(
                            payment?.redirect_url.split("/").pop(),
                            {
                              onSuccess: function (result) {
                                /* You may add your own implementation here */
                                alert("payment success!");
                                console.log(result);
                              },
                              onPending: function (result) {
                                /* You may add your own implementation here */
                                alert("wating your payment!");
                                console.log(result);
                              },
                              onError: function (result) {
                                /* You may add your own implementation here */
                                alert("payment failed!");
                                console.log(result);
                              },
                              onClose: function () {
                                /* You may add your own implementation here */
                                alert(
                                  "you closed the popup without finishing the payment"
                                );
                              },
                            }
                          );
                        }}
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          fontFamily: isBold ? "Lato-Bold" : "Lato-Regular",
                          // textAlign: "right",
                          color: colors.blue3E,
                        }}
                      >
                        {additionalRight}
                      </TouchableOpacity>
                    )}
                  </View>
                )}
              </View>
            </View>
          );
        })}
      </View>
      {Boolean(rate?.name) && (
        <View style={{ marginBottom: isPhone ? 48 : 100 }}>
          <LatoBold style={{ fontSize: 16, lineHeight: 24, marginBottom: 24 }}>
            Shipping Address
          </LatoBold>
          <LatoRegular style={{ fontSize: 14, lineHeight: 20 }}>
            {recipient_name}
          </LatoRegular>
          <LatoRegular style={{ fontSize: 14, lineHeight: 20 }}>
            {complete_address}
          </LatoRegular>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 24,
            }}
          >
            <BsTelephone size={20} color={colors.grey33} />
            <LatoRegular
              style={{ fontSize: 14, lineHeight: 20, marginLeft: 10 }}
            >
              {`+62${recipient_number}`}
            </LatoRegular>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 14,
            }}
          >
            <MdOutlineEmail size={20} color={colors.grey33} />
            <LatoRegular
              style={{ fontSize: 14, lineHeight: 20, marginLeft: 10 }}
            >
              {email}
            </LatoRegular>
          </View>
        </View>
      )}
    </View>
  );
}

import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, useWindowDimensions, Text } from "react-native";
import { useNavigate } from "react-router-dom";
import ImageWithLoader from "../ImageWithLoader"; // Assuming this can handle external URLs
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";
import { LatoRegular } from "../Text";
import Buttons from "../Buttons";
import { times } from "lodash";

// Mock fetch function - replace with actual API call
const fetchLatestArticles = () => {
    const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
    return fetch(`https://cdn.contentful.com/spaces/91r2n3idsld6/entries?access_token=${accessToken}&limit=4&select=fields.title,fields.featuredImage,fields.category,fields.publishedDate,fields.slug&content_type=article&include=1`)
      .then(response => response.json())
      .then(data => {
        const articles = data.items.map(article => {
          const imageUrl = data.includes.Asset.find(asset => asset.sys.id === article.fields.featuredImage.sys.id)?.fields.file.url;
          const categoryEntry = data.includes.Entry.find(entry => entry.sys.id === article.fields.category.sys.id);
          return {
            title: article.fields.title,
            categoryId: article.fields.category.sys.id,
            category: categoryEntry?.fields.name,
            imageUrl: imageUrl && `https:${imageUrl}`, // Ensure the URL is correctly formatted
            slug: article.fields.slug
          };
        });
        return articles; // Return the articles for further use
      })
      .catch(error => {
        console.error('Error:', error);
        return []; // Return an empty array or specific error handling
      });
  };
  

function LatestArticles() {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const loading = false;

  useEffect(() => {
    fetchLatestArticles().then(fetchedArticles => {
      setArticles(fetchedArticles); // This will now correctly update your state
    });
  }, []);

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - 2 * gap;

  return (
    <View style={{ 
        marginBottom: isPhone ? 40 : 100,
        alignItems: "center" }}>
        <View
            style={{
            paddingTop: isPhone ? 48 : 100,
            paddingBottom: !isPhone ? 80 : 48,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: gap,
            }}
        >
            <LatoRegular
                style={{
                fontSize: isPhone ? 16 : 24,
                lineHeight: isPhone ? 24 : 32,
                color: colors.grey6C,
                backgroundColor: colors.whiteFF,
                paddingHorizontal: isPhone ? 8 : 56,
                }}
            >
                LATEST FROM OUR JOURNAL
            </LatoRegular>
            <View
            style={{
                position: "absolute",
                zIndex: -1,
                width: containerWidth,
                height: 1,
                backgroundColor: colors.greyE6,
                // left: gap,
            }}
            />
        </View>
        <View style={{ 
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: containerWidth
            //   paddingHorizontal: gap,
        }}>
            {articles.map(({ id, title, category, imageUrl, slug }) => (
            <TouchableOpacity
                key={slug}
                onPress={() => window.location.href = `https://journal.belleandkate.com/${slug}`} // Adjust navigation as needed
                style={{ 
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: isPhone ? 10 : 20,
                    width: isPhone ? (containerWidth - 20) / 2 : (containerWidth - 80) / 4
                    }}
            >
                <ImageWithLoader
                source={{ uri: imageUrl }}
                containerStyle={{
                    width: "100%",
                    height: isPhone ? (containerWidth - 20) / 2 : (containerWidth - 80) / 4
                }}
                style={{
                    width: "100%",
                    height: "100%",
                }}
                defaultStyle={{
                    width: 48,
                    height: isPhone
                        ? containerWidth
                        : (containerWidth - 100) / 2,
                }}
                resizeMode={"cover"}
                woMask
                />
                <LatoRegular
                        style={{
                            fontSize: isPhone ? 12 : 16,
                            lineHeight: isPhone ? 16 : 24,
                            color: colors.grey33,
                            marginTop: isPhone ? 16 : 24,
                            marginBottom: 8,
                            textTransform: "uppercase"
                        }}
                        >
                            {category}
                        </LatoRegular>
                <LatoRegular
                        style={{
                            fontSize: isPhone ? 14 : 20,
                            lineHeight: isPhone ? 20 : 28,
                            color: colors.black22,
                            marginBottom: isPhone ? 32 : 24,
                            textTransform: "capitalize",
                            textAlign: "center",
                            textWrap: "wrap"
                        }}
                        >
                        {title}
                        </LatoRegular>
            </TouchableOpacity>
            ))}
      </View>
      <a href="https://journal.belleandkate.com/">
        <Buttons
          label={"Read Our Journal"}
          width={isPhone ? containerWidth : 155}
          height={48}
          borderRadius={2}
          containerStyle={{ alignSelf: "center" }}
          isBlack
        />
      </a>
      </View>
  );
}

export default LatestArticles;

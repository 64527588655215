export const colors = {
  green5B: "#8d9e74",
  green8D: "#8D9E74",
  green06: "#06AC4E",
  yellowCC: "#CCD32D",
  yellowEE: "#EED600",
  orangeF6: "#F68920",
  grey33: "#333334",
  grey6C: "#6C6C71",
  grey90: "#909098",
  greyBB: "#BBBBC0",
  grey5F: "#5F6368",
  greyE6: "#E6E6E8",
  grey72: "#727272",
  whiteFC: "#FCFCFC",
  whiteF1: "#F1F1F1",
  whiteF7: "#F7F7F7",
  black00: "#000000",
  black1A: "#1A1A1A",
  black22: "#222222",
  whiteFF: "#FFFFFF",
  brown6B: "#6B5940",
  redAD: "#AD1313",
  redB6: "#B60218",
  blue3E: "#3E8BFF",
  pinkFF: "#FF73F1",
  pinkCB: "#CB1656",
  lightPinkCB: "rgba(203, 22, 86, 0.15)",
  orangeFF: "#FF7E00",
  yellowFF: "#FFF951",
  lightYellow: "rgba(204, 211, 45, 0.20)",
  whiteE8: "#E8E5E0",
  green03: "#037255",
  green46: "#46B113",
  greenDD: "#DDE2D6",
  blue22: "#224674",
  purple72: "#7213D4",
  goldD6: "#D6C880",
  goldE1: "#E1BD00",
  goldA1: "#A1A806",
  silverEF: "#EFEFEF",
  lightGreen5B: "rgba(141, 158, 116, 0.15)",
  lightGreen: "rgba(141, 158, 116, 0.35)",
  transparentBlack: "rgba(34, 34, 34, 0.6)",
  transparentBlack00: "rgba(0, 0, 0, 0.35)",
  superTransparentBlack00: "rgba(0, 0, 0, 0.04)",
  transparentGrey6C: "rgba(108, 108, 113, 0.2)",
  transparentGreyDD: "rgba(221, 221, 221, 0.6)",
  transparentGreyE6: "rgba(230, 230, 232, 0.25)",
  transparentGreyE8: "rgba(232, 229, 224, 0.4)",
  transparentGrey33: "rgba(51, 51, 52, 0.75)",
  brown4A: "#4A301B",
  brownE0: "#E0CFB8"
};

export default colors;

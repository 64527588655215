import React, { useContext, useState } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import Navbar from "../components/Navbar";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { api, useMutation } from "../utils/api";
import { getDeviceType } from "../tools/Interface";
import { MyInput } from "../components/Inputs";
import Line from "../components/Line";
import { Wrapper, WrapperInsider } from "../components/Wrapper";
import { sentenceCase } from "change-case";
import { regexChecker } from "../tools/regexChecker";
import { useAuth } from "../contexts/AuthContext";
import _ from "lodash";
import ToasterContext from "../contexts/ToastContext";
import Buttons from "../components/Buttons";
import Footer from "../components/Footer";
import { LinksFromCDN } from "../constants/cdnLinks";

export default function RegisterScreen() {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { updateUser, setUserHook, authorize, user, refetch } = useAuth();
  const navigate = useNavigate();
  const { showToast } = useContext(ToasterContext);

  const formFieldArr = [
    {
      name: "firstname",
      placeholder: "First Name",
      required: true,
    },
    {
      name: "lastname",
      placeholder: "Last Name",
      required: true,
    },
    {
      name: "email",
      placeholder: "Email Address",
      required: true,
    },
    {
      name: "password",
      placeholder: "Password",
      required: true,
      hide: true,
      length: 8,
    },
    {
      name: "password_confirmation",
      placeholder: "Confirm Password",
      required: true,
      hide: true,
      match: "password",
    },
  ];

  const { mutation: register, loading } = useMutation({
    showToast: showToast,
    url: "/api/auth",
    method: "post",
    afterSuccess: async (response, payload) => {
      const { firstname, lastname } = payload;
      const userData = _.get(response, "data.data");
      setUserHook(userData);

      await updateUser({
        user: { detail_attributes: { name: firstname + " " + lastname } },
      });

      await authorize();
      await refetch();
    },
  });

  const handleRegister = async (values) => {
    const { firstname, lastname, email, password } = values;

    await register({
      firstname,
      lastname,
      email,
      password,
      password_confirmation: password,
    });
  };

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        {Boolean(user?.id) && (
          <Navigate to={"/"} replace={true} relative={"path"} />
        )}
        <Navbar />
        <View style={{ flexDirection: isPhone ? "column" : "row" }}>
          <Image
            source={LinksFromCDN.register}
            style={{
              height: isPhone ? "40vh" : "100vh",
              width: isPhone ? "100vw" : "50vw",
              marginTop: -88,
            }}
          />
          <WrapperInsider engineeredHeight={"85vh"} centered>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: isPhone ? "100vw" : "50vw",
                paddingHorizontal: isTablet ? 40 : 190,
              }}
            >
              <LatoRegular
                style={{
                  fontSize: 24,
                  lineHeight: 32,
                  marginBottom: 24,
                  width: isPhone ? "80vw" : isTablet ? "40vw" : "32.5vw",
                  textAlign: "center",
                }}
              >
                Create an account
              </LatoRegular>
              <Formik
                onSubmit={handleRegister}
                validateOnChange={hasSubmitted}
                validateOnBlur={hasSubmitted}
                initialValues={{
                  email: "",
                  password: "",
                  firstname: "",
                  lastname: "",
                  password_confirmation: "",
                }}
                validate={(values) => {
                  !hasSubmitted && setHasSubmitted(true);
                  const errors = {};

                  for (const a of formFieldArr) {
                    const { name, required, placeholder, length, match } = a;

                    if (required && !values[name]) {
                      errors[name] = `${sentenceCase(
                        placeholder
                      )} can't be blank`;
                    } else if (!regexChecker(name, values[name])) {
                      errors[name] =
                        name === "password"
                          ? "Password must includes 1 uppercase, 1 lowercase, and 1 number"
                          : `Invalid ${placeholder.toLowerCase()}`;
                    } else if (length && values[name].length < length) {
                      errors[name] = `${sentenceCase(
                        name.split("_").join(" ")
                      )} must have at least ${length} characters`;
                    } else if (match && values[name] !== values[match]) {
                      errors[name] = `${sentenceCase(
                        match.split("_").join(" ")
                      )} doesn't match`;
                    }
                  }
                  return errors;
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <View>
                    {formFieldArr.map(
                      ({ name, placeholder, required, hide }, index) => {
                        return (
                          <View key={index}>
                            <MyInput
                              error={errors[name]}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              name={name}
                              placeholder={placeholder}
                              required={required}
                              hide={hide}
                              label={values[name] ? placeholder : ""}
                            />
                          </View>
                        );
                      }
                    )}
                    <Buttons
                      label="Create Account"
                      width={"100%"}
                      isBlack
                      onPress={handleSubmit}
                      disabled={errors.email || errors.password}
                      loading={loading}
                      borderRadius={6}
                      noBorder
                      containerStyle={{ marginVertical: 32 }}
                    />
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey90,
                        alignSelf: "flex-start",
                        paddingHorizontal: 6,
                        width: isPhone ? "80vw" : isTablet ? "40vw" : "32.5vw",
                      }}
                    >
                      {"By registering, you agree to our "}
                      <TouchableOpacity
                        onPress={() => navigate("/terms-conditions")}
                      >
                        <LatoBold
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey33,
                            textDecorationLine: "underline",
                          }}
                        >
                          Terms and Conditions
                        </LatoBold>
                      </TouchableOpacity>
                      {" & "}
                      <TouchableOpacity
                        onPress={() => navigate("/privacy-policy")}
                      >
                        <LatoBold
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey33,
                            textDecorationLine: "underline",
                          }}
                        >
                          Privacy Policy
                        </LatoBold>
                      </TouchableOpacity>
                    </LatoRegular>
                  </View>
                )}
              </Formik>
              <Line
                title="OR"
                width={isPhone ? "80vw" : isTablet ? "40vw" : "32.5vw"}
              />
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: isPhone ? "80vw" : isTablet ? "40vw" : "32.5vw",
                }}
              >
                <LatoRegular
                  style={{
                    fontSize: 16,
                    lineHeight: 24,
                    color: colors.grey6C,
                  }}
                >
                  {"Already client? "}
                </LatoRegular>
                <TouchableOpacity
                  onPress={() => navigate("/login")}
                  style={{ alignSelf: "flex-end" }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                    }}
                  >
                    Login
                  </LatoRegular>
                </TouchableOpacity>
              </View>
            </View>
          </WrapperInsider>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

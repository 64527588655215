import React, { useContext, useEffect } from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import colors from "../../assets/colors/colors";
import ProductTag from "../ProductTag";
import { LatoBold, LatoRegular } from "../Text";
import { formatCurrency } from "../../tools/formatter";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import { PiHeartStraight } from "react-icons/pi";
import ImageWithLoader from "../ImageWithLoader";
import CurrencyContext from "../../contexts/CurrenctContext";
import { removePrependedDesignerName } from "../../utils/productutil";
import { Link } from "react-router-dom";

function GridItem({
  style = {},
  divider = 1,
  index,
  item = {},
  isCrush = false,
  isClassroom = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const isRightmostItem = (index + 1) % divider === 0;

  const { selected } = useContext(CurrencyContext);

  const gap = isPhone ? 24 : width < 1350 ? 50 : width < 1500 ? 150 : 220;
  const containerWidth = width - gap * 2;

  const usedWidth =
    isCrush && !isPhone
      ? containerWidth / 2 - (isTablet ? 12 : 24)
      : containerWidth;

  const navigate = useNavigate();

  const {
    product = {},
    id,
    name: title2,
    selling_price: price2,
    summary_index: summary_index2,
    default_price: realPrice2,
    inventory = {},
    active,
    multi_currency_price: multi2,
  } = item;
  const {
    name: title,
    selling_price: price,
    summary_index,
    default_price: realPrice,
    multi_currency_price: multi,
  } = product;

  const usedSummary = summary_index2 ? summary_index2 : summary_index;

  const {
    condition,
    designer: merk,
    direct_photos,
    badges,
  } = usedSummary || {};

  const soldOut = inventory?.id ? !inventory?.active : !active;

  const shownPrice = multi2
    ? multi2?.selling_price[selected]
    : price2 && !multi
    ? price2
    : multi
    ? multi?.selling_price[selected]
    : price;
  const shownRealPrice = multi2
    ? multi2?.default_price[selected]
    : realPrice2 && !multi
    ? realPrice2
    : multi
    ? multi?.default_price[selected]
    : realPrice;

  return (
    <Link
      style={{
        width:
          (usedWidth -
            (isPhone
              ? 24
              : isTablet
              ? isCrush
                ? 32
                : 64
              : isCrush
              ? 60
              : 144)) /
          divider,
        marginRight: isRightmostItem ? 0 : isPhone ? 24 : isTablet ? 32 : 48,
      }}
      to={`/products/${inventory?.id || id}?sourceIndex=${index}`}
    >
      <View
        style={{
          alignItems: "center",
        }}
      >
        <View
          style={{
            height:
              ((5 / 3) *
                (usedWidth -
                  (isPhone
                    ? 24
                    : isTablet
                    ? isCrush
                      ? 32
                      : 64
                    : isCrush
                    ? 64
                    : 144))) /
              divider,
            width:
              (usedWidth -
                (isPhone
                  ? 24
                  : isTablet
                  ? isCrush
                    ? 32
                    : 64
                  : isCrush
                  ? 64
                  : 144)) /
              divider,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Boolean(direct_photos.filter((item) => item)[0]) && (
            <ImageWithLoader
              source={{
                uri: `${direct_photos.filter((item) => item)[0]}`,
              }}
              alt={`Product ${index}`}
              style={{
                height:
                  ((5 / 3) *
                    (usedWidth -
                      (isPhone
                        ? 24
                        : isTablet
                        ? isCrush
                          ? 32
                          : 64
                        : isCrush
                        ? 64
                        : 144))) /
                  divider,
                width:
                  (usedWidth -
                    (isPhone
                      ? 24
                      : isTablet
                      ? isCrush
                        ? 32
                        : 64
                      : isCrush
                      ? 64
                      : 144)) /
                  divider,
              }}
              containerStyle={{
                height:
                  ((5 / 3) *
                    (usedWidth -
                      (isPhone
                        ? 24
                        : isTablet
                        ? isCrush
                          ? 32
                          : 64
                        : isCrush
                        ? 64
                        : 144))) /
                  divider,
                width:
                  (usedWidth -
                    (isPhone
                      ? 24
                      : isTablet
                      ? isCrush
                        ? 32
                        : 64
                      : isCrush
                      ? 64
                      : 144)) /
                  divider,
              }}
              defaultStyle={{
                height:
                  ((5 / 3) *
                    (usedWidth -
                      (isPhone
                        ? 24
                        : isTablet
                        ? isCrush
                          ? 32
                          : 64
                        : isCrush
                        ? 64
                        : 144))) /
                  divider,
                width: 48,
              }}
              defaultSource={require("../../assets/images/spinning-loading.gif")}
              resizeMode={"contain"}
              woMask
            />
          )}
          <View
            style={{
              position: "absolute",
              backgroundColor: colors.superTransparentBlack00,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
          <View
            style={{
              position: "absolute",
              top: 8,
              bottom: 0,
              right: 8,
            }}
          >
            <PiHeartStraight size={20} color={colors.black22} />
          </View>
          <View
            style={{
              position: "absolute",
              top: 8,
              bottom: 0,
              left: 8,
              height: isTablet ? 24 : 36,
            }}
          >
            <ProductTag
              influencer={badges?.includes("Influencer Preloved")}
              editor={badges?.includes("Editor's Pick")}
              vintage={badges?.includes("Vintage")}
            />
          </View>
          {Boolean(soldOut && !isCrush && !isClassroom) && (
            <View
              style={{
                position: "absolute",
                bottom: 8,
                right: 8,
                padding: 8,
                backgroundColor: colors.grey90,
                borderRadius: 4,
              }}
            >
              <LatoBold
                style={{
                  fontSize: isPhone ? 8 : 14,
                  lineHeight: isPhone ? 12 : 20,
                  color: colors.whiteFF,
                }}
              >
                Sold Out
              </LatoBold>
            </View>
          )}
        </View>
      </View>
      <View
        style={{
          marginTop: 14,
          justifyContent: "space-between",
          marginBottom: isPhone ? 24 : 48,
        }}
      >
        <View>
          <LatoRegular
            style={{
              fontSize: isPhone ? 10 : 14,
              lineHeight: isPhone ? 12 : 20,
              color: colors.grey72,
              marginBottom: 4,
            }}
          >
            {condition}
          </LatoRegular>
          <LatoBold style={style.merk}>{merk}</LatoBold>
          <LatoRegular numberOfLines={2} style={style.title}>
            {title2 ? removePrependedDesignerName(title2, merk) : removePrependedDesignerName(title, merk)}
          </LatoRegular>
        </View>
        <LatoBold
          style={{ ...style.price, color: colors.redAD, marginBottom: 6 }}
        >
          {shownPrice !== shownRealPrice && !isCrush && !isClassroom
            ? `Save ${(
                ((shownRealPrice - shownPrice) / shownRealPrice) *
                100
              ).toFixed(0)}%`
            : ""}
        </LatoBold>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <LatoBold
            style={{
              ...style.price,
              color:
                shownPrice !== shownRealPrice && !isCrush && !isClassroom
                  ? colors.grey6C
                  : colors.grey33,
              textDecorationLine:
                shownPrice !== shownRealPrice && !isCrush && !isClassroom
                  ? "line-through"
                  : "none",
            }}
          >
            {`${selected} ${formatCurrency(shownRealPrice)}`}
          </LatoBold>
          <LatoRegular style={{ textDecorationLine: "none" }}>
            {"  "}
          </LatoRegular>
          {Boolean(
            shownPrice !== shownRealPrice && !isCrush && !isClassroom
          ) && (
            <LatoBold style={style.price}>
              {`${selected} ${formatCurrency(shownPrice)}  `}
            </LatoBold>
          )}
        </View>
      </View>
    </Link>
  );
}

export default GridItem;

import { Linking } from "react-native";
import { eventsTracker } from "../utils/eventsFunctions";
import { contactDecider } from "./decider";

export const openWhatsapp = async (text = "", phone = contactDecider("")) => {
  try {
    const url = `https://wa.me/${phone}?text=${text}`;
    const openWA = await Linking.openURL(url);
    console.log("WhatsApp Opened successfully " + openWA);
    eventsTracker("contact_customer_service", { text, phone });
  } catch (error) {
    console.log(error, "open chat error");
    // Alert.alert("Failed to open Whatsapp");
  }
};

export const sentenceDimension = (
  fontSize = 14,
  lineHeight = 20,
  fontFamily = "lato-regular",
  text = "",
  containerWidth,
  imageHeight,
  tracker
) => {
  const splitted = [];
  const words = text.split(" ");
  let index = 0;
  let calculatedWidth = 0;
  const maxItem = Math.floor(imageHeight / lineHeight) - tracker;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize} ${fontFamily}`;

  words.forEach((item) => {
    const totalWidth = Array.from(item).reduce((width, char) => {
      return width + context.measureText(char).width;
    }, 0);

    if (calculatedWidth + totalWidth > containerWidth) {
      splitted[index] += item;
      index += 1;
      calculatedWidth = 0;
    } else {
      calculatedWidth += totalWidth;
      splitted[index] = splitted[index]
        ? (splitted[index] += ` ${item}`)
        : item;
    }
  });

  return { item: splitted, max: maxItem };
};

import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import { LatoRegular } from "../Text";
import Buttons from "../Buttons";
import { Linking } from "react-native";

function BottomNavBar({
  user = {},
  hover = false,
  setOpenSubBurger = () => {},
  setSubBurgerIndex = () => {},
  setHover = () => {},
  subBurgerIndex = false,
  burgerArr = [],
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });
  const navigate = useNavigate();
  return (
    <View
      onMouseEnter={() => {
        if (!hover) {
          setOpenSubBurger(false);
          setSubBurgerIndex(null);
        }
      }}
      onMouseLeave={() => {
        if (!hover) {
          setHover("");
          setOpenSubBurger(false);
          setSubBurgerIndex(null);
        }
      }}
      style={{
        ...style.container,
        backgroundColor: colors.whiteFF,
        paddingHorizontal: isPhone
          ? 16
          : width < 1350
          ? 50
          : width < 1500
          ? 150
          : 230,
        paddingVertical: 14,
        borderBottomColor: colors.greyE6,
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          width: width - 750,
        }}
      >
        {burgerArr.map((itm, idx) => (
          <TouchableOpacity
            key={idx}
            activeOpacity={itm.menu?.length ? 1 : 0.5}
            onMouseEnter={() => {
              if (hover !== itm.title) {
                setHover(itm.title);
                setOpenSubBurger(itm.menu ? true : false);
                setSubBurgerIndex(itm.menu ? idx : null);
              }
            }}
            onMouseLeave={() => {
              if (hover) {
                setHover("");
              }
            }}
            style={{
              ...style.rightItem,
              marginRight: idx === burgerArr.length - 1 ? 0 : 20,
              borderBottomWidth: subBurgerIndex === idx ? 2 : 0,
              marginBottom: -20,
              paddingBottom: 20,
            }}
            onPress={() => {
              if (itm.externalLink) {
                Linking.openURL(itm.externalLink).catch(err => console.error("Failed to open URL:", err));
                return;
              }
              if (itm.menu || !itm.path) return;
              if (typeof itm.path) {
                navigate(itm.path, {
                  state: { discounted: itm.title === "Sale" },
                });
              }
            }}
          >
            <LatoRegular
              style={{
                fontSize: 14,
                lineHeight: 20,
                color:
                  idx === 0
                    ? colors.redB6
                    : hover === itm.title
                    ? colors.green5B
                    : colors.grey33,
                textTransform: "uppercase",
              }}
            >
              {typeof itm === "object" ? itm.title : itm}
            </LatoRegular>
          </TouchableOpacity>
        ))}
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: colors.yellowCC,
        }}
      >
        <Buttons
          backgroundColor={colors.green5B}
          label={"AUTHENTICATION"}
          fontSize={14}
          lineHeight={20}
          noBorder
          width={168}
          height={32}
          color={colors.whiteFF}
          textStyle={{ fontFamily: "lato-regular" }}
          containerStyle={{ paddingHorizontal: 20, paddingVertical: 4 }}
          onPress={() => navigate("/authentication")}
        />
        <Buttons
          backgroundColor={colors.black1A}
          label={"SELL WITH US"}
          fontSize={14}
          lineHeight={20}
          noBorder
          width={138}
          height={32}
          color={colors.whiteFF}
          textStyle={{ fontFamily: "lato-regular" }}
          containerStyle={{ paddingHorizontal: 20, paddingVertical: 4 }}
          onPress={() => navigate("/sell-with-us/consignment")}
        />
      </View>
    </View>
  );
}

export default BottomNavBar;

const style = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 24,
    paddingHorizontal: 45,
  },
  sideContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftItem: {
    marginRight: 40,
    color: colors.black00,
    textDecorationLine: "none",
    fontSize: 14,
    fontFamily: "Lato-Regular",
    zIndex: 5,
  },
  rightItem: {
    color: colors.black00,
    textDecorationLine: "none",
    fontSize: 14,
    fontFamily: "Lato-Regular",
    marginRight: 32,
  },
  textStyle: { fontSize: 16, lineHeight: 24 },
});

import React, { useState } from "react";
import { View, Image } from "react-native";
import colors from "../assets/colors/colors";

const ImageWithLoader = ({
  source,
  defaultStyle = {},
  style = {},
  containerStyle = {},
  resizeMode = "contain",
  woMask = false,
  alt = "",
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        ...containerStyle,
      }}
    >
      {/* Conditional rendering: Show default image if 'imageLoaded' state is false */}
      {Boolean(!imageLoaded) && (
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
            ...containerStyle,
          }}
        >
          <Image
            accessibilityLabel={alt}
            style={defaultStyle} // Combined styles for defaultSource
            source={require("../assets/images/spinning-loading.gif")} // Placeholder or default image source
            resizeMode={"center"}
          />
        </View>
      )}
      {/* Show main image if 'imageLoaded' state is true */}
      <Image
        accessibilityLabel={alt}
        style={style} // Combined styles for source
        source={source} // Actual image source
        onLoad={() => setImageLoaded(true)} // Set 'imageLoaded' to true when image loads
        resizeMode={resizeMode}
        loadingIndicatorSource={require("../assets/images/spinning-loading.gif")}
      />
      {Boolean((imageLoaded && !woMask) || !imageLoaded) && (
        <View
          style={{
            position: "absolute",
            backgroundColor: colors.superTransparentBlack00,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
    </View>
  );
};

export default ImageWithLoader;

import React from "react";
import { View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import Shimmer from "../Shimmer";

function GridLoading({ divider = 1, index = 0, isCrush = false }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 24 : width < 1350 ? 50 : width < 1500 ? 150 : 220;
  const containerWidth = width - gap * 2;

  const usedWidth =
    isCrush && !isPhone
      ? containerWidth / 2 - (isTablet ? 12 : 24)
      : width - gap * 2;

  return (
    <View
      style={{
        width:
          (usedWidth -
            (isPhone
              ? 24
              : isTablet
              ? isCrush
                ? 32
                : 64
              : isCrush
              ? 120
              : 144)) /
          divider,
        height: "auto",
        marginRight:
          (index + 1) % divider === 0 ? 0 : isPhone ? 24 : isTablet ? 32 : 48,
        marginBottom: 48,
      }}
    >
      <View
        style={{
          height:
            ((5 / 3) *
              (usedWidth -
                (isPhone
                  ? 24
                  : isTablet
                  ? isCrush
                    ? 32
                    : 64
                  : isCrush
                  ? 120
                  : 144))) /
            divider,
          width:
            (usedWidth -
              (isPhone
                ? 24
                : isTablet
                ? isCrush
                  ? 32
                  : 64
                : isCrush
                ? 120
                : 144)) /
            divider,
          justifyContent: "center",
          alignItems: "center",
          paddingVertical: 0,
          marginBottom: 48,
        }}
      >
        <Shimmer
          autoRun={true}
          visible={false}
          width={
            (usedWidth -
              (isPhone
                ? 24
                : isTablet
                ? isCrush
                  ? 32
                  : 64
                : isCrush
                ? 120
                : 144)) /
            divider
          }
          height={
            ((5 / 3) *
              (usedWidth -
                (isPhone
                  ? 24
                  : isTablet
                  ? isCrush
                    ? 32
                    : 64
                  : isCrush
                  ? 120
                  : 144))) /
            divider
          }
        />
      </View>
      <Shimmer
        autoRun={true}
        visible={false}
        width={
          (usedWidth -
            (isPhone
              ? 24
              : isTablet
              ? isCrush
                ? 32
                : 64
              : isCrush
              ? 120
              : 144)) /
          divider
        }
        height={16}
        hasBorder
        style={{ marginBottom: 12 }}
      />
      <Shimmer
        autoRun={true}
        visible={false}
        width={
          (usedWidth -
            (isPhone
              ? 24
              : isTablet
              ? isCrush
                ? 32
                : 64
              : isCrush
              ? 120
              : 144)) /
          divider
        }
        height={16}
        hasBorder
        style={{ marginBottom: 12 }}
      />
      <Shimmer
        autoRun={true}
        visible={false}
        width={
          (usedWidth -
            (isPhone
              ? 24
              : isTablet
              ? isCrush
                ? 32
                : 64
              : isCrush
              ? 120
              : 144)) /
          divider
        }
        height={16}
        hasBorder
        style={{ marginBottom: 12 }}
      />
    </View>
  );
}

export default GridLoading;

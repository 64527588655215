import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import Footer from "../components/Footer";
import ProductCover from "../components/Home/ProductCover";
import Navbar from "../components/Navbar";
import { api } from "../utils/api";
import BannerCarousel from "../components/Home/BannerCarousel";
import Journal from "../components/Home/Journal";
import VisitUs from "../components/Home/VisitUs";
import Commitment from "../components/Home/Commitment";
import { asyncOpenSubscribe } from "../tools/asyncStorages";
import NewsletterModal from "../components/Home/NewsletterModal";
import Review from "../components/Home/Review";
import CategorySection from "../components/Home/CategorySection";

export default function HomeScreen() {
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState([]);
  const [show, setShow] = useState(false);
  const [showLessPriorityElements, setShowLessPriorityElements] = useState(false);

  const { width, height } = useWindowDimensions();

  const getData = async () => {
    try {
      const shown = await asyncOpenSubscribe();

      setShow(shown);
    } catch (err) {
      console.log("err:", err.response);
    }
  };

  const getVoucherPromotion = async () => {
    try {
      setLoading(true);

      const res = await api.get("/api/vouchers/promoted");

      setPromotion(res?.data?.data || []);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getVoucherPromotion();
  }, [width]);

  setTimeout(() => {
    setShowLessPriorityElements(true)
  }, 1000);

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar promotion={promotion} />
        <BannerCarousel loading={loading} />
        
        <ProductCover isHome />
        {
          showLessPriorityElements &&
          <>
          <CategorySection selectedCategory={'newArrival'}/>
          <CategorySection selectedCategory={'sale'}/>
          <CategorySection selectedCategory={'vintage'}/>
          <Journal />
          <Review />
          <Commitment />
          <VisitUs />
          <Footer />
          </>
        }
      </ScrollView>
      {Boolean(show) && <NewsletterModal setShow={setShow} />}
    </View>
  );
}

import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import TopNavBar from "./TopNavBar";
import BottomNavBar from "./BottomNavBar";
import MainBurgerMenu from "./MainBurgerMenu";
import AdditionalBurgerMenu from "./AdditionalBurgerMenu";
import colors from "../../assets/colors/colors";
import { LatoRegular } from "../Text";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { GrClose } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import { openWhatsapp } from "../../tools/tools";
import { contactDecider } from "../../tools/decider";
import { useNavigate } from "react-router";

function MainComponent({
  user = {},
  items = [],
  isCheckout = false,
  animate = () => {},
  openBurger = false,
  setOpenBurger = () => {},
  openCart = false,
  setOpenCart = () => {},
  openWishList = false,
  setOpenWishList = () => {},
  customOpenCart = false,
  openSubBurger = false,
  setOpenSubBurger = () => {},
  setOpenSearch = () => {},
  opacity = 1,
  openSearch = false,
  searchResult = [],
  setHasQuery = () => {},
  showHelp = false,
  setShowHelp = () => {},
  promotion,
}) {
  const { width, height } = useWindowDimensions();
  const { isTablet, isPhone } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const [subBurgerIndex, setSubBurgerIndex] = useState(-1);
  const [hover, setHover] = useState("");

  const burgerArr = [
    { title: "Sale", path: "/products", noChevron: true },
    {
      title: "New Arrivals",
      menu: [
        {
          title: "New Arrivals",
          option: [
            "Editor's Pick",
            // "Black Friday",
            "Vintage",
            "Bags",
            "Shoe",
            "Accessories",
          ],
        },
        {
          title: "Popular Brands",
          option: ["Chanel", "Dior", "Hermes", "Louis Vuitton"],
        },
      ],
    },
    { title: "Journal", externalLink: "https://journal.belleandkate.com" },
    { title: "Designers", path: "/designers"},
    // {
    //   title: "Designers",
    //   menu: [
    //     {
    //       title: "Designers",
    //       option: ["Chanel", "Dior", "Hermes", "Louis Vuitton"],
    //     },
    //     {
    //       title: "Belle&Kate",
    //       option: ["Brands A-Z"],
    //     },
    //   ],
    // },
    {
      title: "Category",
      menu: [{ title: "Category", option: ["Bags", "Shoes", "Accessories"] }],
    },
    {
      title: "Inside Belle&Kate",
      menu: [
        {
          title: "Belle&Kate",
          option: ["About Us", "Customer Care"],
        },
        {
          title: "Sell With Us",
          option: ["Consignment", "Trade In", "Direct Sell"],
        },
        {
          title: "Work With Us",
          option: ["Agent Program", "Clique Program"],
        },
        {
          title: "Pre Order",
          option: ["Request Item"],
        },
        {
          title: "Services",
          option: ["Authentication"],
        },
      ],
    },
    { title: "Contact Us", path: "/contact-us" },
  ];

  const mouseMoveHandler = (event) => {
    if (
      (openSubBurger || (openSearch && !searchResult.length)) &&
      event.clientY > 525
    ) {
      setOpenSubBurger(false);
      setHover(false);
      setSubBurgerIndex(null);
      setOpenSearch(false);
      setHasQuery(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      window.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [openSubBurger, openSearch, searchResult]);

  return (
    <View
      style={{
        zIndex: 10,
        position:
          openCart || openWishList || customOpenCart || openSearch
            ? "absolute"
            : "sticky",
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {Boolean(promotion?.promotional_text) && (
        <TouchableOpacity
          onPress={() => {
            // navigate(`/editorial/campaign/${promotion?.article?.id}`);
            navigate(`/products`)
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.green5B,
            paddingHorizontal: 16,
            paddingVertical: 6,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 12 : 16,
              lineHeight: isPhone ? 16 : 24,
              color: colors.whiteFF,
              textAlign: "center",
            }}
          >
            {promotion?.promotional_text}
          </LatoRegular>
        </TouchableOpacity>
      )}
      <TopNavBar
        animate={animate}
        hover={hover}
        isCheckout={isCheckout}
        items={items}
        openBurger={openBurger}
        openSubBurger={openSubBurger}
        setHover={setHover}
        setOpenBurger={setOpenBurger}
        setOpenCart={setOpenCart}
        setOpenSearch={setOpenSearch}
        setOpenSubBurger={setOpenSubBurger}
        setOpenWishList={setOpenWishList}
        user={user}
      />
      {Boolean(!isTablet) && (
        <BottomNavBar
          burgerArr={burgerArr}
          hover={hover}
          setHover={setHover}
          setOpenSubBurger={setOpenSubBurger}
          setSubBurgerIndex={setSubBurgerIndex}
          subBurgerIndex={subBurgerIndex}
          user={user}
        />
      )}
      {Boolean(showHelp) && (
        <View
          style={{
            position: "sticky",
            top: 0,
            width,
            backgroundColor: colors.green5B,
            padding: 8,
            flexDirection: "row",
            justifyContent: isPhone ? "space-between" : "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          {Boolean(!isPhone) && (
            <FaRegCircleQuestion size={20} color={colors.whiteFF} />
          )}
          <LatoRegular
            style={{
              fontSize: isPhone ? 12 : 16,
              lineHeight: isPhone ? 16 : 20,
              color: colors.whiteFF,
              marginHorizontal: isPhone ? 0 : 8,
              width: isPhone ? 240 : "auto",
            }}
          >
            Didn't find what you're looking for? We'll help source it for you
            {Boolean(!isPhone) && (
              <LatoRegular
                onPress={() => openWhatsapp("", contactDecider())}
                style={{
                  fontSize: 16,
                  lineHeight: 20,
                  color: colors.whiteFF,
                  marginHorizontal: 8,
                  textDecorationLine: "underline",
                }}
              >
                Contact Us
              </LatoRegular>
            )}
          </LatoRegular>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {Boolean(isPhone) && (
              <LatoRegular
                onPress={() => openWhatsapp("", contactDecider())}
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.whiteFF,
                  marginHorizontal: 8,
                  marginRight: 8,
                  textDecorationLine: "underline",
                }}
              >
                Contact Us
              </LatoRegular>
            )}
            <TouchableOpacity onPress={() => setShowHelp(false)}>
              <IoMdClose size={20} color={colors.whiteFF} />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {Boolean(openBurger) && (
        <MainBurgerMenu
          opacity={opacity}
          burgerArr={burgerArr}
          openBurger={openBurger}
          openSubBurger={openSubBurger}
          setOpenBurger={setOpenBurger}
          setOpenSearch={setOpenSearch}
          setOpenSubBurger={setOpenSubBurger}
          setSubBurgerIndex={setSubBurgerIndex}
          subBurgerIndex={subBurgerIndex}
          user={user}
        />
      )}
      {Boolean(openSubBurger && burgerArr[subBurgerIndex]?.menu) && (
        <AdditionalBurgerMenu
          opacity={opacity}
          burgerArr={burgerArr}
          openBurger={openBurger}
          setOpenBurger={setOpenBurger}
          setOpenSubBurger={setOpenSubBurger}
          subBurgerIndex={subBurgerIndex}
          user={user}
          hover={hover}
          setHover={setHover}
          promotion={promotion}
        />
      )}
    </View>
  );
}

export default MainComponent;

export function removePrependedDesignerName(productName, designerName){
  //remove prepended designer name (because it's already displayed in UI)
  if(!productName){
    return ''
  }
  if(!designerName){
    return productName
  }
  let arr = productName.split(designerName + ' ')
  if(arr.length > 2){
    arr.shift()
    return arr.join(designerName + ' ')
  }else if (arr.length === 2){
    return arr[1]
  }else if (arr.length === 1){
    return arr[0]
  }else{
    return productName //impossible path
  }
}
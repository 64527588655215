export const LinksFromCDN = {
  banner1: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/banner-1.webp",
  bannerPhone1: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/banner-phone-1.webp",
  banner2: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/banner-2.webp",
  bannerPhone2: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/banner-phone-2.webp",
  banner3: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/banner-3.webp",
  bannerPhone3: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/banner-phone-3.webp",

  subscribeNewsletter: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/subscribe.png",

  visitUsSection: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/visit-us.webp",
  login: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/login.webp",
  register: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/register.webp",
  
  chanelCoverImage: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/chanel-cover.webp",
  louisVuittonCoverImage: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/louis-vuitton-cover.webp",
  hermesCoverImage: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/hermes-cover.webp",
  diorCoverImage: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/dior-cover.webp",
}
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import LoginScreen from "../screens/LoginScreen";
import ProductCatalogScreen from "../screens/ProductCatalogScreen";
import ProductDetailScreen from "../screens/ProductDetailScreen";
import RegisterScreen from "../screens/RegisterScreen";
import ContactUsScreen from "../screens/ContactUsScreen";
import AboutUsScreen from "../screens/AboutUsScreen";
import FAQScreen from "../screens/FAQScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import TermsConditionsScreen from "../screens/TermsConditionsScreen";
import AccountScreen from "../screens/AccountScreen";
import CheckoutAddressScreen from "../screens/CheckoutAddressScreen";
import CheckoutShipmentScreen from "../screens/CheckoutShipmentScreen";
import CheckoutPaymentScreen from "../screens/CheckoutPaymentScreen";
import CheckoutSuccessScreen from "../screens/CheckoutSuccessScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import PhoneVerificationScreen from "../screens/PhoneVerificationScreen";
import KYCScreen from "../screens/KYCScreen";
import PhoneConsignmentScreen from "../screens/PhoneConsignmentScreen";
import { AuthProvider } from "../contexts/AuthContext";
import { ToasterProvider } from "../contexts/ToastContext";
import AppointmentScreen from "../screens/AppointmentScreen";
import SellWithUsScreen from "../screens/SellWithUsScreen";
import CustomerCareScreen from "../screens/CustomerCareScreen";
import AuthenticationScreen from "../screens/AuthenticationScreen";
import ClassRoomScreen from "../screens/ClassRoomScreen";
import WorkWithUsScreen from "../screens/WorkWithUsScreen";
import EditorialScreen from "../screens/EditorialScreen";
import CrushScreen from "../screens/CrushScreen";
import OpsCreatePriceTagScreen from "../screens/OpsCreatePriceTagScreen";
import RequestFormScreen from "../screens/RequestFormScreen";
import RequestProductScreen from "../screens/RequestProductScreen";
import { CurrencyProvider } from "../contexts/CurrenctContext";
import useGA4 from "../utils/useGA4"
import DesignersScreen from "../screens/DesignersScreen"
import PaymentDoneScreen from "../screens/PaymentDoneScreen";
import CareersScreen from "../screens/CareersScreen";
import CareersDetailScreen from "../screens/CareersDetailScreen";

const routes = [
  {
    path: "/products/designer/:id",
    Component: ProductCatalogScreen,
  },
  {
    path: "/products/category/:id",
    Component: ProductCatalogScreen,
  },
  {
    path: "/products/:id",
    Component: ProductDetailScreen,
  },
  {
    path: "/deals/:id",
    Component: CheckoutSuccessScreen,
  },
  {
    path: "/checkout/payment",
    Component: CheckoutPaymentScreen,
  },
  {
    path: "/checkout/shipment",
    Component: CheckoutShipmentScreen,
  },
  {
    path: "/checkout/address",
    Component: CheckoutAddressScreen,
  },
  {
    path: "/products",
    Component: ProductCatalogScreen,
  },
  {
    path: "/login",
    Component: LoginScreen,
  },
  {
    path: "/forgot-password",
    Component: ForgotPasswordScreen,
  },
  {
    path: "/register",
    Component: RegisterScreen,
  },
  {
    path: "/book-appointment",
    Component: AppointmentScreen,
  },
  {
    path: "/about",
    Component: AboutUsScreen,
  },
  {
    path: "/contact-us",
    Component: ContactUsScreen,
  },
  {
    path: "/payment-done",
    Component: PaymentDoneScreen,
  },
  {
    path: "/faq",
    Component: FAQScreen,
  },
  {
    path: "/privacy-policy",
    Component: PrivacyPolicyScreen,
  },
  {
    path: "/terms-conditions",
    Component: TermsConditionsScreen,
  },
  {
    path: "/account/:segment/:additional/:id",
    Component: AccountScreen,
  },
  {
    path: "/account/:segment/:additional",
    Component: AccountScreen,
  },
  {
    path: "/account/:segment",
    Component: AccountScreen,
  },
  {
    path: "/account",
    Component: AccountScreen,
  },
  {
    path: "/request-product",
    Component: RequestProductScreen,
  },
  {
    path: "/designers",
    Component: DesignersScreen
  },
  {
    path: "/request-item",
    Component: RequestFormScreen,
  },
  {
    path: "/verification/input-phone",
    Component: PhoneConsignmentScreen,
  },
  {
    path: "/verification/input-otp",
    Component: PhoneVerificationScreen,
  },
  {
    path: "/verification/upload-id",
    Component: KYCScreen,
  },
  {
    path: "/sell-with-us/:category",
    Component: SellWithUsScreen,
  },
  {
    path: "/customer-care/:id",
    Component: CustomerCareScreen,
  },
  {
    path: "/customer-care",
    Component: CustomerCareScreen,
  },
  {
    path: "/authentication",
    Component: AuthenticationScreen,
  },
  {
    path: "/work-with-us",
    Component: WorkWithUsScreen,
  },
  {
    path: "/careers",
    Component: CareersScreen,
  },
  {
    path: "/careers/:id",
    Component: CareersDetailScreen,
  },
  {
    path: "/ops/create-price-tag",
    Component: OpsCreatePriceTagScreen,
  },
  {
    path: "/editorial/:category/:id",
    Component: ClassRoomScreen,
  },
  {
    path: "/editorial/crushes/:id",
    Component: CrushScreen,
  },
  {
    path: "/editorial",
    Component: EditorialScreen,
  },
  {
    path: "/",
    Component: HomeScreen,
  },
];

const WithGA4 = ({ Component, ...rest }) => {
  useGA4();
  return <Component {...rest} />;
};

export default function RootNavigation() {
  return (
    <BrowserRouter>
      <ToasterProvider>
        <AuthProvider>
          <CurrencyProvider>
          <Routes>
            {routes.map(({ Component, path }, index) => (
              <Route 
                path={path} 
                element={<WithGA4 Component={Component} />} 
                key={index} 
              />
            ))}
          </Routes>
          </CurrencyProvider>
        </AuthProvider>
      </ToasterProvider>
    </BrowserRouter>
  );
}

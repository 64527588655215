import { headerCase } from "change-case";
import React, { useContext, useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import colors from "../../assets/colors/colors";
import { IMAGE_ROOT_URL } from "../../constants/api";
import {
  stateIndicatorDecider,
  stateMaskingDecider,
} from "../../tools/decider";
import { formatCurrency, shortDateFormatter } from "../../tools/formatter";
import { getDeviceType } from "../../tools/Interface";
import { api } from "../../utils/api";
import { LatoBold, LatoRegular } from "../Text";
import Buttons from "../Buttons";
import ToasterContext from "../../contexts/ToastContext";
import { removePrependedDesignerName } from "../../utils/productutil";
import { ShippingMethodId } from "../../constants/shippingMethods";

export default function MyOrder({
  setDetailID = () => {},
  setOpenTracker = () => {},
  setOpenRefund = () => {},
}) {
  const { segment } = useParams();
  const { state: payload } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useContext(ToasterContext);

  const [itemArr, setItemArr] = useState([]);
  const [loading, setLoading] = useState(true);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const getDeal = async () => {
    try {
      setLoading(true);
      const res = await api.get("api/deals");
      const data = res?.data?.data;

      setItemArr(data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDeal();
  }, []);
  return loading ? (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: isPhone ? width : isTablet ? width - 300 : width - 450,
        height: height - 500,
      }}
    >
      <Image
        source={require("../../assets/images/spinning-loading.gif")}
        // alt={item?.product?.name}
        style={{
          alignSelf: "center",
          width: 200,
          height: 200,
        }}
        defaultSource={require("../../assets/images/spinning-loading.gif")}
        resizeMode={"contain"}
      />
    </View>
  ) : itemArr.length ? (
    <View>
      {itemArr.map((item, index) => {
        const { cart = {}, state, created_at, discounted_subtotal, id } = item;
        const { products = [] } = cart;
        const shownProduct = products[0];
        const { name = "", summary_index = {} } = shownProduct || {};
        const { designer, photos = [], direct_photos = [] } = summary_index;
        return (
          <View
            key={index}
            style={{
              flexDirection: isPhone ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 32,
              paddingBottom: 32,
              borderBottomColor: colors.greyE6,
              borderBottomWidth: 1,
              width: isPhone ? width : "100%",
              marginHorizontal: isPhone ? -16 : 0,
            }}
          >
            {Boolean(isPhone) && (
              <View style={{ width: "100vw", paddingHorizontal: 16 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "flex-end",
                    marginBottom: 8,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: stateIndicatorDecider(state),
                      width: 8,
                      height: 8,
                      borderRadius: "100%",
                    }}
                  />
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      textAlign: "right",
                      marginLeft: 6,
                    }}
                  >
                    {stateMaskingDecider(state)}
                  </LatoBold>
                </View>
                <LatoRegular
                  style={{
                    fontSize: 16,
                    lineHeight: 24,
                    color: colors.grey33,
                    marginBottom: 8,
                    // textAlign: "right",
                  }}
                >
                  {`Ordered on ${shortDateFormatter(created_at)}`}
                </LatoRegular>
              </View>
            )}
            <View
              style={{
                flexDirection: "row",
                alignItems: isPhone ? "flex-start" : "center",
                width: isPhone
                  ? width - 32
                  : isTablet
                  ? width < 900
                    ? 225
                    : 350
                  : width < 1200
                  ? 450
                  : 500,
                borderWidth: isPhone ? 1 : 0,
                borderRadius: isPhone ? 8 : 0,
                borderColor: colors.greyE6,
                paddingVertical: 16,
                paddingHorizontal: 8,
              }}
            >
              <img
                src={`${direct_photos[0]}`}
                alt={`${name}`}
                width={isPhone ? 90 : 100}
                height={isPhone ? (2 / 2) * 90 : 100}
                style={{
                  // alignSelf: "center",
                  marginBottom: isPhone ? 0 : 16,
                  marginRight: isPhone ? 16 : 32,
                  objectFit: "contain",
                }}
              />
              <View style={{ width: "65%" }}>
                <LatoBold
                  style={{
                    fontSize: isPhone ? 12 : 14,
                    lineHeight: 20,
                    color: colors.grey33,
                    marginBottom: 8,
                  }}
                >
                  {designer}
                </LatoBold>
                <LatoRegular
                  style={{
                    fontSize: isPhone ? 14 : 16,
                    lineHeight: 24,
                    color: colors.grey33,
                    marginBottom: 8,
                  }}
                  numberOfLines={2}
                >
                  {removePrependedDesignerName(name, designer)}
                </LatoRegular>
                {/* {Boolean(!isPhone) && ( */}
                <LatoBold
                  style={{
                    fontSize: isPhone ? 14 : 16,
                    lineHeight: 24,
                    color: colors.grey33,
                    marginBottom: isPhone ? 0 : 16,
                  }}
                >{`IDR${formatCurrency(discounted_subtotal)}`}</LatoBold>
                {/* )} */}
              </View>
            </View>
            {Boolean(!isPhone) && (
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: 8,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: stateIndicatorDecider(state),
                      width: 8,
                      height: 8,
                      borderRadius: "100%",
                    }}
                  />
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      textAlign: "right",
                      marginLeft: 6,
                    }}
                  >
                    {stateMaskingDecider(state)}
                  </LatoBold>
                </View>
                <LatoRegular
                  style={{
                    fontSize: 16,
                    lineHeight: 24,
                    color: colors.grey33,
                    marginBottom: 8,
                    textAlign: "right",
                  }}
                >
                  {`Ordered on ${shortDateFormatter(created_at)}`}
                </LatoRegular>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Buttons
                    onPress={() => {
                      setDetailID(id);

                      navigate(`/account/${segment}/${id}`, {
                        state: { idx: payload?.idx, index: payload?.index },
                      });
                    }}
                    label="Order Details"
                    width={115}
                    containerStyle={{
                      marginRight:
                        state === "on_delivery" &&
                        cart?.shipping_rate_id !== ShippingMethodId.privateCourier
                          ? 12
                          : 0,
                    }}
                  />
                  {Boolean(
                    state === "on_delivery" && cart?.shipping_rate_id !== ShippingMethodId.privateCourier
                  ) && (
                    <Buttons
                      onPress={() => {
                        setDetailID(id);
                        setOpenTracker(true);
                      }}
                      label={"Track Order"}
                      width={120}
                      isBlack
                    />
                  )}
                </View>
              </View>
            )}
            {Boolean(isPhone) && (
              <View
                style={{
                  marginTop: 24,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: width - 40,
                }}
              >
                <Buttons
                  onPress={() => {
                    setDetailID(id);

                    navigate(`/account/${segment}/${id}`, {
                      state: { idx: payload?.idx, index: payload?.index },
                    });
                  }}
                  width={state === "on_delivery" ? "45%" : "100%"}
                  label="Order Details"
                />
                {Boolean(state === "on_delivery") && (
                  <Buttons
                    onPress={() => {
                      setDetailID(id);
                      setOpenTracker(true);
                    }}
                    label={"Track Order"}
                    width={"45%"}
                    isBlack
                  />
                )}
              </View>
            )}
          </View>
        );
      })}
    </View>
  ) : (
    <View
      style={{
        alignItems: "center",
        marginTop: 24,
        marginBottom: 0.6 * height,
      }}
    >
      <LatoRegular style={{ fontSize: 20, lineHeight: 28, marginBottom: 8 }}>
        You have not placed any orders yet.
      </LatoRegular>
      <TouchableOpacity
        onPress={() => {}}
        style={{
          width: 200,
          paddingVertical: 10,
          paddingHorizontal: 12,
          backgroundColor: colors.grey33,
          borderColor: colors.grey33,
          borderWidth: 1,
          borderRadius: 6,
          marginVertical: 24,
        }}
      >
        <Link
          to="/products"
          style={{
            fontSize: 14,
            color: colors.whiteFF,
            fontFamily: "Lato-Regular",
            textDecorationLine: "none",
            textAlign: "center",
          }}
        >
          Shop now
        </Link>
      </TouchableOpacity>
    </View>
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  View,
  SectionList,
  TouchableOpacity,
  Image,
  Animated,
  useWindowDimensions,
} from "react-native";
import { BsChevronDown, BsChevronUp, BsSquare } from "react-icons/bs";
import { GrSquare, GrClose } from "react-icons/gr";
import colors from "../../assets/colors/colors";
import { LatoBold, LatoRegular } from "../Text";
import { api } from "../../utils/api";
import { getDeviceType } from "../../tools/Interface";
import ToasterContext from "../../contexts/ToastContext";
import Buttons from "../Buttons";

export default function FilterProduct({
  selected = [],
  setSelected = () => {},
  shownFilter = false,
  setShownFilter = () => {},
  animatedWidth = 0,
  opacity = 1,
}) {
  const [pressed, setPressed] = useState(false);
  const [color, setColor] = useState([]);
  const [condition, setCondition] = useState([]);
  const [designer, setDesigner] = useState([]);
  const [category, setCategory] = useState([]);
  const [type, setType] = useState([]);
  const [showFilter, setShowFilter] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [loading, setLoading] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });
  const { showToast } = useContext(ToasterContext);

  const getFilterSelection = async () => {
    try {
      setLoading(true);
      const resDesigner = await api.get("/api/designers");
      const resCategory = await api.get("/api/categories");
      const resCondition = await api.get("/api/conditions");
      const resColor = await api.get("/api/colours");
      const resType = await api.get("/api/styles");

      const dataDesigner = resDesigner.data.data;
      const dataCategory = resCategory.data.data;
      const dataCondition = resCondition.data.data;
      const dataColor = resColor.data.data;
      const dataType = resType.data.data;

      setCondition(dataCondition);
      setColor(dataColor);
      setCategory(dataCategory);
      setDesigner(dataDesigner);
      setType(dataType);
      setShowFilter([true, true, true, true, true]);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFilterSelection();
  }, []);

  const filterArr = [
    {
      title: "Category",
      data: category,
      index: 1,
    },
    {
      title: "Type",
      data: type,
      index: 4,
    },
    {
      title: "Conditions",
      data: condition,
      index: 2,
    },
    {
      title: "Colour",
      data: color,
      index: 3,
    },
  ];

  const selectedNameArr = selected.map(({ name }) => name);

  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: "100vh",
        zIndex: 5,
        backgroundColor: colors.transparentBlack00,
        width: width,
      }}
    >
      <Animated.View
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          height: "100vh",
          zIndex: 5,
          backgroundColor: colors.whiteFF,
          width: animatedWidth,
          opacity,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginHorizontal: 16,
            paddingVertical: 24,
            paddingTop: isPhone ? 24 : 48,
            borderBottomWidth: 1,
            borderBottomColor: colors.greyE6,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <LatoRegular
              style={{ fontSize: 16, lineHeight: 24, marginLeft: 10 }}
            >
              FILTER
            </LatoRegular>
            {Boolean(selected.length) && (
              <>
                <View
                  style={{
                    height: 5,
                    width: 5,
                    borderRadius: "100%",
                    backgroundColor: colors.grey33,
                    marginHorizontal: 10,
                  }}
                />
                <LatoRegular style={{ fontSize: 16, lineHeight: 24 }}>
                  {`${selected.length} Selected`}
                </LatoRegular>
              </>
            )}
          </View>
          <TouchableOpacity
            onPress={() => {
              setShownFilter(!shownFilter);
            }}
          >
            {<GrClose size={16} />}
          </TouchableOpacity>
        </View>
        <SectionList
          sections={filterArr}
          keyExtractor={(_, index) => {
            return _ + index;
          }}
          renderItem={({ item, section }) => {
            return showFilter[section.index] ? (
              <TouchableOpacity
                onPress={() => {
                  if (selectedNameArr.includes(item.name)) {
                    const filterredSelected = selected.filter((i) => {
                      if (i.name !== item.name) return i;
                      return null;
                    });
                    setSelected(filterredSelected);
                    return;
                  }
                  setSelected([
                    ...selected,
                    { ...item, section: section?.title },
                  ]);
                  setPressed(!pressed);
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginHorizontal: 16,
                  borderBottomColor: colors.greyE6,
                }}
              >
                {section.title === "Colour" ? (
                  item.name === "Multicolour" ? null : (
                    <View
                      style={{
                        borderWidth: selectedNameArr.includes(item.name)
                          ? 1
                          : 0,
                        borderColor: selectedNameArr.includes(item.name)
                          ? colors.black00
                          : "transparent",
                        padding: selectedNameArr.includes(item.name) ? 7 : 0,
                        borderRadius: selectedNameArr.includes(item.name)
                          ? "100%"
                          : 0,
                        marginLeft: selectedNameArr.includes(item.name)
                          ? -6
                          : 0,
                      }}
                    >
                      <View
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "100%",
                          backgroundColor:
                            item.hex_code !== "multicolour"
                              ? item.hex_code
                              : "black",
                          shadowColor: colors.black00,
                          shadowRadius: 2,
                          shadowOpacity: 0.75,
                          shadowOffset: { height: 0, width: 0 },
                          marginLeft: selectedNameArr.includes(item.name)
                            ? 0
                            : 2,
                        }}
                      />
                    </View>
                  )
                ) : selectedNameArr.includes(item.name) ? (
                  <GrSquare color={colors.black00} size={20} />
                ) : (
                  <BsSquare color={colors.grey90} size={20} />
                )}
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    marginLeft:
                      selectedNameArr.includes(item.name) &&
                      section.title === "Colour"
                        ? 8
                        : 16,
                  }}
                >
                  {typeof item === "object"
                    ? item.name === "Multicolour"
                      ? null
                      : item.name
                    : item}
                </LatoRegular>
              </TouchableOpacity>
            ) : null;
          }}
          renderSectionHeader={({ section }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  const newArr = !isPhone
                    ? [true, true, true, true]
                    : showFilter.map((item, idx) => {
                        if (section.index === idx) {
                          return !item;
                        } else {
                          return item;
                        }
                      });
                  setShowFilter(newArr);
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginHorizontal: 16,
                  paddingVertical: isPhone ? 28 : 0,
                  paddingTop: 28,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: isPhone ? 1 : 0,
                  borderTopColor: colors.greyE6,
                  borderTopWidth: isPhone ? 1 : 0,
                }}
              >
                {Boolean(isPhone) ? (
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                    }}
                  >
                    {section.title}
                  </LatoRegular>
                ) : (
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                    }}
                  >
                    {section.title}
                  </LatoBold>
                )}
                {loading ? (
                  <Image
                    source={require("../../assets/images/spinning-loading.gif")}
                    style={{
                      height: 20,
                      width: 20,
                    }}
                    defaultSource={require("../../assets/images/spinning-loading.gif")}
                  />
                ) : isPhone ? (
                  showFilter[section.index] ? (
                    <BsChevronUp size={14} />
                  ) : (
                    <BsChevronDown size={14} />
                  )
                ) : null}
              </TouchableOpacity>
            );
          }}
          SectionSeparatorComponent={({ section }) => {
            return showFilter[section.index] ? (
              <View
                style={{
                  marginTop: 12,
                }}
              />
            ) : null;
          }}
          ItemSeparatorComponent={({ section }) => {
            return showFilter[section.index] ? (
              <View style={{ marginTop: 12 }} />
            ) : null;
          }}
          ListFooterComponent={() =>
            loading ? null : (
              <Buttons
                onPress={() => setShownFilter(false)}
                label={"Apply"}
                isBlack
                containerStyle={{ marginBottom: 160 }}
              />
            )
          }
          ListFooterComponentStyle={{ marginHorizontal: 24 }}
        />
      </Animated.View>
    </View>
  );
}

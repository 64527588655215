import { Image, useWindowDimensions, View } from "react-native-web";
import { LatoRegular } from "../Text";
import { getDeviceType } from "../../tools/Interface";
import colors from "../../assets/colors/colors";

function Review() {
  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });
  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - 2 * gap;

  const reviews = [
    {
      title: "Fitria Yusuf - Public Figure & Entrepreneur",
      body: "Belle&Kate found exactly what I needed and they were super professional and reliable!",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_Fitria-Yusuf.webp",
    },
    {
      title: "Hanifa Ambadar - CEO of Female Daily",
      body: "Super seamless online shopping, and their boutique has friendly advisors and a stunning collection of Hermès bags!",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_Hanifa-Ambaddar.webp",
    },
    {
      title: "Nagita Slavina - Public Figure & Entrepreneur",
      body: "I love how fast, helpful, and informative Belle&Kate’s team is. Just share your wishlist, and they’ve got you covered!",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_NAGITA-SLAVINA.webp",
    },
    {
      title: "Ivan Gunawan - Fashion Designer",
      body: "Shopping with Belle&Kate is so easy! The team’s super friendly, quick to respond, and they handle my wishlist seamlessly.",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_Ivan-Gunawan.webp",
    },
    {
      title: "Donna Harun - Public Figure & Actress",
      body: "Whenever I’m hunting for Hermès or other luxury bags, I always trust Belle&Kate for their curated, authentic pieces and top-notch experience!",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_Donna-Harun.webp",
    },
    {
      title: "Nikita Willy - Actress",
      body: "My shopping experience at Belle&Kate was effortless with a quick delivery process. I love their gorgeous display!",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_Nikita-Willy.webp",
    },
    {
      title: "Renita Sari - Entrepreneur",
      body: "I discovered Belle&Kate on social media, and I love how they educate us on investing in Hermès bags while providing excellent service. Definitely my go-to!",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_Renita-Sari.webp",
    },
    {
      title: "Ayudia Bing Slamet - Public Figure & Entrepreneur",
      body: "I discovered Belle&Kate on Instagram and loved their friendly, client-focused service, plus the convenience of trading in my bags.",
      imageUrl: "https://belleandkate-production.sgp1.cdn.digitaloceanspaces.com/bnk-static/PHOTO_Ayudia-Bing-Slamet.webp",
    },
  ]

  return (
    <View style={{ marginBottom: 80, alignItems: "center" }}> 
      <View
        style={{
        paddingTop: 48,
        paddingBottom: 48,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: gap,
        }}
      >
        <LatoRegular
          style={{
            fontSize: isPhone ? 16 : 24,
            lineHeight: isPhone ? 24 : 32,
            color: colors.grey6C,
            backgroundColor: colors.whiteFF,
            paddingHorizontal: isPhone ? 8 : 56,
          }}
        >
          WHAT THEY SAID
        </LatoRegular>
        <View style={{
          position: "absolute",
          zIndex: -1,
          width: containerWidth,
          height: 1,
          backgroundColor: colors.greyE6,
          // left: gap,
        }}/>
      </View>
      <View style={{
        width: containerWidth,
        flex: 1,
        flexDirection: "row",
        overflowX: "scroll",
        padding: 3,
      }}>
        {reviews.map(o => {
          return (
            <div key={o.title} className="min-w-[80vw] md:min-w-[60vw] lg:min-w-[40vw] xl:min-w-[30vw] m-3 rounded-lg bg-white shadow-md">
              <div className="px-4 py-5 sm:p-6">
                <div className="flex">
                  <div className="mr-4 shrink-0">
                    <Image source={o.imageUrl} alt={o.title} style={{width: 60, height: 60}}/>
                  </div>
                  <div>
                    <h4 className="text-sm font-bold">{o.title}</h4>
                    <p className="text-sm mt-1">{o.body}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </View>
    </View>
  );
}

export default Review;

import _ from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { api, useMutation } from "../utils/api";
import {
  asyncGetUser,
  asyncRemoveUser,
  asyncSetUser,
  asyncSetHasLoggedIn,
  asyncGetCredentials,
  asyncSetCredentials,
  asyncRemoveCredentials,
} from "../tools/asyncStorages";
import {
  eventsTracker,
  loginEvents,
  logoutEvents,
} from "../utils/eventsFunctions";
import { useNavigate } from "react-router";
import ToasterContext from "./ToastContext";
// import ToasterContext from "./ToastContext";
// import { navigate } from "../utils/navigationService";

const userFormatter = (user) => {
  // customize user props
  // const globalRoles = get(user, 'roles', []).filter(role => {
  //   const roleName = get(role, 'name', '');
  //   const beOnlyRoles = ['admin', 'checker', 'maker'];
  //   return !beOnlyRoles.includes(roleName);
  // });

  // const role = globalRoles[0]?.name || '';
  // const isPunyUser = ['partner_viewer', 'partner_card_user'].includes(role);
  // const isAdmin = !isPunyUser;

  // customize user props
  return {
    ...user,
  };
};

// store user states here
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [credentials, setCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const { showToast } = useContext(ToasterContext);

  const navigate = useNavigate();

  const setUserHook = async (user) => {
    setUser(user);
    await asyncSetUser(user);
  };

  const unauthorize = async () => {
    await asyncRemoveUser();
    setUser({});
    await asyncRemoveCredentials();
    setCredentials({});
    logoutEvents();
    // navigate("/");
  };

  const authorize = async () => {
    try {
      const userCredentials = await asyncGetCredentials();
      setCredentials(userCredentials);
      // navigate("/");
    } catch (err) {
      console.log("err:", err);
      // showToast(err);
    }
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/api/users/my_info");
      const user = userFormatter(data?.user || {});
      await setUserHook(user);
      return user;
    } catch (err) {
      if (err.status === 401) {
        await unauthorize();
        return;
      }
      // showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getUserAuthData = async () => {
      const userData = await asyncGetUser();
      setUser(userData);
      const userCredentials = await asyncGetCredentials();
      setCredentials(userCredentials);
      await getUserData();
    };
    getUserAuthData();
  }, []);

  const { mutation: updateUser, loading: loadingUpdateUser } = useMutation({
    url: "/api/users",
    method: "put",
    afterSuccess: async (response, payload) => {
      const userData = _.get(response, "data.user");
      setUserHook(userData);
    },
  });

  const isAuthorized = !_.isEmpty(credentials);

  const authChecker = (navigation) => {
    if (isAuthorized) return;
    // navigation.replace("AuthStack");
  };

  const values = {
    user,
    loading,

    authorize,
    isAuthorized,
    authChecker,
    unauthorize,

    updateUser,
    loadingUpdateUser,
    setUserHook,
    refetch: getUserData,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContext;

export const useAuth = () => {
  const {
    user,
    loading,
    authorize,
    isAuthorized,
    authChecker,
    unauthorize,
    updateUser,
    loadingUpdateUser,
    setUserHook,
    refetch,
  } = useContext(AuthContext) || {};

  return {
    user,
    loading,
    authorize,
    isAuthorized,
    authChecker,
    unauthorize,
    updateUser,
    loadingUpdateUser,
    setUserHook,
    refetch,
  };
};

export const useLogin = ({ navigation }) => {
  const { authorize } = useAuth();

  const { mutation: login, loading } = useMutation({
    url: "/api/auth/sign_in",
    method: "post",
    afterSuccess: async (response) => {
      const userData = _.get(response, "data.data");

      // TODO handle for logout https://devise-token-auth.gitbook.io/devise-token-auth/usage

      await authorize();
    },
  });
  return { login, loading };
};

export const useLogout = () => {
  const { unauthorize } = useAuth();

  const { mutation: logout, loading } = useMutation({
    url: "/api/auth/sign_out",
    method: "delete",
    afterSuccess: async (response) => {
      await unauthorize();
    },
  });
  return { logout, loading };
};

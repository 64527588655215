import React, { useContext, useState } from "react";
import {
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { useNavigate } from "react-router";
import colors from "../assets/colors/colors";
import Shimmer from "./Shimmer";
import ImageWithLoader from "./ImageWithLoader";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "./Text";
import { formatCurrency } from "../tools/formatter";
import ToasterContext from "../contexts/ToastContext";
import { api } from "../utils/api";
import { MyInput } from "./Inputs";
import Buttons from "./Buttons";
import { upperCase } from "lodash";
import { removePrependedDesignerName } from "../utils/productutil";

function CartProduct({
  isCheckout,
  items = [],
  loading = false,
  getData = async () => {},
  getItem = async () => {},
  getItems = async () => {},
}) {
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();
  const { isPhone } = getDeviceType({ width, height });

  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const [value, setValue] = useState("");
  const [loadingApply, setLoadingApply] = useState(false);
  const [error, setError] = useState("");

  const handleRemove = async (id) => {
    try {
      await api.delete(`api/carts/${id}`);

      showSuccessToast("Item removed from cart");

      await getItems();
      await getData();
      await getItem();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  const handleMove = async (id) => {
    try {
      await api.delete(`api/carts/${id}`);
      showSuccessToast("Item removed from cart");

      await api.post("api/wishlist_items", { product_id: id });
      showSuccessToast("Item added to wishlist");

      await getItems();
      await getData();
      await getItem();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  const handleApply = async () => {
    try {
      setLoadingApply(true);
      await api.post("api/carts/input_voucher", {
        voucher_code: value.replaceAll(" ", ""),
      });
      showSuccessToast("Voucher code has been applied");

      await getItems();
      await getData();
      await getItem();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
      setError(err.data.message || "");
    } finally {
      setValue("");
      setLoadingApply(false);
    }
  };

  return (
    <View>
      <ScrollView
        style={{ height: isCheckout ? "auto" : height - 225 }}
        showsVerticalScrollIndicator={false}
      >
        {items.map((item, idx) => {
          const {
            summary_index,
            name,
            selling_price,
            id,
            inventory,
            default_price,
          } = item;

          return (
            <TouchableOpacity
              disabled={isCheckout}
              onPress={() => {
                navigate(`/products/${inventory?.id}`, {
                  state: { index: idx },
                });
              }}
              key={idx}
              style={{
                marginTop: 32,
                paddingBottom: 32,
                borderBottomColor:
                  isCheckout && items.length - 1 === idx
                    ? "transparent"
                    : colors.greyE6,
                borderBottomWidth: 1,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: isCheckout ? "space-between" : "flex-start",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: isCheckout ? (isCheckout / 3) * 2 - 24 : "100%",
                  }}
                >
                  <View
                    style={{
                      borderWidth: isCheckout && !loading ? 1 : 0,
                      padding: 8,
                      borderRadius: isCheckout && !loading ? 8 : 0,
                      justifyContent: "center",
                      marginRight: isCheckout && !loading ? 16 : 0,
                      borderColor:
                        isCheckout && !loading ? colors.greyE6 : colors.whiteFF,
                    }}
                  >
                    {loading ? (
                      <Shimmer
                        autoRun={true}
                        visible={false}
                        width={isCheckout ? 64 : 144}
                        height={isCheckout ? 64 : 120}
                        hasBorder
                      />
                    ) : summary_index?.direct_photos.length ? (
                      <ImageWithLoader
                        source={{
                          uri: `${summary_index?.direct_photos[0]}`,
                        }}
                        alt={name}
                        containerStyle={{
                          width: isCheckout ? 64 : 120,
                          height: isCheckout ? 64 : 120,
                          marginBottom: isCheckout ? 0 : 16,
                          marginRight: isCheckout ? 0 : 16,
                        }}
                        defaultStyle={{
                          width: 48,
                          height: isCheckout ? 64 : 120,
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        resizeMode={"contain"}
                        woMask
                      />
                    ) : null}
                  </View>
                  <View>
                    {loading ? (
                      <View>
                        <Shimmer
                          autoRun={true}
                          visible={false}
                          width={isCheckout ? isCheckout / 4 : "15vw"}
                          height={20}
                          hasBorder
                          style={{ marginBottom: 8 }}
                        />
                        <Shimmer
                          autoRun={true}
                          visible={false}
                          width={isCheckout ? isCheckout / 4 : "15vw"}
                          height={isCheckout ? 16 : 24}
                          hasBorder
                          style={{ marginBottom: 8 }}
                        />
                      </View>
                    ) : (
                      <View
                        style={{
                          width: isCheckout
                            ? isCheckout / 4
                            : isPhone
                            ? width - 200
                            : 250,
                          flexWrap: "wrap",
                        }}
                      >
                        <LatoBold
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey33,
                            marginBottom: 8,
                          }}
                        >
                          {summary_index?.designer}
                        </LatoBold>
                        <LatoRegular
                          style={{
                            fontSize: isCheckout ? 12 : 14,
                            lineHeight: isCheckout ? 16 : 20,
                            color: colors.grey33,
                            marginBottom: 8,
                          }}
                          numberOfLines={2}
                        >
                          {removePrependedDesignerName(name, summary_index?.designer)}
                        </LatoRegular>
                      </View>
                    )}
                    {loading && !isCheckout ? (
                      <Shimmer
                        autoRun={true}
                        visible={false}
                        width={"15vw"}
                        height={24}
                        hasBorder
                      />
                    ) : (
                      Boolean(!isCheckout) && (
                        <View>
                          {Boolean(selling_price !== default_price) && (
                            <LatoBold
                              style={{
                                fontSize: 14,
                                lineHeight: 20,
                                color: colors.redAD,
                              }}
                            >
                              {`Save ${(
                                ((default_price - selling_price) /
                                  default_price) *
                                100
                              ).toFixed(0)}%`}
                            </LatoBold>
                          )}
                          <View
                            style={{
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: isPhone ? width - 200 : "15vw",
                              marginBottom: 16,
                            }}
                          >
                            <LatoBold
                              style={{
                                fontSize: 14,
                                lineHeight: 20,
                                color:
                                  selling_price !== default_price
                                    ? colors.grey6C
                                    : colors.grey33,
                                textDecorationLine:
                                  selling_price !== default_price
                                    ? "line-through"
                                    : "none",
                              }}
                            >{`IDR ${formatCurrency(default_price)}`}</LatoBold>
                            <LatoRegular style={{ textDecorationLine: "none" }}>
                              {"  "}
                            </LatoRegular>
                            {Boolean(selling_price !== default_price) && (
                              <LatoBold
                                style={{
                                  fontSize: 14,
                                  lineHeight: 20,
                                  color: colors.grey33,
                                }}
                              >
                                {`IDR ${formatCurrency(selling_price)}`}
                              </LatoBold>
                            )}
                          </View>
                        </View>
                      )
                    )}
                    {Boolean(!isCheckout && !loading) && (
                      <View
                        style={{
                          flexDirection:
                            (isPhone && width > 545) || !isPhone
                              ? "row"
                              : "column",
                          alignItems:
                            (isPhone && width > 545) || !isPhone
                              ? "center"
                              : "flex-start",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            handleRemove(id);
                          }}
                          style={{
                            marginRight: 12,
                            borderBottomColor: colors.grey6C,
                            borderBottomWidth: 1,
                            marginBottom: 8,
                          }}
                        >
                          <LatoRegular
                            style={{
                              fontSize: 14,
                              lineHeight: 20,
                              color: colors.grey6C,
                            }}
                          >
                            Remove from cart
                          </LatoRegular>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            handleMove(id);
                          }}
                          style={{
                            borderBottomColor: colors.grey6C,
                            borderBottomWidth: 1,
                            marginBottom: 8,
                          }}
                        >
                          <LatoRegular
                            style={{
                              fontSize: 14,
                              lineHeight: 20,
                              color: colors.grey6C,
                            }}
                          >
                            Move to wishlist
                          </LatoRegular>
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                </View>
                {loading && isCheckout ? (
                  <Shimmer
                    autoRun={true}
                    visible={false}
                    width={isCheckout / 3 - 24}
                    height={20}
                    hasBorder
                  />
                ) : (
                  Boolean(isCheckout) && (
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginBottom: 16,
                      }}
                    >{`IDR ${formatCurrency(selling_price)}`}</LatoBold>
                  )
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
      {Boolean(isCheckout) && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: isCheckout ? isCheckout : width,
            borderBottomWidth: 1,
            borderBottomColor: colors.greyE6,
          }}
        >
          <MyInput
            handleChange={(val) => {
              setValue(upperCase(val.replaceAll(" ", "")));
              setError("");
            }}
            error={error}
            noLabel
            placeholder={"Enter Promo Code"}
            value={value}
            customWidth={(isCheckout ? isCheckout : width) - 80}
            bordered
            color={value ? colors.grey33 : colors.greyBB}
            inline
            checkByName={false}
            textStyle={{ textTransform: value ? "uppercase" : "none" }}
            autoClear
          />
          <Buttons
            loading={loadingApply}
            disabled={!value}
            onPress={handleApply}
            isBlack
            label="Apply"
            width={70}
            borderRadius={2}
          />
        </View>
      )}
    </View>
  );
}

export default CartProduct;

import { useAuth } from "../contexts/AuthContext"
import { useNavigate } from "react-router-dom";
import { range } from "lodash";
import { useState } from "react";
import { ROOT_URL } from "../constants/api";

export default function OpsCreatePriceTagScreen() {
  const itemsPerPage = 12
  const { user } = useAuth()
  const ops_role = user?.detail?.ops_role
  const navigate = useNavigate();
  const [searchTermList, setSearchTermList] = useState(new Array(itemsPerPage).fill(""))
  const [items, setItems] = useState(Array(itemsPerPage))
  
  return (
    <div className="w-full flex justify-around h-[100vh] overflow-scroll print:overflow-hidden">
      <div className="print:hidden max-w-[50%]">
        <h1 className="text-2xl font-bold text-center">Product Price Tag</h1>
        <ul className="mt-1 list-disc ml-6">
          <li>Mohon isi sku dan klik Add. Meng-klik Remove akan menghilangkan data untuk satu cell tersebut.</li>
          <li>Untuk print, silahkan klik Ctrl+P (atau Cmd+P di Mac), lalu print di kertas A4.</li>
          <li>Me-refresh halaman akan menghilangkan seluruh data dibawah ini.</li>
          <li>Untuk menambahkan/mengganti gambar, silahkan klik Edit Product, scroll ke paling bawah di halaman yang muncul, tambahkan gambar di kolom Foto kelengkapan, lalu klik Save Product di bagian paling atas halaman.</li>
          <li>Untuk melihat gambar yang baru di-upload, silahkan klik Reload dan tunggu beberapa saat.</li>
        </ul>
        <div className="grid grid-cols-3 grid-rows-3">
          {
            range(itemsPerPage).map(function(i){
              return (
                <div key={i} className="border border-black m-2 p-2 text-center flex flex-col">
                  <input
                    className="border border-gray-300"
                    placeholder="Input SKU disini: BKR-HR01..."
                    value={searchTermList[i]}
                    onChange={function(e){
                      setSearchTermList(function(searchTermList){
                        searchTermList[i] = e.target.value
                        return [...searchTermList]
                      })}
                    }
                  />
                  <button
                    className="hover:bg-gray-200"
                    onClick={async function(e){
                      fetch(`${ROOT_URL}/api/product_data_for_price_tag?q=${encodeURIComponent(searchTermList[i])}`)
                        .then(response => response.json())
                        .then(resp => {
                          if (resp.error) {
                            alert(`Error: ${resp.error}`)
                            return
                          }
                          const data = resp.data
                          if (data) {
                            setItems(function(items){
                              items[i] = {
                                id: data.id,
                                isHermesBag: data.designer_id === 1 && data.category_id === 1,
                                name: data.name ?? "N/A",
                                logoUrl: data.designer_price_tag_logo_url ? `${ROOT_URL}${data.designer_price_tag_logo_url}` : "N/A",
                                imageUrl: data.comes_with_image_url ? `${ROOT_URL}${data.comes_with_image_url}` : "N/A",
                                price: data.selling_price ?? "N/A",
                                condition: `${data.condition?.name?.toUpperCase() ?? "N/A"}: ${data.condition?.default_message}`,
                                comesWith: data.comes_with ?? "N/A",
                                sku: data.sku ?? "N/A",
                              }
                              return [...items]
                            })
                          }
                        })
                        .catch(error => {
                          alert(`Error: ${error}`)
                          return
                        });
                    }}
                  >
                    {items[i] ? "Reload" : "Add"}
                  </button>
                  <button
                    className={items[i] ? `hover:bg-gray-200` : "invisible"}
                    onClick={function(e){
                      setItems(function(items){
                        items[i] = null
                        return [...items]
                      })
                    }}
                  >
                    Remove
                  </button>
                  <a
                    className={items[i] ? `hover:bg-gray-200` : "invisible"}
                    href={items[i] ? ROOT_URL+`/admin/products/${items[i].id}` : undefined}
                    target="_blank"
                  >
                    Edit Product
                  </a>
                </div>
              )
            })
          }
        </div>
      </div>
      
      <div className="grid grid-cols-3 grid-rows-4 w-[595px] h-[1026px]">
        {items
          .filter(o=>o) //filter empty, null, and undefined
          .map((item, index) => (
            <div key={index} className="bg-white p-[6px] border-[1px] rounded-none border-black w-[194px] h-[248px] text-center flex flex-col">
              {
                item.isHermesBag
                ?
                <div className="flex justify-center relative mt-[2px] mb-[4px]">
                  <img className="h-[18px] m-auto" src="/price_tag_header.png" alt="Belle and Kate Logo"/>
                </div>
                :
                <div className="flex justify-between relative">
                  <img className="h-[18px] my-auto" src="/price_tag_header.png" alt="Belle and Kate Logo"/>
                  <img className="h-[28px] w-[80px] my-[2px] object-right object-scale-down" src={item.logoUrl} alt="Brand Logo"/>
                </div>
              }
              <div className="flex">
                <p className="font-bold text-[10px] p-[2px] m-auto">{item.sku}</p>
              </div>
              <div className="grid grid-cols-2 grid-rows-[repeat(6,minmax(0,1fr))] gap-[3px] text-left h-[155px]">
                <div className="border-gray-400 rounded-none border-[1px] p-[2px] row-span-2 overflow-clip flex">
                  <p className="font-bold text-[6px] m-auto text-center">{item.name}</p>
                </div>
                <div className="border-gray-400 rounded-none border-[1px] p-[2px] row-span-3 overflow-clip">
                  <p className="text-[6px] leading-[7px]">{`COMES WITH:`}</p>
                  <p className="text-[6px] leading-[7px]">{item.comesWith}</p>
                </div>
                <div className="border-gray-400 rounded-none border-0 p-[2px] row-span-4 overflow-clip">
                  <img className="max-h-[100%] mx-auto" src={item.imageUrl} alt={item.name}/>
                </div>
                <div className="border-gray-400 rounded-none border-[1px] p-[2px] row-span-3 overflow-clip">
                <p className="text-[6px] leading-[7px]">{`CONDITION/NOTE:`}</p>
                <p className="text-[6px] leading-[7px]">{item.condition}</p>
                </div>
              </div>
              <div className="h-full flex">
                <p className="font-bold text-[12px] m-auto">{formatCurrencyDisplay(item.price)}</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

function formatCurrencyDisplay(priceStr){
  const num = parseInt(priceStr)
  if (!num){
    return "N/A"
  }
  return `IDR ${num.toLocaleString("en-US")},-`
}
import React, { useContext, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  useWindowDimensions,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import colors from "../assets/colors/colors";
import Cart from "../components/Cart";
import Footer from "../components/Footer";
import { LatoBold, LatoRegular } from "../components/Text";
import { useAuth } from "../contexts/AuthContext";
import { getDeviceType } from "../tools/Interface";
import { useLocation, useNavigate } from "react-router";
import { upperCase } from "lodash";
import { formatCurrency } from "../tools/formatter";
import Midtrans from "../assets/images/midtrans.png";
import Card1 from "../assets/images/card-1.png";
import Card2 from "../assets/images/card-2.png";
import Card3 from "../assets/images/card-3.png";
import { RadioSelection } from "../components/Inputs";
import { api } from "../utils/api";
import Navbar from "../components/Navbar";
import Buttons from "../components/Buttons";
import ToasterContext from "../contexts/ToastContext";
import CheckoutTracker from "../components/CheckoutTracker";
import { contactDecider } from "../tools/decider";

export default function CheckoutPaymentScreen() {
  const [selected, setSelected] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState({
    installment: null,
  });
  const [calculator, setCalculator] = useState();
  const [loading, setLoading] = useState(false);
  const [cartId, setCartId] = useState("0");
  const [cartConfirmed, setCartConfirmed] = useState(false);
  const [checkoutLoad, setCheckoutLoad] = useState(false);

  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const { user } = useAuth();
  const { address = {} } = user;
  const { recipient_name, complete_address, recipient_number } = address;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { courier, radioSelected } = state;
  const {
    logistic,
    rate,
    duration_max,
    duration_min,
    duration_unit,
    total_price,
  } = courier;

  const installmentArr = [
    {
      name: "Full Payment",
      value: null,
    },
    {
      name: "3 Months",
      value: 3,
    },
    {
      name: "6 Months",
      value: 6,
    },
    {
      name: "12 Months",
      value: 12,
    },
  ];

  const selection = [
    {
      label: "Virtual Account",
      rightIcon: (
        <img
          src={Midtrans}
          alt={"Midtans Logo"}
          width={20}
          height={20}
          style={{
            alignSelf: "center",
            objectFit: "cover",
          }}
        />
      ),
      value: "virtual_account",
    },
    {
      label: "Credit Cards",
      rightIcon: (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <img
            src={Card1}
            alt={"VISA Logo"}
            width={20}
            height={20}
            style={{
              alignSelf: "center",
              objectFit: "cover",
              marginRight: 12,
            }}
          />
          <img
            src={Card2}
            alt={"Mastercard Logo"}
            width={20}
            height={20}
            style={{
              alignSelf: "center",
              objectFit: "cover",
              marginRight: 12,
            }}
          />
          <img
            src={Card3}
            alt={"Mastercard Logo"}
            width={20}
            height={20}
            style={{
              alignSelf: "center",
              objectFit: "cover",
              marginRight: 12,
            }}
          />
        </View>
      ),
      value: "credit_card",
      installments: installmentArr,
    },
    {
      label: "Direct bank transfer",
      value: "manual_transfer",
    },
  ];

  const style = StyleSheet.create({
    sectionContainer: {
      width: isTablet ? containerWidth : (2 * containerWidth) / 3 - 40,
    },
    sectionHeader: {
      fontSize: 20,
      lineHeight: 24,
      color: colors.grey33,
    },
  });

  const onSelect = async (value) => {
    try {
      setLoading(true);
      setSelected(value);

      await api.post(
        "/api/carts/input_rate",
        value === "credit_card"
          ? {
              rate_id: rate?.id,
              payment_method: value,
              installment: selectedInstallment?.installment?.value,
            }
          : {
              rate_id: rate?.id,
              payment_method: value,
            }
      );

      const res = await api.get("/api/carts");
      const data = res?.data?.data;

      setCalculator(data?.calculator || {});
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onSelect(selected);
  }, [selectedInstallment]);
  
  const cartProductsAreConfirmed = (cart) => {
    const encoded = cart.products.map(p => p.sku).sort().join(' | ') //encoded need to be synced with backend and mobile
    return encoded === cart.confirmation_string && new Date() < new Date(cart.confirmation_expired_at)
  }
  const checkCartConfirmation = async () => {
    try {
      setLoading(true);
      const res = await api.get("/api/carts");
      const data = res?.data?.data;
      setCartId(data.id)
      setCartConfirmed(cartProductsAreConfirmed(data))
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    checkCartConfirmation()
  }, []);

  const handleCheckout = async () => {
    try {
      if(!cartConfirmed){
        throw "Order not confirmed yet"
      }
      setCheckoutLoad(true);
      // if (selected === "credit_card") {
      // } else {
      const res = await api.post("/api/deals", {
        payment_method: selected,
        rate_id: rate?.id,
      });
      const data = res?.data?.data;

      const discountedSubtotal = parseFloat(data.discounted_subtotal).toFixed(2);

      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          value: discountedSubtotal,
          currency: 'IDR'
          });
      }

      navigate(`/deals/${data?.id}`, { state: { id: data?.id } });

      if (
        data?.payment?.method_type === "virtual_account" ||
        data?.payment?.method_type === "credit_card"
      ) {
        setTimeout(() => {
          // let scriptTag = document.createElement("script");
          // scriptTag.src = MIDTRANS_URL;
          // // optional if you want to set script attribute
          // // for example snap.js have data-client-key attribute
          // scriptTag.setAttribute("data-client-key", MIDTRANS_CLIENT_KEY);

          // document.body.appendChild(scriptTag);

          window.snap.pay(data?.payment?.redirect_url.split("/").pop(), {
            onSuccess: function (result) {
              /* You may add your own implementation here */
              alert("payment success!");
              console.log(result);
            },
            onPending: function (result) {
              /* You may add your own implementation here */
              alert("wating your payment!");
              console.log(result);
            },
            onError: function (result) {
              /* You may add your own implementation here */
              alert("payment failed!");
              console.log(result);
            },
            onClose: function () {
              /* You may add your own implementation here */
              alert("you closed the popup without finishing the payment");
            },
          });
          return () => {
            // document.body.removeChild(scriptTag);
          };
        }, 1500);
      }
      // }
    } catch (err) {
      showToast(err);
    } finally {
      setCheckoutLoad(false);
    }
  };

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} isCheckout />
        <CheckoutTracker position="payment" />
        <View
          style={{
            flexDirection: isTablet ? "column-reverse" : "row",
            justifyContent: "space-between",
            paddingHorizontal: gap,
            paddingBottom: 200,
          }}
        >
          <View style={style.sectionContainer}>
            <View
              style={{
                paddingBottom: 32,
                borderBottomColor: colors.greyE6,
                borderBottomWidth: 1,
                marginBottom: 32,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 16,
                }}
              >
                <LatoRegular style={style.sectionHeader}>Ship to</LatoRegular>
                <TouchableOpacity
                  onPress={() =>
                    navigate("/checkout/address", {
                      state: { ...state, fromPayment: true },
                    })
                  }
                  style={{
                    borderBottomColor: colors.grey33,
                    borderBottomWidth: 1,
                    paddingBottom: 2,
                    paddingHorizontal: 4,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    Edit
                  </LatoRegular>
                </TouchableOpacity>
              </View>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: 8,
                  color: colors.grey33,
                }}
              >
                {recipient_name}
              </LatoRegular>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: 16,
                  color: colors.grey33,
                }}
              >
                {`+62${recipient_number}`}
              </LatoRegular>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: 8,
                  color: colors.grey33,
                }}
              >
                {complete_address}
              </LatoRegular>
            </View>
            <View
              style={{
                paddingBottom: 32,
                borderBottomColor: colors.greyE6,
                borderBottomWidth: 1,
                marginBottom: 32,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 16,
                }}
              >
                <LatoRegular style={style.sectionHeader}>
                  Shipping Method
                </LatoRegular>
                <TouchableOpacity
                  onPress={() =>
                    navigate("/checkout/shipment", { state: { courier } })
                  }
                  style={{
                    borderBottomColor: colors.grey33,
                    borderBottomWidth: 1,
                    paddingBottom: 2,
                    paddingHorizontal: 4,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    Edit
                  </LatoRegular>
                </TouchableOpacity>
              </View>
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: 8,
                  color: colors.grey33,
                }}
              >
                {radioSelected?.label}
              </LatoBold>
              <View style={{ marginBottom: 16 }}>
                {radioSelected?.additional.map((item, idx) => (
                  <LatoRegular
                    key={idx}
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey90,
                    }}
                  >
                    {item}
                  </LatoRegular>
                ))}
              </View>
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: 8,
                  color: colors.grey33,
                }}
              >
                {`${upperCase(logistic?.name)} - ${upperCase(rate?.type)}`}
                <LatoBold
                  style={{ fontSize: 14, lineHeight: 20, color: colors.grey90 }}
                >
                  {` (IDR${formatCurrency(
                    calculator?.discounted_delivery_fee || total_price
                  )})`}
                </LatoBold>
              </LatoBold>
              <LatoRegular
                style={{ fontSize: 14, lineHeight: 20, color: colors.grey90 }}
              >
                {`Estimated Time: ${
                  duration_min === duration_max || duration_unit === "hours"
                    ? duration_min === 0 || duration_unit === "hours"
                      ? "today"
                      : duration_min === 1
                      ? "tomorrow"
                      : duration_min + " days"
                    : duration_min + "-" + duration_max + " days"
                }`}
              </LatoRegular>
            </View>
            {
              cartConfirmed
              ?
              <>
                <LatoRegular style={{ ...style.sectionHeader, marginBottom: 16 }}>
                  Payment Method
                </LatoRegular>
                <RadioSelection
                  selection={selection}
                  selected={selected}
                  setSelected={onSelect}
                  installment={selectedInstallment}
                  setInstallment={setSelectedInstallment}
                />
                <View
                  style={{
                    borderBottomColor: colors.greyE6,
                    borderBottomWidth: 1,
                    marginVertical: 24,
                  }}
                />
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey6C,
                    alignSelf: "flex-start",
                    // paddingHorizontal: 6,
                    // width: isPhone ? "80vw" : isTablet ? "40vw" : "32.5vw",
                  }}
                >
                  {"By placing order, you agree to our "}
                  <TouchableOpacity onPress={() => navigate("/terms-conditions")}>
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        textDecorationLine: "underline",
                      }}
                    >
                      Terms and Conditions
                    </LatoBold>
                  </TouchableOpacity>
                  {" & "}
                  <TouchableOpacity onPress={() => navigate("/privacy-policy")}>
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        textDecorationLine: "underline",
                      }}
                    >
                      Privacy Policy
                    </LatoBold>
                  </TouchableOpacity>
                </LatoRegular>
                <View
                  style={{
                    flexDirection: isPhone ? "column-reverse" : "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 32,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => navigate(-1)}
                    style={{
                      borderBottomColor: colors.grey33,
                      borderBottomWidth: 1,
                      paddingBottom: 2,
                      paddingHorizontal: 4,
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      Return to Shipping
                    </LatoRegular>
                  </TouchableOpacity>
                  <Buttons
                    onPress={handleCheckout}
                    loading={checkoutLoad}
                    disabled={
                      loading ||
                      !selected ||
                      checkoutLoad ||
                      (selected === "credit_card" &&
                        !selectedInstallment?.installment?.name)
                    }
                    label="Place Order"
                    isBlack
                    width={isPhone ? containerWidth : 250}
                    containerStyle={{ marginBottom: isPhone ? 16 : 0 }}
                  />
                </View>
              </>
              :
              <>
                <LatoBold
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    marginBottom: 8,
                    color: colors.grey33,
                    textAlign: isPhone ? 'center' : 'left'
                  }}
                >
                  Before completing your payment, please confirm product availability by contacting our Customer Service to ensure your order is in stock. Estimated confirmation time: max 1 × 24 hours. Cart ID: {cartId}.
                </LatoBold>
                <View
                  style={{
                    flexDirection: isPhone ? "column-reverse" : "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 32,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      checkCartConfirmation()
                    }}
                    style={{
                      borderBottomColor: colors.grey33,
                      borderBottomWidth: 1,
                      paddingBottom: 2,
                      paddingHorizontal: 4,
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      {loading ? "Reloading" : "Refresh to Check if Order is Confirmed"}
                    </LatoRegular>
                  </TouchableOpacity>
                  <a href={`https://wa.me/${contactDecider()}?text=${encodeURIComponent(`Halo. Tolong konfirmasi ketersediaan produk atas pesanan saya ${recipient_name} (#${cartId})`)}`} target="_blank" rel='noopener noreferrer'>
                    <Buttons
                      onPress={() => {}}
                      label="Ask for Confirmation"
                      isBlack
                      width={isPhone ? containerWidth : 250}
                      containerStyle={{ marginBottom: isPhone ? 16 : 0 }}
                    />
                  </a>
                </View>
              </>
            }
          </View>
          <View
            style={{
              marginTop: isTablet ? 32 : -90,
              marginBottom: isTablet ? 48 : 0,
              paddingBottom: isTablet ? 48 : 0,
              borderBottomColor: colors.greyE6,
              marginHorizontal: isTablet ? -gap : 0,
              paddingHorizontal: isTablet ? gap : 0,
              borderBottomWidth: isTablet ? 1 : 0,
              width: isTablet ? width : containerWidth / 3 - 40,
            }}
          >
            <LatoRegular style={style.sectionHeader}>Order Summary</LatoRegular>
            <Cart
              isCheckout={isTablet ? containerWidth : containerWidth / 3 - 40}
              calculator={calculator}
              customLoading={loading}
            />
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
